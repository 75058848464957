import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@core/store'
import { Box, Button, Skeleton } from '@mui/material'

import ReferencesModal from '@containers/references/references-modal'
import Citations from '@containers/references/Citations'
import { actions as authorActions } from '@pages/author/author-slice'
import { getReferences } from '@containers/references/references-thunk'
import { selectReferences } from '@containers/references/references-selectors'
import ItemReferenceOptions from './references-item'
import ItemStartReferences from './references-item-start'

export default function ReferencesTab() {
  const dispatch = useAppDispatch()
  const { aiModelId, hasItemStartReferences, customInput } = useAppSelector(selectReferences)
  const { loading, items } = useAppSelector((state) => state.references)

  const [selectedCitationId, setSelectedCitationId] = useState<string | null>(null)
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)

  const question = useAppSelector((state) => state.author.content?.question)
  const answers = useAppSelector((state) => state.author.content?.answers) || []
  const selectedOptionAnswerIndex = answers.findIndex((answer) => answer.value === selectedOption)

  const handleGetCitations = async () => {
    if (!selectedOption || !aiModelId) return
    dispatch(
      getReferences({
        query: `${!hasItemStartReferences ? `${question} ` : ''}${selectedOption}`,
        aiModelId,
      })
    )
  }

  const handleAddRationale = (selectedText: string) => {
    const updatedAnswers = answers.map((answer, index) => {
      if (index === selectedOptionAnswerIndex) {
        if (answer.rationales) {
          return {
            ...answer,
            rationales: [...answer.rationales, { value: selectedText }],
          }
        }
        return { ...answer, rationales: [{ value: selectedText }] }
      }
      return answer
    })
    dispatch(authorActions.setAnswers(updatedAnswers))
    setSelectedCitationId(null)
  }

  const handleAddPassage = (selectedText: string) => {
    if (customInput) {
      dispatch(authorActions.setInputText({ name: customInput, value: selectedText }))
      dispatch(authorActions.set({ selectedTab: 0 }))
    }
  }
  const buttonDisabled = !selectedOption

  return (
    <Box mt={4} mx={4}>
      {hasItemStartReferences ? (
        <ItemStartReferences
          key="item-start-references"
          loading={loading}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <ItemReferenceOptions
          loading={loading}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )}

      <Button
        onClick={handleGetCitations}
        fullWidth
        variant="contained"
        color="secondary"
        aria-label="Get Citations"
        disabled={buttonDisabled}
        sx={{ mb: 3 }}
      >
        Get Citations
      </Button>

      <Citations items={items} onSelect={setSelectedCitationId} />

      {loading && (
        <Box>
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ borderRadius: 1, mb: 2 }}
            height={50}
          />
          <Skeleton variant="rectangular" animation="wave" sx={{ borderRadius: 1 }} height={50} />
        </Box>
      )}

      <ReferencesModal
        items={items}
        selectedCitationId={selectedCitationId}
        open={Boolean(selectedCitationId)}
        onClose={() => setSelectedCitationId(null)}
        onSelect={setSelectedCitationId}
        onUseSelection={hasItemStartReferences ? handleAddPassage : handleAddRationale}
      />
    </Box>
  )
}
