import Raven from '@core/api/Raven';

// Messages that are excluded from Sentry
const excludedMessages = ['Login required'];

export default function ErrorHandler(error, extra) {
  // eslint-disable-next-line
  console.error('ErrorHandler', error, extra);
  if (typeof error?.message === 'string' && error.message.includes('order more credits')) {
    return { message: 'Please either wait or order more credits' };
  }

  const errorMessage =
    (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0].message) ||
    (error.networkError && error.networkError.error_description) ||
    'Unknown error';
  const friendlyMessage = 'Sorry, an error occurred. Please try again.';

  if (
    excludedMessages.filter((excludedMessage) => errorMessage.indexOf(excludedMessage) === -1)
      .length > 0
  ) {
    Raven.captureException(error, extra);
  }

  return { message: friendlyMessage, errorMessage };
}
