import React, { useMemo, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { TextArea } from '@core/components/textfield/textarea'
import { TextFieldEdit } from '@core/components/textfield/textfield-edit'
import { Check, Inputs } from '@pages/author/author-types'
import { maxWords, minWords } from '@core/utils/check'
import { hasLessThanChars } from '@core/utils/text'

const MIN_WORDS_LENGTH = 3
interface MaxKeywords {
  number: any
}

const MaxKeywords = (props: MaxKeywords) => {
  const { number = 0 } = props
  return (
    <Box display="flex" justifyContent="space-between">
      <Box fontSize={14} letterSpacing="normal" fontWeight={500} color="text.grayishBlue">
        Max {number} keywords
      </Box>
      <Box fontSize={14} letterSpacing="normal" fontWeight={500} color="text.slateBlue">
        Return [⮐] to Enter
      </Box>
    </Box>
  )
}

interface FlexibleProps {
  input: Inputs
  isLoading?: boolean
  onKeyDown: (e) => void
  handleClose: (i: number) => void
  handleEdit: (e, i: number) => void
}

interface ValidationCheck {
  minElements?: Check
  maxElements?: Check
  maxWords?: Check
  minWords?: Check
}

export const FlexibleInputList = (props: FlexibleProps) => {
  const { input, isLoading = false, onKeyDown, handleClose, handleEdit } = props
  const { checks, title, errorMessage, placeholder, required, elements } = input
  const [listValue, setListValue] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListValue(e.target.value)
  }
  const checkValues: ValidationCheck = useMemo(() => {
    return checks.reduce((next, check) => {
      const { name } = check
      return { ...next, [name]: check }
    }, {})
  }, [checks])

  return (
    <Box mt={3} mb={1}>
      {title && <Typography variant="h5">{title}</Typography>}
      <TextArea
        disabled={isLoading}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            setListValue('')
            onKeyDown(e)
            e.preventDefault()
          }
        }}
        inputProps={{
          'aria-label': 'flexible list',
        }}
        required={required}
        value={listValue}
        errorMessage={errorMessage?.errorMessage}
        placeholder={placeholder}
        helperContent={checkValues && <MaxKeywords number={checkValues.maxElements?.params} />}
        onChange={handleChange}
      />
      {elements?.map((x, i) => {
        const message = errorMessage
        return (
          <TextFieldEdit
            key={i}
            value={x}
            disabled={isLoading}
            inputProps={{
              'aria-label': 'list item',
            }}
            error={
              (message?.name === 'minWords' && !minWords(x, message?.params[0])) ||
              (message?.name === 'maxWords' && !maxWords(x, message?.params[0])) ||
              (message?.name === 'minLength' && !!hasLessThanChars([x], MIN_WORDS_LENGTH)) ||
              false
            }
            handleClose={() => handleClose(i)}
            onChange={(e) => handleEdit(e, i)}
          />
        )
      })}
    </Box>
  )
}
