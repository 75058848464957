import React, { memo, useEffect } from 'react'
import { Box, List, Button, Typography, IconButton } from '@mui/material'
import { useHistory, useParams, generatePath } from 'react-router-dom'

import ConfirmDialog, { useConfirmDialog } from '@core/components/dialogs/dialog-confirm'
import FTGTooltip from '@core/components/FTGTooltip'
import { useSubscription, useAppDispatch, useAppSelector } from '@core/store'
import { AUTHOR_ITEM_ROUTE, AUTHOR_ROUTE } from '@core/constants/routes'
import CachedIcon from '@mui/icons-material/Cached'
import { useSnack } from '@core/providers/snack'
import { useUserRole } from '@containers/main/main-utils'
import { actions as authorActions } from '@pages/author/author-slice'

import BatchAutocomplete from './batches-tab-autocomplete'
import BatchItem from './batches-tab-item'
import { actions } from './batches-slice'
import AiModelInfo from './batches-tab-aimodel-info'
import * as queries from './batches-queries'
import * as thunkActions from './batches-thunk'
import * as selectors from './batches-selectors'

function BatchesTab() {
  const { itemId: selectedItemId } = useParams<{ itemId: string }>()
  const history = useHistory()
  const snack = useSnack()
  const { role } = useUserRole()
  const generatedJobId = useAppSelector((state) => state.author.generatedJobId)
  const selectedModel = useAppSelector((state) => state.author.selectedModel)
  const confirmDiscard = useConfirmDialog()
  const selected = useAppSelector(selectors.selectCurrentBatch)
  const selectedDiscard = useAppSelector((state) => state.batches.selectedDiscard)
  const selectedJobId = useAppSelector((state) => state.batches.selected)
  const status = useAppSelector(selectors.selectItemStatus)
  const isRegenerating = useAppSelector((state) => state.batches.isRegenerating)
  const user = useAppSelector((state) => state.main.user)
  const models = useAppSelector((state) => state.author.models || [])

  const dispatch = useAppDispatch()

  useSubscription<{ job: any[] }>({
    query: queries.GET_BATCH_LIST,
    variables: { ownerId: user?.id },
    context: { role },
    onData: (data) => actions.updateList({ jobs: data.job }),
  })

  useSubscription<{ job: { items: any[]; itemsPassed: number; itemsFailed: number; id: string } }>({
    query: queries.GET_BATCH_ITEMS,
    variables: { jobId: selected?.id },
    skip: !selected,
    context: { role },
    onData: (data) => {
      if (!data.job || !selected) return null
      return actions.updateBatch({
        ...data.job,
        step: 'LOADED',
      })
    },
  })

  useEffect(() => {
    if (generatedJobId) {
      dispatch(actions.set({ selected: generatedJobId }))
    }
  }, [generatedJobId])

  // If generated batch job, make first done item selected.
  useEffect(() => {
    if (!selectedItemId && generatedJobId === selectedJobId && selected?.itemsIds.length) {
      history.push(generatePath(AUTHOR_ITEM_ROUTE, { itemId: selected.itemsIds[0] }))
    }
  }, [selected, selectedItemId, generatedJobId, selectedJobId])

  useEffect(() => {
    if (status.allItemsFailed)
      snack.add({
        message: 'Generating a batch has failed. Please try again',
        severity: 'error',
      })
  }, [status.allItemsFailed])

  useEffect(() => {
    if (!selected) {
      return
    }

    dispatch(authorActions.set({ paginatedItemsIds: selected?.itemsIds || [] }))

    const parentModel = models?.find(({ name }) => name === selected.jobName)

    // selected the same model for generate tab
    if (parentModel && parentModel.id !== selectedModel?.id) {
      dispatch(authorActions.set({ selectedModel: parentModel, selectedFlavors: [] }))
    }
  }, [selected])

  const handleChange = (id: string) => {
    dispatch(actions.set({ selected: id }))
    history.push(AUTHOR_ROUTE)
    dispatch(authorActions.set({ paginatedItemsIds: [] }))
  }

  const handleDiscard = () => {
    confirmDiscard.open(async () => {
      const { payload }: any = await dispatch(thunkActions.discardBatches())

      if (payload?.deleted) {
        dispatch(authorActions.set({ paginatedItemsIds: [], generatedJobId: null }))
        history.push(AUTHOR_ROUTE)
      }
    })
  }

  return (
    <Box m={4}>
      <ConfirmDialog
        state={confirmDiscard}
        id="confirm-discard-batch"
        title="Are you sure you are done with these batches?"
        content={
          <>
            Rest assured that all of your saved items are safe and available in Deliver.
            <Box component="b" mt={2} display="block">
              This action will delete all unsaved items and cannot be undone.
            </Box>
          </>
        }
      />

      <Box display="flex" alignItems="center">
        <Typography variant="h5" pr={1}>
          Batch
        </Typography>
        {status.allItemsFailed && (
          <FTGTooltip title="Regenerate Batch Items" placement="right" disabled={isRegenerating}>
            <IconButton
              disabled={isRegenerating}
              onClick={() => dispatch(thunkActions.regenerateBatches())}
              aria-label="regenerate batch items"
              size="large"
            >
              <CachedIcon />
            </IconButton>
          </FTGTooltip>
        )}
      </Box>

      <BatchAutocomplete onChange={handleChange} />

      {selectedDiscard.length > 0 && !isRegenerating && (
        <Button
          fullWidth
          aria-label="Discard batch"
          onClick={handleDiscard}
          variant="contained"
          color="secondary"
          sx={{ mt: 3 }}
        >
          Discard selected ({selectedDiscard.length})
        </Button>
      )}

      {selected?.flavors && selected.flavors.length > 0 && <AiModelInfo />}

      {selected && (
        <List disablePadding sx={{ mt: 2 }}>
          {selected.items.map((item) => (
            <BatchItem
              key={`item-${item.id}`}
              selectedItemId={selectedItemId}
              item={item}
              isRegenerating={isRegenerating}
            />
          ))}
        </List>
      )}
    </Box>
  )
}

export default memo(BatchesTab)
