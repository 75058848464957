import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorHandler from '@core/api/ErrorHandler'
import * as queries from './users-queries'

const context = { headers: { 'x-hasura-role': 'customer_admin' } }

export const createUsers = createAsyncThunk<
  | { status: 'error'; message: string; email: string }[]
  | { id: string; email: string; ticket: string; status: 'success' }[],
  { email: string; first_name: string; last_name: string }[]
>('users/createUser', async (users, { extra }) => {
  try {
    const { data } = await extra.client.mutate({
      mutation: queries.CREATE_USERS,
      context,
      variables: { users },
    })

    return data.create_users?.responses || []
  } catch (error) {
    const { message } = ErrorHandler(error) as any
    extra.snack.add({ severity: 'error', message })
    return { status: 'error', message: error.message }
  }
})

export const updateUser = createAsyncThunk<
  { email: string; first_name: string; last_name: string; ticket?: string },
  { user: { email: string; first_name: string; last_name: string }; create_password_link?: boolean }
>('users/updateUser', async (variables, { extra }) => {
  try {
    const { data } = await extra.client.mutate({
      mutation: queries.UPDATE_USER,
      context,
      variables,
    })

    return data.update_user_info?.response
  } catch (error) {
    const { message } = ErrorHandler(error) as any
    extra.snack.add({ severity: 'error', message })
    return { status: 'error', message: error.message }
  }
})

export const assignRole = createAsyncThunk(
  'users/assignRole',
  async (role: string, { extra, getState }) => {
    try {
      const userSelected = getState().users.selected

      const { data } = await extra.client.mutate({
        mutation: queries.ASSIGN_ROLE,
        context,
        variables: {
          users: userSelected,
          role,
        },
      })

      if (data.update_user?.affected_rows > 0) {
        extra.snack.add({
          message: `${role} assigned successfully!`,
          severity: 'success',
        })
      }
    } catch (error) {
      ErrorHandler(error) as any
      extra.snack.add({
        message: `Error when trying to change user role permissions. ${error.message}`,
        severity: 'error',
      })
      throw error
    }
  }
)

export const deleteUser = createAsyncThunk('users/deleteUser', async (_, { extra, getState }) => {
  try {
    const usersSelected = getState().users.selected

    const { data } = await extra.client.mutate({
      mutation: queries.DELETE_USER,
      context,
      variables: {
        users: usersSelected,
      },
    })

    if (data.update_user?.affected_rows > 0) {
      extra.snack.add({
        message: `User${usersSelected.length > 1 ? 's' : ''} deleted successfully!`,
        severity: 'success',
      })
      return usersSelected
    }
  } catch (error) {
    ErrorHandler(error) as any
    extra.snack.add({
      message: `Error when trying to delete users. ${error.message}`,
      severity: 'error',
    })
    throw error
  }
})

export const deleteUsers = createAsyncThunk<void, string[]>(
  'users/deleteUsers',
  async (ids, { extra }) => {
    try {
      await extra.client.mutate({
        mutation: queries.DELETE_USER,
        context,
        variables: { users: ids },
      })
    } catch (error) {
      ErrorHandler(error) as any
      extra.snack.add({
        message: `Error when trying to delete users. ${error.message}`,
        severity: 'error',
      })
      throw error
    }
  }
)

interface InsertPermissionParams {
  aiModelInternalName: string
  userIds: string[]
  items: {
    user_id: string
    ai_model_internal_name: string
    read: boolean
  }[]
}

export const setModelPermission = createAsyncThunk<any, InsertPermissionParams>(
  'admin/setModelPermissions',
  async (variables, { extra }) => {
    try {
      const { data } = await extra.client.mutate({
        fetchPolicy: 'network-only',
        mutation: queries.INSERT_AI_MODEL_PERMISSIONS,
        context,
        variables,
      })

      return data
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({ severity: 'error', message })
      return { status: 'error', message: error.message }
    }
  }
)
