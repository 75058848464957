import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Box, IconButton, Menu, MenuItem, ListItem, ListItemText } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

import Raven from '@core/api/Raven'
import { useAppSelector } from '@core/store'
import AdminSettingsDialog, { useAdminSettings } from '@containers/admin/admin-settings'

export default function UserProfile() {
  const { logout } = useAuth0()

  const [anchorEl, setAnchorEl] = useState(null)
  const user = useAppSelector((state) => state.main.user!)
  const customer = useAppSelector((state) => state.main.customer)

  const adminSettings = useAdminSettings()

  const handleExpandClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleExpandClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    Raven.configureScope((scope) => {
      scope.setUser({
        id: '',
      })
    })
    handleExpandClose()
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })
  }

  useEffect(() => {
    if (user.internalAdmin && customer?.name) {
      document.title = `${customer.name} - Finetune Generate`
    }
  }, [user.internalAdmin, customer?.name])

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={(theme) => ({
        '& .notifications-icon': {
          fill: theme.palette.primary.main,
        },
      })}
    >
      <Avatar
        alt={user.firstName || user.name}
        src={user.picture}
        data-testid="user-profile-image"
        sx={{
          width: 24,
          height: 24,
          mr: 1,
          bgcolor: '#73737F',
        }}
      />
      <IconButton
        color="primary"
        aria-label="Profile Menu"
        aria-haspopup="true"
        onClick={handleExpandClick}
        data-testid="user-profile-expand"
        size="large"
      >
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        id="user-profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleExpandClose}
        data-testid="user-profile-menu"
        variant="selectedMenu"
      >
        <ListItem>
          <ListItemText primary={user.email} secondary={user.internalAdmin ? customer?.name : ''} />
        </ListItem>

        {user.internalAdmin && (
          <MenuItem
            onClick={() => {
              adminSettings.setState({ open: true })
              handleExpandClose()
            }}
          >
            <AdminPanelSettingsIcon color="primary" sx={{ mr: 1 }} />
            Admin Settings
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>

      <AdminSettingsDialog adminSettings={adminSettings} />
    </Box>
  )
}
