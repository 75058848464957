import { histogram as histogramColors, tag as tagColors } from '@core/styles/colors';
import { FILTER_TYPES } from '@core/constants';
import _ from 'lodash';

/**
 * Round number to one decimal place
 * @param {*} num - Number
 */

export const toOneDecimalPlace = (num) => parseFloat(num.toFixed(1));

/**
 * Round number to two decimal place
 * @param {*} num - Number
 */

export const toTwoDecimalPlace = (num) => parseFloat(num.toFixed(2));

export const getItemsDomainTags = (items) => [
  ...new Map(items.map(({ job: { domainTag } }) => [domainTag.id, domainTag])).values(),
];

/**
 * Returns an object with domain tags items count
 * @param {*} items - Array
 */

export const getJobDomainTagsCount = (items) => {
  return items.reduce((prev, item) => {
    const { domain } = item.aiModel;
    const prevTags = prev;

    if (prevTags[domain]) prevTags[domain] += 1;
    else prevTags[domain] = 1;

    return prevTags;
  }, {});
};

/**
 * Returns an object with items clone count
 * @param {*} items - Array
 */

export const getItemsCloneCount = (items) =>
  items.reduce((sum, { clonedFromId, clones }) => {
    if (clonedFromId || clones.aggregate.count) return sum + 1;
    return sum;
  }, 0);

/**
 * Returns an object with items topics count
 * @param {*} items - Array
 */

export const getItemsTopicsCount = (items) =>
  items.reduce((prev, { topic }) => {
    const prevTopics = prev;

    if (prevTopics[topic]) prevTopics[topic] += 1;
    else prevTopics[topic] = 1;

    return prevTopics;
  }, {});

const isClone = (item) => item.clonedFromId || item.clones.aggregate.count;

/**
 * @param {array} projects
 * @param {array} draft
 * @param {string} status
 * @param {{id, type}} tag
 * @returns [projectsFiltered, showDraft]
 */
export const filterProjects = (projects, draft, status, tag) => {
  let filtered = projects;
  let showDraft = true;

  if (status === FILTER_TYPES.all && !tag) return [filtered, showDraft];

  if (status === FILTER_TYPES.clone) {
    filtered = filtered.filter((project) => project.items.some(isClone));
    showDraft = draft.some(isClone);
  }

  if (tag?.type === FILTER_TYPES.tag) {
    filtered = filtered.filter(({ items }) => items.some((item) => item.aiModel.domain === tag.id));
    showDraft = draft.some((item) => item.aiModel.domain === tag.id);
  }

  if (tag?.type === FILTER_TYPES.topic) {
    filtered = filtered.filter(({ items }) => items.some((item) => item.topic === tag.id));
    showDraft = draft.some((item) => item.topic === tag.id);
  }

  return [filtered, showDraft];
};

/**
 * Returns unique jobs of given array of items
 * @param {Array} items
 * @returns
 * @TODO: delete
 */
export const getItemsJobs = (items) => [
  ...new Map(items.map((item) => [item.job.id, item.job])).values(),
];

/**
 * Returns list of quality metrics of given job array
 * @param {*} jobs - Array
 * @TODO: delete
 */
export const getJobQualityMetrics = (jobs) =>
  Array.from(new Set(jobs.flatMap(({ aiModel }) => aiModel.qualityMetrics).map((qm) => qm.name)));

/**
 * Returns the maximum and minimum value of qualityMetrics
 * and total items of the desired qualityMetric from a given array of items
 * @param {*} items - Array
 * @param {*} qualityMetric - String
 */
const getQualityMetricsDetails = (items, qualityMetric) =>
  items.reduce(
    (acc, { finalContentVersion, lastContentVersion }) => {
      const metricValue = acc.metricValues || [];

      const metrics = finalContentVersion?.quality_metrics || lastContentVersion?.quality_metrics;
      const currentQualityMetric = metrics.find(({ name }) => name === qualityMetric);

      if (currentQualityMetric?.value === undefined) return acc;

      metricValue.push(currentQualityMetric.value);
      const totalItems = acc.totalItemsMetric + 1 || 1;

      return {
        metricValues: [Math.min(...metricValue), Math.max(...metricValue)],
        totalItemsMetric: totalItems,
      };
    },
    [{ metricValues: [], totalItemsMetric: 0 }]
  );

/**
 * Returns qualityMetrics range of given items array
 * @param {*} items - Array
 * @param {*} qualityMetric - String
 */
export const getItemsQualityMetricsRange = (items, qualityMetric) => {
  const { metricValues, totalItemsMetric } = getQualityMetricsDetails(items, qualityMetric);

  const [min, max] = metricValues || [0, 0];

  if (qualityMetric === 'Type-Token Ratio' || max === 1) {
    return [0, 1];
  }

  const applyBuffer = (value) => toOneDecimalPlace(value * 0.1);

  const isUseRange = totalItemsMetric > 1 && min !== max;
  const rangeBetween = max - min;
  const maxValue = isUseRange ? rangeBetween : max;
  const minValue = isUseRange ? rangeBetween : min;

  let minRange = toOneDecimalPlace(min - applyBuffer(minValue));
  if (minRange < 0 && min > 0) minRange = 0;
  const maxRange = toOneDecimalPlace(max + applyBuffer(maxValue));
  return [minRange, maxRange];
};

/**
 * Returns list of items with metric attribute
 * @param {*} items - Array
 * @param {*} activeMetric - String
 */
export const getMetricsByItems = (items, activeMetric) =>
  items
    .map((_item) => {
      const metrics = (
        _item.finalContentVersion?.quality_metrics || _item.lastContentVersion.quality_metrics
      ).filter(Boolean);

      const metric = metrics.find(({ name }) => name === activeMetric);
      return {
        ..._item,
        metric: { ...metric, value: metric && toOneDecimalPlace(metric.value) },
      };
    })
    .filter(({ metric }) => metric?.value);

/**
 * Returns formatted quality metric name
 * @param {*} name - string
 */
export const getQualityMetricName = (name) => name?.toLowerCase().split(' ').join('-');

/**
 * Returns mapping of quality metrics to colors
 * @param {*} qualityMetrics - Array
 */
export const getQualityMetricsColors = (qualityMetrics) => {
  const colors = {};
  qualityMetrics.forEach((metricName, index) => {
    const parseQualityMetricName = getQualityMetricName(metricName);
    const getColorByMetricIndex =
      histogramColors.additionalColors[index % histogramColors.additionalColors.length];
    colors[metricName] = histogramColors.colors[parseQualityMetricName] || getColorByMetricIndex;
  });
  return colors;
};

/**
 * Returns tag color name by hex color
 * @param {*} color - String
 */
export const findTagColorName = (color) => {
  const [name] = Object.entries(tagColors).find(([, { main }]) => main === color);

  return name;
};

/**
 * Returns true when there are comments
 * @param {*} data - Array
 */
export const hasComments = (data) => {
  return _.filter(data, (comments) => _.get(comments, 'value', '')).length > 0;
};

/**
 * Returns string with [a-zA-Z] letters
 * @param {*} str - String
 */
export const extractAlphabet = (str) => str?.replace(/[^a-zA-Z]/g, '');

/**
 * Returns specific string with [a-zA-Z] letters by position (start, end)
 * @param {*} str - String
 * @param {*} start - Number
 * @param {*} end - Number
 */
export const extractAlphabetByPosition = (str, start, end) => {
  return extractAlphabet(str)?.substring(start, end);
};

/**
 * Sorts array of items by updatedAt and not disabled
 * @param {*} item - Array
 */
export const sortItems = (itemA, itemB) => {
  const updatedA = itemA.finalContentVersion?.updated_at || itemA.updatedAt;
  const updatedB = itemB.finalContentVersion?.updated_at || itemB.updatedAt;
  const sort = new Date(updatedB) - new Date(updatedA);

  if (sort !== 0) {
    return sort;
  }

  return itemA.disabled - itemB.disabled;
};

/**
 * Returns whether items have clones or templates
 * @param {*} items - Array
 * @param {*} projects - Array
 * @returns {*} - Boolean
 */
export const hasClonesOrTemplates = ({ items }) =>
  items?.some((item) => item.clones.aggregate.count || item.clonedFromId);

/**
 * Create a date and subtract the number of seconds
 * @param {number} seconds
 * @returns ISO 8601 date string
 */
export const getDateAgo = (seconds = 240 * 60) => {
  const date = new Date();
  date.setSeconds(date.getSeconds() - seconds);

  return date.toISOString();
};

/**
 * Return reason if export type can't be downloaded
 * @param {*} items - Array
 * @returns reason - String
 */
export const validateDownloadItems = (items) => {
  const reason = {
    comptia: { value: '' },
    surpass: { value: '', missingKeyItems: [], totalItems: items?.length },
  };
  items?.every((item) => {
    const content = item.finalContentVersion?.content || {};
    if (item.contentType !== 'MCQ') {
      const error = "Only MCQ items can be downloaded as '#type'";
      reason.comptia.value = error;
      reason.surpass.value = error;
    } else {
      const hasKey = content?.answers?.some(({ status }) => status === 'CORRECT');
      if (content?.answers && !hasKey) {
        reason.surpass.missingKeyItems.push({ question: content.question, id: item.id });
      }
    }

    if (content.passage || content.custom_passage) {
      reason.surpass.value = "Only items without a stimulus can be downloaded as 'Surpass'";
    }
    return true;
  }, []);
  return reason;
};
