import { gql } from '@apollo/client'

export const INSERT_PROJECT = gql`
  mutation insert_project($projectData: project_insert_input!) {
    project: insert_project_one(object: $projectData) {
      id
      name
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation delete_project($id: uuid!, $isArchived: Boolean!, $name: String!) {
    update_project_by_pk(pk_columns: { id: $id }, _set: { is_archived: $isArchived, name: $name }) {
      id
    }
  }
`

export const GET_PROJECTS = gql`
  subscription get_projects {
    project(where: { can_deliver: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      access(where: { read: { _eq: true } }) {
        userId: user_id
        write
        download
      }
      ownerId: owner_id
      owner {
        id
        email
      }
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation update_project($id: uuid!, $name: String!) {
    update_project_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
      id
    }
  }
`

export const GET_USER_PROJECT = gql`
  query users($projectId: uuid!) {
    user(where: { role: { _eq: "User" } }, order_by: { created_at: desc }) {
      id
      email
      firstName: first_name
      lastName: last_name
      access: project_access(where: { project_id: { _eq: $projectId } }) {
        projectId: project_id
        userId: user_id
        download
        read
        write
      }
    }
  }
`

export const UPDATE_PROJECT_USER = gql`
  mutation update_project_user(
    $projectId: uuid!
    $userId: String!
    $read: Boolean
    $write: Boolean
    $download: Boolean
  ) {
    projectUser: insert_project_user_one(
      object: {
        project_id: $projectId
        user_id: $userId
        download: $download
        read: $read
        write: $write
      }
      on_conflict: { constraint: project_user_pkey, update_columns: [download, read, write] }
    ) {
      projectId: project_id
      userId: user_id
      download
      read
      write
    }
  }
`
