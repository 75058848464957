import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import ErrorHandler from '@core/api/ErrorHandler';
import Logo from '@core/components/Logo';

const useStyles = makeStyles((theme) => ({
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    '& img': { marginBottom: theme.spacing(3) },
  },
  '& h3': {
    marginBottom: theme.spacing(2),
  },
  '& p': {
    marginBottom: theme.spacing(2),
  },
}));

const CustomError = (props) => {
  const { title, redirectTo, redirectToMessage, error, altMessage } = props;
  const classes = useStyles();
  const history = useHistory();

  const { message, errorMessage: originalErrorMessage } = ErrorHandler(error);
  let errorMessage = message || 'Unknown error';
  if (message?.includes('This object was deleted')) {
    errorMessage = `The requested item no longer exists.`;
  }
  if (originalErrorMessage?.includes('Login required')) {
    history.push('/');
  }
  return (
    <div className={classes.error}>
      <Logo />
      {(title && <Typography variant="h3">{title}</Typography>) || (
        <Typography variant="h3">{errorMessage}</Typography>
      )}
      {(altMessage && <p>{altMessage}</p>) ||
        (redirectTo && redirectToMessage && (
          <p>
            Please go back to <Link to={redirectTo}>{redirectToMessage}</Link>.
          </p>
        )) || (
          <p>
            Please click <Link to="/">here</Link> to continue.
          </p>
        )}
    </div>
  );
};

CustomError.displayName = 'Error';

CustomError.propTypes = {
  /* The title of the error. */
  title: PropTypes.string,
  /* The route where the user should be redirected. */
  redirectTo: PropTypes.string,
  /* The message for the redirect route. */
  redirectToMessage: PropTypes.string,
  /* Error to be sent to sentry */
  error: PropTypes.object,
  /* Alternative message to display in place of redirect message */
  altMessage: PropTypes.string,
};

CustomError.defaultProps = {
  title: null,
  redirectTo: null,
  redirectToMessage: null,
  error: null,
  altMessage: null,
};

export default CustomError;
