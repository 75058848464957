import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import * as thunks from './projects-thunk'
import { State } from './projects-types'

export function getInitialState(): State {
  return {
    items: null,
    lastProjectId: null,
    onCreate: null,
    open: false,
    share: {
      loading: false,
      id: null,
      users: null,
    },
  }
}

export const slice = createSlice({
  name: 'projects',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(thunks.getUserByProject.pending, (state, action) => {
        state.share.loading = true
        state.share.id = action.meta.arg.projectId
        state.share.users = null
      })
      .addCase(thunks.getUserByProject.fulfilled, (state, action) => {
        state.share.loading = false
        state.share.users = action.payload
      })
      .addCase(thunks.getUserByProject.rejected, (state) => {
        state.share.loading = false
      })

    builder.addCase(thunks.updateProjectUser.fulfilled, (state, action) => {
      const user = state.share.users?.find((u) => u.id === action.payload.userId)
      if (!user) return
      user.access[0] = action.payload
    })
  },
})

export default slice
export const { actions } = slice
