import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

export default function Auth0ProviderWithHistory(props: { children: React.ReactElement }) {
  const history = useHistory()

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      onRedirectCallback={(appState) =>
        history.push(appState?.returnTo || window.location.pathname)
      }
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
      }}
      cacheLocation="localstorage"
    >
      {props.children}
    </Auth0Provider>
  )
}
