import React from 'react'
import {
  Alert,
  TextField as MuiTextField,
  SxProps,
  FormControl,
  StandardTextFieldProps,
} from '@mui/material'

const styles: Record<string, SxProps> = {
  root: {
    my: 1,
    '& fieldset': { border: 'none' },
    '& .MuiOutlinedInput-root': {
      bgcolor: '#f5f6f8',
      pt: 1,
      pl: 2,
    },
    '& .MuiInputBase-inputMultiline': {
      fontSize: 14,
      resize: 'vertical',
    },
  },
}

interface Props extends StandardTextFieldProps {
  helperContent: string | React.ReactNode
  errorMessage?: string
}

export const TextArea = (props: Props) => {
  const { error, errorMessage, helperContent, required, helperText = 'Required*', ...rest } = props

  return (
    <FormControl fullWidth>
      <MuiTextField
        rows={6}
        variant="outlined"
        sx={styles.root}
        required={required}
        multiline
        helperText={required && helperText}
        FormHelperTextProps={{
          sx: {
            mt: 0.5,
            ml: 0,
            color: 'text.grayishBlue',
          },
        }}
        {...rest}
      />
      {helperContent}
      {errorMessage && (
        <Alert
          icon={false}
          severity="error"
          sx={{
            mt: 1,
            fontWeight: 500,
            borderRadius: 0,
            color: 'text.milanoRed',
          }}
        >
          {errorMessage}
        </Alert>
      )}
    </FormControl>
  )
}
