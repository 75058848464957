import React, { Children, useEffect, useState } from 'react'
import clsx from 'clsx'
import FTGTooltip from '@core/components/FTGTooltip'

import { makeStyles } from '@mui/styles'
import { Box, Menu, MenuItem, Button, SxProps, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ClearIcon from '@mui/icons-material/Clear'

const useStyles = makeStyles((theme) => ({
  dropdown: {
    height: '32px',
    display: 'flex',
    '& button': {
      color: theme.palette.text.slateBlue,
      letterSpacing: '-0.13px',
      fontSize: '1rem',
      fontWeight: 400,
      padding: '2px 8px 2px 10px',
      lineHeight: '2rem',
      '&:hover': {
        background: 'inherit',
      },
      '& svg': {
        marginLeft: theme.spacing(1),
        color: theme.palette.text.slateBlue,
      },
      '& p': {
        width: '100%',
        textAlign: 'left',
      },
      '& span': {
        justifyContent: 'space-between',
      },
    },
  },
}))

interface Props {
  testid?: string
  className?: string
  buttonLabel?: string | React.ReactNode
  value?: string
  placeholder: string
  setValue: (value: any) => void
  options: any[]
  hasClearButton?: boolean
  disabled?: boolean
  sx?: SxProps
  menuSx?: SxProps
  optionsSx?: SxProps
  menuClassName?: string
  valueClassName?: any
  Icon?: React.FC
  iconPosition?: 'left' | 'right'
}

export default function FTGDropdown({
  testid,
  className,
  options = [],
  value,
  buttonLabel,
  setValue,
  placeholder,
  hasClearButton,
  disabled,
  iconPosition = 'right',
  Icon = ExpandMoreIcon,
  sx,
  menuSx,
  optionsSx,
  menuClassName,
  valueClassName,
}: Props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget)

  const handleMenuClose = () => setAnchorEl(null)

  const handleAction = (action) => () => {
    handleMenuClose()
    action()
  }

  const handleSelectItem = (option) => () => {
    handleMenuClose()
    if (value !== option.name) {
      setValue(option)
    }
  }

  useEffect(() => {
    if (value) {
      handleMenuClose()
    }
  }, [value])

  useEffect(() => {
    if (!disabled && options && options.length === 1) {
      const selectedOption = options[0]
      if (!selectedOption.disabled) handleSelectItem(selectedOption)()
    }
  }, [])

  return (
    <Box
      className={clsx(classes.dropdown, className, {
        [valueClassName]: !buttonLabel && value,
      })}
      aria-label="dropdown"
      sx={sx}
    >
      <Button
        aria-controls={testid}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        aria-label="Dropdown button"
        disabled={disabled}
        fullWidth
        disableRipple
      >
        {iconPosition === 'left' && <Icon />}
        <Box
          component="span"
          flexGrow="1"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign="left"
        >
          {buttonLabel ?? value ?? placeholder}
        </Box>
        {iconPosition === 'right' && <Icon />}
      </Button>

      <Menu
        id={testid}
        data-testid={testid}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={menuSx}
        className={menuClassName}
      >
        {hasClearButton && (
          <MenuItem
            data-testid="dropdown-menu-item"
            onClick={handleSelectItem({ name: undefined })}
          >
            <ClearIcon /> Clear
          </MenuItem>
        )}
        {Children.toArray(
          options.map((option) => {
            const { name, tooltip, tooltipMaxWidth, action, header, itemClassName } = option
            return (
              <FTGTooltip
                title={tooltip}
                placement="left"
                disabled={!tooltip}
                wrap={option.disabled}
                maxWidth={tooltipMaxWidth || 300}
              >
                {header ? (
                  <Typography variant="h5">{name}</Typography>
                ) : (
                  <MenuItem
                    selected={value === name}
                    data-testid={`dropdown-menu-item-${name}`}
                    onClick={action ? handleAction(action) : handleSelectItem(option)}
                    disabled={option.disabled}
                    className={itemClassName}
                    sx={optionsSx}
                  >
                    <Typography variant="inherit" noWrap>
                      {option.name}
                    </Typography>
                  </MenuItem>
                )}
              </FTGTooltip>
            )
          })
        )}
      </Menu>
    </Box>
  )
}
