import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import Raven from '@core/api/Raven';
import Logo from '@core/components/Logo';

/**
 * Component that will be used as HOC, and will catch JS errors, and show UI friendly message
 * and will send errors to Sentry
 */
const styles = (theme) => ({
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    '& img': { marginBottom: theme.spacing(3) },
  },
  '& h3': {
    marginBottom: theme.spacing(2),
  },
  '& p': {
    marginBottom: theme.spacing(2),
  },
});

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Raven.withScope((scope) => {
      scope.setExtras(errorInfo);
      Raven.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, classes } = this.props;

    if (hasError) {
      return (
        <div className={classes.error}>
          <Logo />
          <Typography variant="h3">An Error Occured</Typography>
          <p>
            Please click <a href="/">here</a> to continue.
          </p>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
};

ErrorBoundary.defaultProps = {
  classes: {},
};

export default withStyles(styles)(ErrorBoundary);
