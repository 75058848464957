import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@core/store'
import { MCQ_STATUSES } from '@author/constants/optionsStatus'

export const selectReferences = createSelector(
  (state: RootState) => state.main.customer?.features.references,
  (state: RootState) => state.author.item?.job.aiModel,
  (state: RootState) => state.author.selectedModel,
  (customerEnabled, itemAiModel, selectedAiModel) => {
    const enabled =
      customerEnabled &&
      ((itemAiModel?.references?.length && selectedAiModel?.id === itemAiModel.id) ||
        !!selectedAiModel?.public_mapper?.has_item_start_references)

    return {
      enabled,
      aiModelId: itemAiModel?.id || selectedAiModel?.id,
      hasItemStartReferences: !!selectedAiModel?.public_mapper?.has_item_start_references,
      customInput: selectedAiModel?.inputSettings?.[0].name,
    }
  }
)

export const keysDistractorsSelector = createSelector(
  (state: RootState) => state.author.content?.answers || [],
  (answers) => {
    return answers.filter(
      (answer) => answer.status === MCQ_STATUSES.KEY || answer.status === MCQ_STATUSES.DISTRACTOR
    )
  }
)

export const excludedOptionsSelector = createSelector(
  (state: RootState) => state.author.content?.answers || [],
  (answers) => {
    return answers.filter((answer) => !answer.status)
  }
)

export const keysSelector = createSelector(keysDistractorsSelector, (selectedKeysDistractors) => {
  return selectedKeysDistractors.filter((answer) => answer.status === MCQ_STATUSES.KEY)
})
