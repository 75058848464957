import React, { useCallback, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useAppSelector } from '@core/store'
import { makeStyles } from '@mui/styles'
import { Alert, Box, Radio } from '@mui/material'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import ConfirmDialog, { useConfirmDialog } from '@core/components/dialogs/dialog-confirm'

import { MCQ } from '@author/constants/questionsTypes'
import {
  KEY,
  optionsStatus,
  SUGGEST_CORRECT,
  SUGGEST_DISTRACTOR,
} from '@author/constants/optionsStatus'
import ContentEditable from '@core/components/ContentEditable/ContentEditable'
import { getOptionPrefix } from '@core/utils/option-prefix'

import RationaleAccordion, { RationaleArrow } from './rationales'

const useStyles = makeStyles((theme) => ({
  contentOption: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1),
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
    '&:hover': {
      background: theme.palette.background.paleGrey,
    },
    '&.content-option-hover': {
      background: theme.palette.background.paleGrey,
    },
    '& .option-text-container': {
      display: 'flex',
      paddingLeft: theme.spacing(1),
      alignItems: 'center',
      paddingBottom: theme.spacing(2),
    },
    '& .option-text-container > div': {
      width: '100%',
    },
    '& .suggested-key-label': {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.greyBlue,
      padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
      '&-bold': {
        color: '#091e42',
        backgroundColor: theme.palette.other.scandalGreen,
      },
    },
    '& .rationale-hepler-text': {
      fontSize: 14,
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    '& .rationale-input': {
      backgroundColor: theme.palette.background.white,
      height: 'auto',
      '& p': {
        margin: 0,
      },
    },
    '& .status-radio-group': {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'flex-end',
      color: theme.palette.text.greyBlue,
      '& .btn-add-rationale': {
        pointerEvents: 'auto',
      },
      '& .status-wrapper': {
        pointerEvents: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& .status': {
        marginLeft: 0,
        paddingRight: theme.spacing(2),
        '& .MuiRadio-root:hover': {
          backgroundColor: 'transparent',
        },
        '&:hover': {
          backgroundColor: theme.palette.background.button,
        },
        '& .status-label': {
          fontSize: '0.875rem',
          textTransform: 'capitalize',
        },
        '& svg': {
          fontSize: '1rem',
        },
      },
      '& .status-key-checked, .status-selected-checked': {
        backgroundColor: theme.palette.other.scandalGreen,
      },
      '& .status-distractor-checked': {
        backgroundColor: theme.palette.other.fairPink,
      },
      '& .status-excluded-checked': {
        backgroundColor: theme.palette.background.button,
      },
    },
  },
}))

type TextEditable = {
  index: number
  onChange: (value, html) => void
  isDragEnabled: boolean
}

// eslint-disable-next-line react/prop-types
const TextEditable = ({ index, onChange, isDragEnabled }) => {
  const answer = useAppSelector((state) => state.author.content?.answers?.[index]?.value)
  const part = useAppSelector((state) => state.author.content?.parts?.[index]?.value)
  const contentType = useAppSelector((state) => state.author.item?.contentType)!
  const options = useAppSelector((state) => state.author.content?.options?.[index]?.value)
  const optionPrefix = useAppSelector((state) => state.author.item?.job.aiModel.optionPrefix)

  return (
    <div className="option-text-container">
      {isDragEnabled ? (
        <DragIndicatorIcon />
      ) : (
        <Box component="span" mr={0.5} data-testid="option-index">
          {getOptionPrefix(index, { contentType, optionPrefix })}
        </Box>
      )}

      <ContentEditable
        text={answer || part || options}
        name="value"
        onChange={onChange}
        readOnly={isDragEnabled}
      />
    </div>
  )
}

type RadioOptions = {
  selectableStatuses: string[]
  statuses: Record<string, string>
  selectedStatusIsSuggest: boolean
  index: number
  originalStatus: string | undefined
  selectedStatus: string
  option: string | number
  handleChangeOptions: RadioGroupProps['onChange']
}

const RadioOptions = ({
  selectableStatuses,
  statuses,
  selectedStatusIsSuggest,
  index,
  originalStatus,
  selectedStatus,
  option,
  handleChangeOptions,
}: RadioOptions) => {
  return (
    <RadioGroup
      className="status-radio-group"
      aria-label={`status ${option}`}
      name={`status ${option}`}
      value={selectedStatus}
      onChange={handleChangeOptions}
    >
      {selectableStatuses.map((selectableStatus) => {
        const statusLower = selectableStatus.toLowerCase()
        const selectableStatusValue = statuses[selectableStatus]
        return (
          <div className="status-wrapper" key={`status-${statusLower}`}>
            {originalStatus === SUGGEST_CORRECT && selectableStatus === KEY && (
              <span
                className={clsx('suggested-key-label', {
                  'suggested-key-label-bold': selectedStatusIsSuggest,
                })}
              >
                Suggested Key
              </span>
            )}
            <FormControlLabel
              key={`${statusLower}-${index}`}
              value={selectableStatusValue}
              control={
                <Radio
                  size="small"
                  classes={{ checked: 'status-radio-checked' }}
                  inputProps={{ 'aria-label': statusLower }}
                />
              }
              label={statusLower}
              classes={{
                root: clsx(
                  'status',
                  selectedStatus === selectableStatusValue && `status-${statusLower}-checked`
                ),
                label: 'status-label',
              }}
            />
          </div>
        )
      })}
    </RadioGroup>
  )
}

type ContentOption = {
  index: number
  rationales?: { value: string }[] | undefined
  originalStatus?: string | undefined
  selectableStatuses: string[]
  setValue: (index: number, name: string, value: string) => void
  hasOptionRationale?: boolean | undefined
  isDragEnabled: boolean
}

const ContentOption = ({
  index,
  rationales,
  originalStatus,
  setValue,
  selectableStatuses,
  hasOptionRationale,
  isDragEnabled,
}: ContentOption) => {
  const classes = useStyles()
  const confirmDeleteRationale = useConfirmDialog()
  const [isRationaleInputVisible, setIsRationaleInputVisible] = useState(false)
  const contentType = useAppSelector((state) => state.author.item?.contentType)!
  const answer = useAppSelector((state) => state.author.content?.answers?.[index]?.status)
  const part = useAppSelector((state) => state.author.content?.parts?.[index]?.status)
  const options = useAppSelector((state) => state.author.content?.options?.[index]?.status)
  const optionPrefix = useAppSelector((state) => state.author.item?.job.aiModel.optionPrefix)
  const status = answer || part || options

  useEffect(() => {
    if (rationales?.length) {
      setIsRationaleInputVisible(true)
    }
  }, [rationales])

  const handleAnswerEdit = useCallback(
    (name, _newValue) => {
      const newValue = typeof _newValue === 'string' ? _newValue.trim() : _newValue
      setValue(index, name, newValue)
    },
    [index, setValue]
  )

  const statuses = useMemo(() => optionsStatus[contentType] || optionsStatus[MCQ], [contentType])

  const handleChangeOptions = (event) => {
    const newValue = event.target.value
    handleAnswerEdit('status', newValue !== statuses.EXCLUDED ? newValue : null)
  }

  const selectedStatus =
    status === SUGGEST_CORRECT || status === SUGGEST_DISTRACTOR || !status
      ? statuses.EXCLUDED
      : status

  const selectedStatusIsSuggest = status === SUGGEST_CORRECT || status === SUGGEST_DISTRACTOR
  const showRationale = isRationaleInputVisible && !(selectedStatus === statuses.EXCLUDED)
  const option = getOptionPrefix(index, { contentType, optionPrefix, wrap: false })

  return (
    <div className={classes.contentOption} data-testid="content-option">
      <TextEditable
        index={index}
        isDragEnabled={isDragEnabled}
        onChange={(textContent) => handleAnswerEdit('value', textContent)}
      />

      <FormControl component="fieldset">
        <RadioOptions
          index={index}
          originalStatus={originalStatus}
          selectableStatuses={selectableStatuses}
          statuses={statuses}
          selectedStatusIsSuggest={selectedStatusIsSuggest}
          selectedStatus={selectedStatus}
          option={option}
          handleChangeOptions={handleChangeOptions}
        />

        {hasOptionRationale && contentType === MCQ && (
          <RationaleArrow
            show={showRationale}
            onClick={() => setIsRationaleInputVisible((prevStatus) => !prevStatus)}
            disabled={selectedStatus === statuses.EXCLUDED}
          />
        )}
      </FormControl>

      <RationaleAccordion
        rationales={rationales}
        show={showRationale}
        onChange={(textContent) => handleAnswerEdit('rationales', textContent)}
        confirmDeleteRationale={confirmDeleteRationale}
      />

      <ConfirmDialog
        state={confirmDeleteRationale}
        id="confirm-delete"
        title="Are you sure?"
        content={
          <Alert severity="info" sx={{ my: 2, '& svg': { fontSize: '2rem' } }}>
            Deleting a rationale cannot be undone. Please proceed with caution!
          </Alert>
        }
      />
    </div>
  )
}

export default ContentOption
