import React, { useEffect, useRef, useState } from 'react'
import { Editor, EditorProps, EditorState, ContentState, Modifier } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { makeStyles, CSSProperties } from '@mui/styles'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import { IconButton } from '@mui/material'
import { countWords, truncate } from '@core/utils/text'

interface ClassName {
  root?: CSSProperties
  editorContainer?: CSSProperties
  content?: CSSProperties
}
interface ContentEditableProps extends Omit<EditorProps, 'editorState' | 'onChange'> {
  text: string
  className?: ClassName
  name?: string
  placeholder?: string
  onChange: (value, html) => void
  maxWords?: number
  clearButton?: boolean
  handleClear?: (text: string, fn: (fn: () => void) => void) => void
  removeNewlines?: boolean
}

const useStyles = makeStyles((theme) => ({
  editor: ({ className = {} }: ContentEditableProps) => ({
    color: '#253858',
    display: 'flex',
    alignItems: 'center',
    '& .DraftEditor-root': {
      ...className.root,
      borderRadius: 4,
      flex: 1,
      '& :focus, &:hover': {
        borderRadius: 4,
        boxShadow: '0 0 0 3px rgb(50 151 253)',
        transition: 'box-shadow 0.33s ease-in-out, background-color 0.1s ease-in-out',
        backgroundColor: 'rgba(50, 151, 253, 0.0333)',
      },
      '& .public-DraftEditor-content': {
        ...className.editorContainer,
        overflow: 'auto',
        padding: theme.spacing(1),
        '& div[data-contents=true]': {
          height: '100%',
        },
      },
    },
    '& .DraftEditor-root .public-DraftEditorPlaceholder-root': {
      padding: theme.spacing(1),
    },
  }),
}))

const ContentEditable = (props: ContentEditableProps) => {
  const {
    text = '',
    onChange,
    placeholder,
    maxWords = 0,
    handleClear,
    clearButton = false,
    removeNewlines = true,
    ...rest
  } = props
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const removeNewlinesRegex = removeNewlines ? /(\r\n|\n|\r)/gm : /(\r|\r)/gm

  const classes = useStyles(props)

  const editor = useRef<Editor | null>(null)
  const refFocus = useRef(false)

  useEffect(() => {
    if (typeof text === 'string' && !refFocus.current) {
      setEditorState(EditorState.createWithContent(ContentState.createFromText(text)))
    }
  }, [text])

  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection()
    const anchorKey = currentSelection.getAnchorKey()
    const currentContent = editorState.getCurrentContent()
    const currentContentBlock = currentContent.getBlockForKey(anchorKey)
    const start = currentSelection.getStartOffset()
    const end = currentSelection.getEndOffset()
    const selectedText = currentContentBlock.getText().slice(start, end)

    return countWords(selectedText) || 0
  }

  const handleBeforeInput = () => {
    if (maxWords === 0) return 'not-handled'
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = countWords(currentContent.getPlainText('')) || 0
    const selectedTextLength = getLengthOfSelectedText()

    if (currentContentLength - selectedTextLength >= maxWords) {
      return 'handled'
    }
    return 'not-handled'
  }

  const handleChange = (value: EditorState) => {
    setEditorState(value)
  }

  useEffect(() => {
    if (refFocus.current) {
      onChange(
        editorState.getCurrentContent().getPlainText('').replace(removeNewlinesRegex, ' ') || '',
        ''
      )
    }
  }, [editorState.getCurrentContent().getPlainText('')])

  // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
  const addPastedContent = (input: string, editorState: EditorState) => {
    const inputLength = countWords(editorState.getCurrentContent().getPlainText('')) || 0
    const remainingLength = maxWords - inputLength

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      truncate(input, remainingLength)
    )
    setEditorState(EditorState.push(editorState, newContent, 'insert-characters'))
  }

  const removeSelection = () => {
    const selection = editorState.getSelection()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const endKey = selection.getEndKey()
    const endOffset = selection.getEndOffset()
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(editorState.getCurrentContent(), selection, 'forward')
      const tempEditorState = EditorState.push(editorState, newContent, 'remove-range')
      setEditorState(tempEditorState)
      return tempEditorState
    }
    return editorState
  }

  const handlePastedText = (pastedText: string) => {
    if (maxWords === 0) return 'not-handled'
    const currentContent = editorState.getCurrentContent()
    const currentContentLength = countWords(currentContent.getPlainText('')) || 0
    const selectedTextLength = getLengthOfSelectedText()

    if (currentContentLength + countWords(pastedText) || 0 - selectedTextLength >= maxWords) {
      const selection = editorState.getSelection()
      const isCollapsed = selection.isCollapsed()
      const tempEditorState = !isCollapsed ? removeSelection() : editorState
      addPastedContent(pastedText, tempEditorState)
      return 'handled'
    }

    return 'not-handled'
  }

  const clearText = () => {
    const clean = () => {
      editor.current?.focus()
      const newEditor = EditorState.push(
        editorState,
        ContentState.createFromText(''),
        'delete-character'
      )
      setEditorState(newEditor)
    }
    if (handleClear) {
      handleClear(editorState.getCurrentContent().getPlainText(''), clean)
      return
    }
    clean()
  }

  return (
    <div className={classes.editor}>
      <Editor
        {...rest}
        ref={editor}
        onFocus={() => {
          refFocus.current = true
        }}
        handlePastedText={handlePastedText}
        onBlur={() => {
          refFocus.current = false
        }}
        handleBeforeInput={handleBeforeInput}
        editorState={editorState}
        placeholder={placeholder || 'Place your text here inside'}
        onChange={handleChange}
      />
      {clearButton && (
        <IconButton size="small" aria-label="Clear text" onClick={clearText}>
          <CancelSharpIcon />
        </IconButton>
      )}
    </div>
  )
}

export default ContentEditable
