import React, { useState } from 'react'
import DOMPurify from 'dompurify'
import { useAppDispatch, useAppSelector } from '@core/store'
import { makeStyles } from '@mui/styles'
import { Box, Typography } from '@mui/material'

import { TextArea } from '@core/components/textfield/textarea'
import FTGDropdown from '@core/components/FTGDropdown'
import { actions } from '@containers/references/references-slice'

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 3,
    '& button': {
      width: '100%',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(
        1.25
      )}`,
      '&:hover': { background: 'transparent' },
    },
  },
  dropdownMenu: {
    '& ul h5': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    '& ul li': {
      paddingLeft: theme.spacing(4),
    },
  },
}))

interface ItemStartReferencesProps {
  loading: boolean
  selectedOption: string | undefined
  setSelectedOption: (value: string | undefined) => void
}

export default function ItemStartReferences({
  loading,
  selectedOption,
  setSelectedOption,
}: ItemStartReferencesProps) {
  const classes = useStyles()
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const dispatch = useAppDispatch()
  const { keywords } = useAppSelector((state) => state.references)

  const dropdownDisabled = loading || !keywords.length

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextAreaValue(e.target.value)
  }

  const options = keywords.length
    ? [
        { name: 'Keywords', header: true },
        ...keywords.map((keyword) => ({
          name: keyword,
        })),
      ]
    : []

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setTextAreaValue('')
      dispatch(actions.set({ keywords: [...keywords, DOMPurify.sanitize(e.target.value)] }))
      e.preventDefault()
    }
  }

  return (
    <>
      <Typography variant="h5">Keywords</Typography>
      <TextArea
        onKeyDown={handleKeyDown}
        inputProps={{
          'aria-label': 'references keywords',
        }}
        value={textAreaValue}
        placeholder="Enter a keyword (or phrases)"
        helperContent={
          <Box display="flex" justifyContent="flex-end">
            <Box fontSize={14} letterSpacing="normal" fontWeight={500} color="text.slateBlue">
              Return [⮐] to Enter
            </Box>
          </Box>
        }
        onChange={handleChange}
      />
      <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>
        Select
      </Typography>
      <FTGDropdown
        className={classes.dropdown}
        testid="item-start-keywords"
        value={selectedOption}
        setValue={(o) => {
          setSelectedOption(o.name)
        }}
        placeholder="Choose keyword to begin"
        options={options}
        menuClassName={classes.dropdownMenu}
        disabled={dropdownDisabled}
        menuSx={{ '& li': { maxWidth: 372, minWidth: 250 } }}
      />
    </>
  )
}
