import {
  DISTRACTOR,
  EXCLUDED,
  SUGGEST_CORRECT,
  SUGGEST_DISTRACTOR,
} from '@author/constants/optionsStatus';
import { isEqual } from 'lodash';

export const mapAnswers = (selectedParts, finalContentParts, defaultStatus = DISTRACTOR) => {
  let updated = !isEqual(selectedParts, finalContentParts);

  if (!selectedParts.length) {
    const parts = finalContentParts.map((part) => ({ ...part, status: defaultStatus }));
    return [parts, updated];
  }

  const statuses = selectedParts.map((part) => part.status);
  const hasOnlySuggested = statuses.every((s) => [SUGGEST_CORRECT, SUGGEST_DISTRACTOR].includes(s));
  if (hasOnlySuggested && selectedParts.length === finalContentParts.length) {
    return [selectedParts, updated];
  }

  const partValues = finalContentParts.map((part) => part.value);
  const parts = finalContentParts.map((part) => {
    let { status } = part;
    if (
      [SUGGEST_CORRECT, SUGGEST_DISTRACTOR].includes(part.status) &&
      partValues.includes(part.value)
    ) {
      updated = true;
      status = EXCLUDED;
    }
    return { ...part, status };
  });

  return [parts, updated];
};
