import React, { useMemo, useCallback } from 'react'
import { FTGSlider } from '@core/components'
import { actions } from '@pages/author/author-slice'
import { useAppDispatch } from '@core/store'

interface Mapper {
  min_temperature: number
  max_temperature: number
}
interface AiModel {
  public_mapper: Mapper
}
interface CreativitySliderProps {
  jobId: string
  aiModel: AiModel
  disabled: boolean
}

const CreativitySlider = ({ jobId, aiModel, disabled }: CreativitySliderProps) => {
  const dispatch = useAppDispatch()
  const creativity = useMemo(() => {
    if (aiModel) {
      const {
        public_mapper: { max_temperature: maxTemperature, min_temperature: minTemperature },
      } = aiModel
      return { maxTemperature, minTemperature }
    }
    return {}
  }, [aiModel])

  const onChange = useCallback((_, newValue: number) => {
    dispatch(actions.set({ selectedCreativity: newValue }))
  }, [])

  const { minTemperature, maxTemperature } = creativity

  if (!(minTemperature && maxTemperature)) return null

  return (
    <FTGSlider
      key={`ftg-slider-${jobId}`}
      title="Generate Creativity"
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default CreativitySlider
