import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@core/store'
import {
  AI,
  AI_REMAINING_ANSWERS,
  DONE,
  CONTENT_DELIVERY,
  IN_PROGRESS,
  NOT_STARTED,
  FAILED,
} from '@author/constants/progressSteps'
import { HOT_TEXT } from '@author/constants/questionsTypes'
import { ItemFake, Item, Batch, BatchLoaded } from './batches-types'

type Batches = BatchLoaded | Batch

// Compare by first content_version
function compareAsc(a: Item, b: Item) {
  if (a.content_versions?.[0]?.created_at > b.content_versions?.[0]?.created_at) return 1
  if (a.content_versions?.[0]?.created_at < b.content_versions?.[0]?.created_at) return -1
  return 0
}

// Finished items are shown first
function doneGenerating(a: Item) {
  if (a.lastQuestion) {
    return -1
  }
  return 1
}

function compareDesc(a: Batches, b: Batches) {
  if (a.createdAt < b.createdAt) return 1
  if (a.createdAt > b.createdAt) return -1

  return 0
}

interface Current extends Omit<Batches, 'step' | 'items'> {
  items: (Item | ItemFake)[]
  isLoading: boolean
  itemsDone: Item[]
  itemsIds: string[]
}

export const selectCurrentBatch = createSelector(
  (state: RootState) => state.batches.batches,
  (state: RootState) => state.batches.selected,
  (state: RootState) => state.author.item?.contentType,
  (batches, selected, contentType): Current | null => {
    if (!batches || !selected || !(selected in batches)) return null

    const batch = batches[selected]

    if (batch.step === 'UNLOADED') {
      return {
        ...batch,
        items: new Array(batch.itemsRequired).fill(null).map((_, index) => ({
          id: `temp-${index}`,
          step: 'AI',
          status: 'NOT_STARTED',
        })),
        isLoading: true,
        itemsDone: [],
        itemsIds: [],
      }
    }

    // the sort method mutate the reference, so is necessary to clone it

    let items: Item[] = []
    if (contentType === HOT_TEXT) {
      items = batch.items ? [...batch.items] : []
    } else {
      items = (batch.items ? [...batch.items] : []).sort(doneGenerating).sort(compareAsc)
    }

    const itemsDone = items.filter((item) => {
      const { step, status: itemStatus } = item
      return (
        ([AI, AI_REMAINING_ANSWERS].includes(step) && itemStatus === DONE) ||
        step === CONTENT_DELIVERY
      )
    })

    const isLoading = items.some(
      ({ status: itemStats }) => itemStats === IN_PROGRESS || itemStats === NOT_STARTED
    )

    const itemsIds = itemsDone.map(({ id }) => id)

    return { ...batch, items, isLoading, itemsDone, itemsIds }
  }
)

export const selectBatches = createSelector(
  (state: RootState) => state.batches.batches,
  (items) => {
    if (!items) return
    return Object.values(items).sort(compareDesc)
  }
)

export const selectAutocomplete = createSelector(
  selectCurrentBatch,
  selectBatches,
  (selected, options) => ({ selected, options })
)

export const selectItemStatus = createSelector(selectCurrentBatch, (currentBatch) => {
  return {
    id: currentBatch?.id,
    allItemsFailed:
      currentBatch?.items.length && currentBatch.items.every((x) => x.status === FAILED),
  }
})
