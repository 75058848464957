import { gql } from '@apollo/client'
import { User, Customer } from './main-slice'

export interface GET_USER_DATA {
  user: (User & { customer: Customer; isInternal: boolean })[]
}

export const GET_USER = gql`
  subscription getUser {
    user {
      id
      email
      role
      firstName: first_name
      lastName: last_name
      picture
      canResetCustomerData: can_reset_customer_data
      canDeleteProjects: can_delete_projects
      role
      isInternal: is_internal
      customer {
        id
        name
        features
        hasOptionRationale: has_option_rationale
      }
    }
  }
`

export const CONFIGURE_USER = gql`
  mutation configureUser {
    configure_user {
      response
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: String!
    $picture: String
    $firstName: String
    $lastName: String
    $lastAccess: timestamptz
  ) {
    update_user_by_pk(
      pk_columns: { id: $id }
      _set: {
        picture: $picture
        first_name: $firstName
        last_name: $lastName
        last_access: $lastAccess
      }
    ) {
      id
    }
  }
`

export const UPDATE_USER_LAST_ACCESS = gql`
  mutation updateUserLastAccess($id: String!, $lastAccess: timestamptz) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { last_access: $lastAccess }) {
      id
    }
  }
`
