import { gql } from '@apollo/client'

export const GET_ITEM_SET = gql`
  query fetchItemSet($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      status
      selectedFlavors: selected_flavors
      type: content_type
      project {
        id
        name
        access {
          write
          download
        }
      }
      aiModel: ai_model {
        public_mapper
        flavors
        comments
        name
        qualityMetrics: quality_metrics
        type
      }
      job {
        id
        ownerId: owner_id
      }
      lastContent: last_content_version(path: "content")
      finalContent: final_content_version(path: "content")
      qualityMetrics: last_content_version(path: "quality_metrics")
      comments: last_content_version(path: "comments")
      children {
        id
        type: content_type
        lastContent: last_content_version(path: "content")
        finalContent: final_content_version(path: "content")
        status
        step
        contentFilter: content_filter
        aiModelId: ai_model_id
      }
    }
  }
`

export const GET_ITEM_CONTENT = gql`
  query fetchItemContent($id: uuid!) {
    item: item_by_pk(id: $id) {
      id
      lastContent: last_content_version(path: "content")
      finalContent: final_content_version(path: "content")
      qualityMetrics: last_content_version(path: "quality_metrics")
      status
      step
      type: content_type
      contentFilter: content_filter
    }
  }
`

export const GET_ITEM_UPDATES = gql`
  subscription fetchItemSetStatus($id: uuid!) {
    item: item_by_pk(id: $id) {
      status
      step
      qualityMetrics: last_content_version(path: "quality_metrics")
      children {
        id
        status
        step
      }
    }
  }
`

export const SAVE_ITEM = gql`
  mutation saveItem($id: uuid!, $projectId: uuid) {
    deliver_content(item_id: $id, project_id: $projectId) {
      ok
    }
  }
`

export const REGENERATE_ITEM = gql`
  mutation regenerateItem($itemId: uuid!, $clearStem: Boolean) {
    generate_item(item_id: $itemId, clear_stem: $clearStem) {
      items_ids
      job_id
    }
  }
`

export const REGENERATE_ROOT_QUESTION = gql`
  mutation regenerateRootQuestion($itemId: uuid!, $aiModelId: uuid!) {
    generate_item(item_id: $itemId, ai_model_id: $aiModelId, clear_root_stimulus: true) {
      items_ids
    }
  }
`

export const GENERATE_ITEM = gql`
  mutation generateItem(
    $itemId: uuid
    $empty: Boolean = false
    $aiModelId: uuid
    $rootId: uuid
    $flavors: [String]
  ) {
    generate: generate_item(
      item_id: $itemId
      empty: $empty
      ai_model_id: $aiModelId
      root_id: $rootId
      flavors: $flavors
    ) {
      items_ids
    }
  }
`

export const UPDATE_ITEM_CONTENT = gql`
  mutation updateItemContent($content: jsonb!, $id: uuid!) {
    upsert_content_version(
      content_version: { content: $content, item_id: $id, type: "USER_INPUT" }
    ) {
      ok
    }
  }
`

export const UPDATE_ITEM_ROOT = gql`
  mutation updateItemContent($content: jsonb!, $id: uuid!, $qualityMetrics: jsonb!) {
    upsert_content_version(
      content_version: {
        content: $content
        item_id: $id
        type: "USER_INPUT"
        quality_metrics: $qualityMetrics
      }
    ) {
      ok
    }
  }
`
