export enum FilterType {
  all = 'all',
  clone = 'clone',
}

interface Aggregate {
  count: number
}

interface Clones {
  aggregate: Aggregate
}

interface DomainTag {
  name: string
  id: string
}

interface AiModel {
  name: string
  domain: string
  qualityMetrics: any[]
}

interface Job {
  id: string
  status: string
  domainTag: DomainTag
}

interface DomainTags {
  tags: DomainTag[] | []
  topics: string[]
  hasClones: boolean
}

interface SortParams {
  column: string
  order: 'asc' | 'desc'
}

export interface Item {
  id: string
  job: Job
  clonedFromId?: string
  clones: Clones
  aiModel: AiModel
  topic: string
  contentType: string
}

export interface Project {
  id: string
  name: string
  createdAt: string
  owner?: {
    id: string
    email: string
    role: string
  }
  access: {
    read: boolean
    write: boolean
    download: boolean
    user?: {
      id: string
      email: string
    }
  }[]
  users?: Record<string, { id: string; email: string }>
  stats: {
    items: number
    clones: number
    models: string[]
    domains: string[]
    topics: string[]
  }
}

export interface ProjectFilter {
  type: FilterType
  domain: string
  topic: string
}

export interface State {
  projects: Project[] | [] | null
  draftItemsLength: number
  filter: ProjectFilter
  domainTags: DomainTags
  loading: boolean
  sortParams: SortParams
  selectedFilter?: string | null
  users?: { id: string; email: string; count: number }[]
}
