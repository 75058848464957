import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorHandler from '@core/api/ErrorHandler'

import * as queries from './batches-queries'
import { actions } from './batches-slice'

export const discardBatches = createAsyncThunk(
  'author/discardBatches',
  async (_, { extra, getState }) => {
    try {
      const ids = getState().batches.selectedDiscard

      const { data } = await extra.client.mutate({
        mutation: queries.DISCARD_BATCH_JOB,
        variables: { ids },
      })

      if (data?.update_job.affected_rows) {
        return { deleted: true }
      }
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({ severity: 'error', message })
    }

    return { deleted: false }
  }
)

export const regenerateBatches = createAsyncThunk(
  'author/regenerateBatches',
  async (_, { extra, getState, dispatch }) => {
    try {
      const { batches } = getState()

      if (!batches?.batches || !batches?.selected) return

      const {
        id: jobId,
        itemsRequired,
        selectedFlavors,
        customPassage,
        temperatePercentage,
        aiModel,
      } = batches.batches[batches.selected]

      const { data } = await extra.client.mutate({
        mutation: queries.REGENERATE_BATCH_ITEMS,
        variables: {
          jobId,
          modelId: aiModel.id,
          itemsRequired,
          flavors: selectedFlavors,
          customPassage,
          temperatePercentage,
        },
      })

      dispatch(actions.set({ selected: data.generate_item.job_id }))

      return { data, jobId }
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({
        message: `Error while regenerating the batch ${message}`,
        severity: 'error',
      })

      throw error
    }
  }
)
