import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import { TextArea } from '@core/components/textfield/textarea';
import { countWords, truncate } from '@core/utils/text';
import { WordCount } from '@core/components';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  helpText: {
    fontSize: '0.875rem',
    letterSpacing: 'normal',
  },
  helpTextOpacity: {
    opacity: 0.7,
  },
  helpTextWarning: {
    color: theme.palette.text.red,
    marginBottom: 0,
  },
}));

const CustomPassage = ({ setCustomPassage, customPassage, maxWords }) => {
  const MIN_WORDS = 50;
  const classes = useStyles();

  const [localValue, setLocalValue] = useState(customPassage);

  useEffect(() => {
    if (customPassage !== localValue) {
      setLocalValue(customPassage);
    }
  }, [localValue, customPassage]);

  const wordsCount = useMemo(() => {
    return countWords(localValue);
  }, [localValue]);

  const hasFewWords = useMemo(() => {
    return localValue && wordsCount < MIN_WORDS;
  }, [wordsCount, localValue]);

  const handleOnChange = (event) => {
    const text = truncate(event.target.value, maxWords);
    setLocalValue(text);
    setCustomPassage(text);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h5" gutterBottom id="custom-passage-label">
        Custom Passage
      </Typography>
      <TextArea
        error={hasFewWords}
        onChange={handleOnChange}
        multiline
        placeholder="Type or paste your content here…"
        rows={8}
        value={localValue}
        helperContent={<WordCount text={localValue} maxWords={maxWords} />}
        variant="outlined"
        resize="vertical"
        inputProps={{ 'aria-labelledby': 'custom-passage-label' }}
      />

      {hasFewWords && (
        <p className={clsx(classes.helpText, classes.helpTextWarning)}>
          We recommend a passage with at least {MIN_WORDS} words.
        </p>
      )}
    </div>
  );
};

CustomPassage.propTypes = {
  setCustomPassage: PropTypes.func,
  customPassage: PropTypes.any,
  maxWords: PropTypes.number.isRequired,
};
CustomPassage.defaultProps = {
  setCustomPassage: () => {},
  customPassage: '',
};

export default CustomPassage;
