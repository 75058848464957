import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { IconButton, SvgIcon } from '@mui/material';

import { makeStyles } from '@mui/styles';

import FTGTooltip from '@core/components/FTGTooltip';
import CachedIcon from '@mui/icons-material/Cached';
import { OPTIONS } from '../constants';

const useStyles = makeStyles((theme) => ({
  regenerateBtn: {
    padding: theme.spacing(1),
    '&:hover': { background: theme.palette.other.lightBlue },
    '&.Mui-disabled': { opacity: 0.5 },
  },
}));

const getTitle = (type, name) => {
  if (type === OPTIONS) return ['Regenerate excluded options', type];

  return [`Regenerate ${name}`, type];
};

const RegenerateButton = ({ name, disabled, onRegenerate, type }) => {
  const ref = useRef();
  const classes = useStyles();
  const [title] = getTitle(type, name);

  return (
    <FTGTooltip title={title} placement="right" disabled={disabled}>
      <div data-testid="restore-generate" ref={ref}>
        <IconButton
          disabled={disabled}
          className={classes.regenerateBtn}
          onClick={onRegenerate}
          aria-label="Regenerate item"
          size="large"
        >
          <SvgIcon color="primary" component={CachedIcon} viewBox="0 0 24 24" />
        </IconButton>
      </div>
    </FTGTooltip>
  );
};

RegenerateButton.propTypes = {
  name: PropTypes.string.isRequired,
  onRegenerate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

RegenerateButton.defaultProps = {
  disabled: false,
};

export default RegenerateButton;
