import React from 'react'
import { AppBar, Box, Container } from '@mui/material'

import { useAppSelector } from '@core/store'
import { HEADER_HEIGHT } from '@core/constants/styles'
import UserProfile from '@core/components/User/Profile'

import Logo from './Logo'
import Tabs from './Tabs'

export default function Header() {
  const user = useAppSelector((state) => state.main.user)

  if (!user) {
    return null
  }

  return (
    <>
      <AppBar
        component="div"
        sx={{
          bgcolor: 'common.white',
          boxShadow: '0 8px 16px -4px rgba(9, 30, 66, 0.25), 0 0 1px 0 rgba(9, 30, 66, 0.31)',
          height: HEADER_HEIGHT,
          minWidth: '100%',
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Logo />
          <Tabs />
          <UserProfile />
        </Container>
      </AppBar>
      <Box height={HEADER_HEIGHT} />
    </>
  )
}
