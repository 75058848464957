import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ProjectTag from '@deliver/components/Shared/Tag';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: ({ expanded }) => (expanded ? 'auto' : '1rem'),
    whiteSpace: ({ expanded }) => (expanded ? 'pre-wrap' : 'no-wrap'),
    maxWidth: `calc(100% - ${theme.spacing(1)})`,
    padding: `2px 0`,
    margin: theme.spacing(0.5),
    marginLeft: 0,
    boxSizing: 'content-box',
    border: `1px solid ${theme.palette.tag.dusk.main}`,
    '& .MuiChip-label': {
      whiteSpace: ({ expanded }) => (expanded ? 'pre-wrap' : 'no-wrap'),
      lineHeight: 1.4,
    },
  },
  clickable: {
    '&:hover': { borderColor: theme.palette.tag.aquaBlue.main },
  },
}));

/**
 * Multiline tag component
 */

const MultilineTag = ({ label, ...rest }) => {
  const tagRef = React.createRef();
  const [isEllipsis, setIsEllipsis] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles({ expanded });
  useEffect(() => {
    if (tagRef?.current?.firstChild.scrollWidth > tagRef?.current?.firstChild.clientWidth) {
      setIsEllipsis(true);
    }
  }, []);

  return (
    <ProjectTag
      ref={tagRef}
      clickable={isEllipsis && !expanded}
      classes={classes}
      size="small"
      bgcolor="dusk"
      label={label}
      onClick={() => setExpanded(true)}
      {...rest}
    />
  );
};

MultilineTag.propTypes = {
  label: PropTypes.string.isRequired,
};

MultilineTag.defaultProps = {};

export default MultilineTag;
