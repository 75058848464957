import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const NOTIFICATION_TYPES = {
  DEFAULT: 'default',
  WARNING: 'warning',
};

/**
 * Snackbar Context that will store current notification information and setters.
 */
const SnackbarContext = React.createContext();

/**
 * SnackbarContextProvider which will provide the current notification and
 * setters to update that notification to child components.
 */
function SnackbarContextProvider({ children }) {
  const [notification, setNotification] = useState();

  const clearNotification = () => {
    setNotification({ message: null, type: null, toast: null, options: {} });
  };

  const handleSetNotification = (message, type = NOTIFICATION_TYPES.DEFAULT, options) => {
    setNotification({ message, type, options });
  };

  const handleSetToastNotification = (message, options = {}) => {
    handleSetNotification(message, NOTIFICATION_TYPES.DEFAULT, { ...options, toast: true });
  };

  const setErrorNotification = (message, options) => {
    handleSetNotification(message, NOTIFICATION_TYPES.WARNING, options);
  };

  const value = {
    notification,
    setNotification: handleSetNotification,
    setToastNotification: handleSetToastNotification,
    setErrorNotification,
    clearNotification,
  };

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
}
SnackbarContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export { SnackbarContext, SnackbarContextProvider };
