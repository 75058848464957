import React from 'react'
import { Box, Alert } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'

import ConfirmDialog, {
  useConfirmDialog,
  FormComponent,
} from '@core/components/dialogs/dialog-confirm'

function Content({ options }: FormComponent<object>) {
  return (
    <Alert severity="error" icon={<WarningAmber sx={{ display: 'flex', alignSelf: 'center' }} />}>
      <Box>
        {options?.hideKey === 'regenerate-options' && (
          <>
            Regeneration will delete <strong>ALL</strong> excluded item options.
            <br /> This action cannot be undone. Proceed with caution.
          </>
        )}
        {options?.hideKey === 'regenerate-question' && (
          <>
            Regeneration will delete <strong>ALL</strong> item options. <br /> This action cannot be
            undone. Proceed with caution.
          </>
        )}
      </Box>
    </Alert>
  )
}

export function useConfirmRegenerate() {
  return useConfirmDialog()
}

interface Props {
  state: ReturnType<typeof useConfirmDialog>
}

export default function DialogRegenerate({ state }: Props) {
  return (
    <ConfirmDialog
      id="confirm-regenerate"
      state={state}
      title="Are you sure?"
      RenderContent={Content}
      cancelText="Cancel"
      confirmText="Continue"
    />
  )
}
