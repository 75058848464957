import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@core/store'

export const selectComments = createSelector(
  (state: RootState) => state.author.item?.lastContentVersion?.comments,
  (state: RootState) => state.comments.comments,
  (itemComments, comments) => {
    if (comments.length > 0) return comments
    return itemComments
  }
)
