import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Tooltip from '@core/components/FTGTooltip'

import { useAppDispatch, useAppSelector } from '@core/store'
import { actions } from './batches-slice'
import * as selectors from './batches-selectors'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface Props {
  onChange: (id: string) => void
}

export default function BatchesAutocomplete({ onChange }: Props) {
  const dispatch = useAppDispatch()

  const selectedDiscard = useAppSelector((root) => root.batches.selectedDiscard)
  const { selected, options } = useAppSelector(selectors.selectAutocomplete)

  const handleChange = (event, newOption) => {
    onChange(newOption.id)
  }

  const handleClickCheckbox = (e, option) => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(actions.selectDiscard(option.id))
  }

  return (
    <Autocomplete
      sx={{ mt: 2 }}
      color="secondary"
      options={options || []}
      disabled={options?.length === 0}
      value={selected || null}
      fullWidth
      openOnFocus
      disableClearable
      onChange={handleChange}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => (
        <ListItem {...props} key={option.id} sx={{ padding: '0px !important' }}>
          <ListItemButton role={undefined} dense>
            <Tooltip title="I've finished with this batch" placement="left">
              <ListItemIcon onClick={(e) => handleClickCheckbox(e, option)} sx={{ minWidth: 30 }}>
                <Checkbox
                  icon={icon}
                  edge="start"
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selectedDiscard.includes(option.id)}
                  inputProps={{ 'aria-labelledby': option.id }}
                  color="secondary"
                />
              </ListItemIcon>
            </Tooltip>
            <Tooltip title={option.tooltip} placement="top" disabled={!option.tooltip}>
              <ListItemText id={option.id} primary={option.name} secondary={option.date} />
            </Tooltip>
          </ListItemButton>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={!options ? 'Loading...' : 'Select Batch'}
          placeholder="search"
        />
      )}
    />
  )
}
