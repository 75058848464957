import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import { countWords } from '@core/utils/text'

interface WordCountProps {
  text: string
  maxWords: number
}

const WordCount = ({ text, maxWords }: WordCountProps) => {
  const wordCountText = useMemo(() => {
    const numberOfWords = countWords(text)

    if (maxWords) return `${numberOfWords}/${maxWords} words`

    return `${numberOfWords} word${numberOfWords !== 1 ? 's' : ''}`
  }, [text, maxWords])

  return (
    <Box
      sx={{
        fontSize: 14,
        letterSpacing: 'normal',
        opacity: 0.7,
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {wordCountText}
    </Box>
  )
}

export default WordCount
