import ErrorHandler from '@core/api/ErrorHandler'
import { createAsyncThunk } from '@reduxjs/toolkit'
import * as queries from './comments-queries'

export const saveComment = createAsyncThunk(
  'comments/saveComment',
  async (_, { extra, getState }) => {
    const { itemId } = getState().comments
    const { comments } = getState().comments

    try {
      await extra.client.mutate({
        mutation: queries.UPDATE_ITEM_COMMENTS,
        variables: {
          itemId,
          comments,
        },
      })
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error saving comment: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)
