import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    '& .title': {
      display: 'flex',
      paddingBottom: `${theme.spacing(3)}`,
      fontSize: '1rem',
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    '& .item-container': {
      '& .item-info': {
        display: 'flex',
        padding: theme.spacing(1),
        '& .item-info-text': {
          color: theme.palette.text.slateBlue,
          textAlign: 'start',
        },
        '& .item-info-digit': {
          borderRadius: '10px',
          fontSize: '0.844rem',
          fontWeight: 600,
          lineHeight: 1.33,
          padding: '1px 5px 0 5px',
          textAlign: 'center',
          width: theme.spacing(6),
          height: theme.spacing(2.3),
          backgroundColor: theme.palette.tag.dusk.main,
          color: theme.palette.tag.dusk.contrastText,
        },
      },
    },
  },
}));

const GridQualityMetrics = ({ title, options }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.gridContainer}>
      {title && (
        <Grid item xs={12} className="title">
          {title}
        </Grid>
      )}

      <Grid container item className="item-container">
        {options?.map(({ value, name }) => (
          <Grid key={`item-${name}`} container item data-testid="quality-metric-item">
            <Grid item xs={6} className="item-info" data-testid="item-info">
              <Typography variant="body2" gutterBottom className="item-info-text">
                {name}
              </Typography>
            </Grid>
            <Grid item xs={6} className="item-info" data-testid="item-info">
              <Typography variant="h3" gutterBottom className="item-info-digit">
                {value ? value.toFixed(1) : 0}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

GridQualityMetrics.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
};

GridQualityMetrics.defaultProps = {
  title: '',
  options: [],
};

export default GridQualityMetrics;
