import { MPFRQ } from '@author/constants/questionsTypes'

const options = {
  ABC: (index: number) => String.fromCharCode(65 + index),
  '123': (index: number) => index + 1,
  disabled: () => null,
}

export type OptionPrefixType = keyof typeof options

interface Config {
  contentType: string
  optionPrefix?: OptionPrefixType
  wrap?: boolean
}

export function getOptionPrefix(index: number, { optionPrefix, wrap = true, contentType }: Config) {
  if (optionPrefix === 'disabled') return ''
  let type = optionPrefix

  if (!type) {
    type = contentType === MPFRQ ? '123' : 'ABC'
  }

  let value = options[type](index)

  if (wrap) {
    value = `(${value})`
  }

  return value
}
