import React from 'react'
import { Box, StyleProps, Typography } from '@mui/material'
import { Inputs, Option } from '@pages/author/author-types'
import FTGDropdown from '@core/components/FTGDropdown'

interface FlexibleProps {
  input: Inputs
  isLoading?: boolean
  onChange: (value: string) => void
}

const style: StyleProps = {
  dropdown: {
    bgcolor: 'background.button',
    mt: 2,
    mb: 3,
    borderRadius: 0.2,
    '& button': {
      width: '100%',
      '&: hover': { background: 'transparent' },
      '& span': {
        justifyContent: 'space-between',
      },
    },
  },
}

export const FlexibleOptions = (props: FlexibleProps) => {
  const { input, isLoading = false, onChange } = props
  const { title, value, placeholder, options } = input
  const optionsConverted = options?.map((option) => ({
    name: option.text,
  }))

  const setValue = (option) => {
    onChange(option.name)
  }

  return (
    <Box mb={1}>
      <Typography variant="h5">{title}</Typography>
      <FTGDropdown
        sx={style.dropdown}
        value={(value as Option)?.text}
        setValue={setValue}
        placeholder={placeholder}
        options={optionsConverted as any[]}
        disabled={isLoading}
      />
    </Box>
  )
}
