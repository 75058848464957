import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import Error from '@mui/icons-material/Error';

import Notification from '@author/components/Shared/Notification';

const useStyles = makeStyles((theme) => ({
  contentLabel: {
    marginTop: theme.spacing(2),
    fontSize: '0.875rem',
    boxShadow: 'none',
    fontWeight: '400',
    letterSpacing: '-0.12px',
    padding: 0,
    color: '#0052cc',
    '& .info-icon': {
      fontSize: '1.5rem',
      marginRight: theme.spacing(1),
      fill: '#0052cc',
    },
  },
}));

const ContentLabelNotification = ({ label, text }) => {
  const classes = useStyles({ label });
  return useMemo(
    () => (
      <>
        {(label === 'sensitive' || label === 'unsafe') && (
          <Notification className={classes.contentLabel}>
            <Error className="info-icon" />
            {text || ' Content is potentially sensitive'}
          </Notification>
        )}
      </>
    ),
    [label]
  );
};

ContentLabelNotification.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
};

ContentLabelNotification.defaultProps = {
  label: '',
  text: '',
};

export default ContentLabelNotification;
