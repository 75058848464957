import React, { useState, useContext, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'

import IconButton from '@mui/material/IconButton'
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { Box, SxProps } from '@mui/material'

import ErrorHandler from '@core/api/ErrorHandler'
import FTGTooltip from '@core/components/FTGTooltip'
import { SnackbarContext } from '@core/providers'

import { UPDATE_ITEM_VOTE, DELETE_ITEM_VOTE } from '@author/queries'

const THUMB_UP = 1
const THUMB_DOWN = -1
const DELETE = 0

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  voteButton: {
    color: 'text.slateBlue',
    '&:hover': {
      backgroundColor: ' background.itemVoteButton',
    },
    '&:first-child': {
      marginRight: 1.5,
    },
  },
}

type Vote = {
  votes: { vote: number }[] | undefined
  itemId: string
  userId: string | undefined
}

/**
 * Displays thumbs up and thumbs down to like or dislike an item
 */
export const Vote = ({ itemId, userId, votes }: Vote) => {
  const [selected, setSelected] = useState<number | null>()
  const client = useApolloClient()
  useEffect(() => {
    if (votes?.length) {
      const { vote } = votes[0]
      setSelected(vote)
    } else {
      setSelected(null)
    }
  }, [votes])

  const { setErrorNotification } = useContext(SnackbarContext)

  const handleClick = (vote) => async () => {
    const previous = selected
    try {
      const [mutation, variables] =
        vote === DELETE
          ? [DELETE_ITEM_VOTE, { itemId, userId }]
          : [UPDATE_ITEM_VOTE, { itemId, vote }]

      const { data } = await client.mutate({
        mutation,
        variables,
      })

      if (data.insert_item_vote_one?.vote) {
        setSelected(data.insert_item_vote_one.vote)
      }
      if (data.delete_item_vote_by_pk?.item_id === itemId) {
        setSelected(null)
      }
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      setErrorNotification(message)
      setSelected(previous)
    }
  }

  const isThumbsUpSelected = selected === THUMB_UP
  const isThumbsDownSelected = selected === THUMB_DOWN

  return (
    <Box sx={styles.container}>
      <FTGTooltip
        // eslint-disable-next-line max-len
        title="We are continually improving the accuracy of this Generate AI Model and you can help us by simply voting–up or voting–down the item."
        placement="bottom"
        leaveDelay={200}
      >
        <div>
          <IconButton
            key={THUMB_UP}
            aria-label="Like item"
            disableRipple={false}
            sx={styles.voteButton}
            onClick={handleClick(isThumbsUpSelected ? DELETE : THUMB_UP)}
            size="large"
          >
            {selected === THUMB_UP ? (
              <ThumbUpIcon aria-label="selected-thumb-up" fontSize="small" />
            ) : (
              <ThumbUpAltOutlinedIcon aria-label="thumb-up" fontSize="small" />
            )}
          </IconButton>
          <IconButton
            key={THUMB_DOWN}
            aria-label="Dislike item"
            disableRipple={false}
            sx={styles.voteButton}
            onClick={handleClick(isThumbsDownSelected ? DELETE : THUMB_DOWN)}
            size="large"
          >
            {selected === THUMB_DOWN ? (
              <ThumbDownIcon aria-label="selected-thumb-down" fontSize="small" />
            ) : (
              <ThumbDownAltOutlinedIcon aria-label="thumb-down" fontSize="small" />
            )}
          </IconButton>
        </div>
      </FTGTooltip>
    </Box>
  )
}

export default Vote
