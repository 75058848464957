import { gql } from '@apollo/client'

export const GET_MODELS = gql`
  query get_models {
    models: ai_model(where: { is_archived: { _eq: false } }) {
      id
      flavors
      comments
      public_mapper
      name
      passages
      inputSettings: input_settings
      qualityMetrics: quality_metrics
      type
      internalName: internal_model_name
    }
  }
`

export const GET_ITEM = gql`
  query item($itemId: uuid!, $userId: String!) {
    item: item_by_pk(id: $itemId) {
      id
      isArchived: is_archived
      flavors
      contentType: content_type
      job {
        id
        itemsRequired: items_required
        aiModel: ai_model {
          id
          public_mapper
          flavors
          comments
          name
          qualityMetrics: quality_metrics
          type
          references: references_settings(path: "taxonomies")
          internalName: internal_model_name
          optionPrefix: public_mapper(path: "$.option_prefix")
        }
        ownerId: owner_id
      }
      project {
        id
        name
        ownerId: owner_id
        access(where: { user_id: { _eq: $userId } }) {
          download
          read
          write
        }
      }
      finalContentVersion: final_content_version
      contentFilter: content_filter
      originalContentVersion: original_content_version
      lastContentVersion: last_content_version
      votes {
        vote
      }
      inputs
    }
  }
`

export const DELIVER_CONTENT = gql`
  mutation deliver_content($itemId: uuid!, $projectId: uuid) {
    deliver_content(item_id: $itemId, project_id: $projectId) {
      ok
    }
  }
`

export const UPDATE_CONTENTS = gql`
  mutation update_contents(
    $content: jsonb!
    $itemId: uuid!
    $qualityMetrics: jsonb!
    $comments: jsonb
    $createdAt: timestamptz
  ) {
    upsert_content_version(
      content_version: {
        content: $content
        quality_metrics: $qualityMetrics
        comments: $comments
        item_id: $itemId
        type: "USER_INPUT"
        created_at: $createdAt
      }
    ) {
      ok
      updatedAt: updated_at
    }
  }
`

export const UPDATE_ITEM_COMMENTS = gql`
  mutation update_item_comments($itemId: uuid!, $comments: jsonb) {
    upsert_content_version(
      content_version: { comments: $comments, item_id: $itemId, type: "USER_INPUT" }
    ) {
      ok
    }
  }
`

export const CLONE_ITEM = gql`
  mutation clone_item(
    $itemId: uuid!
    $clearAnswers: Boolean
    $filterAnswers: Boolean
    $clearStem: Boolean
  ) {
    cloneItem: clone_item(
      item_id: $itemId
      clear_answers: $clearAnswers
      filter_answers: $filterAnswers
      clear_stem: $clearStem
    ) {
      clonedItemId: cloned_item_id
    }
  }
`

export const GENERATE_ITEM_SET = gql`
  mutation generate_item_set(
    $customPassage: String
    $flavors: [String]
    $modelId: uuid
    $temperaturePercentage: Float
  ) {
    generate_item_set(
      custom_passage: $customPassage
      flavors: $flavors
      ai_model_id: $modelId
      temperature_percentage: $temperaturePercentage
    ) {
      items_ids
      job_id
    }
  }
`

export const GENERATE_ITEM = gql`
  mutation generate_item(
    $modelId: uuid
    $itemsRequired: Int
    $itemId: uuid
    $temperaturePercentage: Float
    $flavors: [String]
    $customPassage: String
    $title: String
    $autogenerateBlocks: Boolean = false
    $empty: Boolean = false
    $meta: jsonb = {}
    $inputs: jsonb = {}
  ) {
    generate_item(
      ai_model_id: $modelId
      items_required: $itemsRequired
      item_id: $itemId
      temperature_percentage: $temperaturePercentage
      flavors: $flavors
      custom_passage: $customPassage
      title: $title
      autogenerate_blocks: $autogenerateBlocks
      empty: $empty
      meta: $meta
      inputs: $inputs
    ) {
      items_ids
      job_id
    }
  }
`
