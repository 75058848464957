export const MCQ = 'MCQ';
export const FRQ = 'FRQ';
export const LIKERT = 'LIKERT';
export const MPFRQ = 'MPFRQ';
export const STORY = 'STORY';
export const CLOZE = 'CLOZE';
export const ITEM_SET = 'ITEM_SET';
export const PASSAGE_BUILDER = 'PASSAGE_BUILDER';
export const HOT_TEXT = 'HOT_TEXT';

export const subTitles = {
  MCQ: {
    question: 'Item Stem',
    options: 'Options',
    passage: 'Stimulus',
  },
  FRQ: {
    question: 'Stimulus',
    options: 'Item Prompts',
    passage: 'Passage',
  },
  MPFRQ: {
    question: 'Stimulus',
    options: 'Item Prompts',
    passage: 'Passage',
  },
  HOT_TEXT: {
    question: 'Item Stem',
    options: 'Options',
    passage: 'Stimulus',
  },
  LIKERT: {
    question: 'Item Stem',
    options: 'Options',
    passage: 'Stimulus',
  },
};
