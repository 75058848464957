import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { routeEnter } from '@containers/main/main-slice'

import type { State, AiModel, Permission } from './users-types'
import * as thunkActions from './users-thunk'

export function getInitialState(): State {
  return {
    users: null,
    userManager: {
      open: false,
    },
    selected: [],
    selectAll: false,
    searchUser: '',
    userStats: null,
    sortParams: {
      column: '',
      order: 'asc',
    },
    count: 0,
    tickets: {},
  }
}

interface Item {
  aiModel: AiModel
}

export interface ModelData {
  id: string
  name: string
  owner: {
    id: string
    email: string
  }
  items: Item[]
}

export interface Model {
  projects: ModelData[]
}

export const slice = createSlice({
  name: 'users',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    increaseCount(state: State) {
      state.count += 1
    },
    setSelectedUser(state: State, action: PayloadAction<string>) {
      if (!state.selected.includes(action.payload)) state.selected.push(action.payload)
      else state.selected = state.selected.filter((x) => x !== action.payload)
    },
    setSortParams(state: State, action: PayloadAction<string>) {
      state.sortParams.column = action.payload
      state.sortParams.order = state.sortParams.order === 'asc' ? 'desc' : 'asc'
    },
    setTicket(state, action: PayloadAction<{ email: string; value?: string; loading: boolean }>) {
      const { email, loading, value } = action.payload
      state.tickets[email] = { loading, value }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkActions.deleteUser.fulfilled, (state: State) => {
      state.selected = []
    })

    builder.addCase(thunkActions.setModelPermission.fulfilled, (state: State, action) => {
      const { aiModelInternalName } = action.meta.arg
      if (!state.models) return
      const model = state.models?.findIndex((i) => i.internalName === aiModelInternalName)

      if (model === -1) return

      const convert = (acc: Record<string, Permission>, curr: Permission) => ({
        ...acc,
        [curr.userId]: curr,
      })

      const current = state.models[model].permissions.reduce(convert, {})
      const updated = action.payload.result.returning.reduce(convert, current)
      state.models[model].permissions = Object.values(updated)
    })

    // clear some state when enter the page
    builder.addCase(routeEnter, (state: State, action) => {
      if (action.payload.path === '/users') {
        Object.assign(state, {
          searchUser: '',
          selected: [],
          sortParams: {
            column: '',
            order: 'asc',
          },
        })
      }
    })
  },
})

export default slice

export const { actions } = slice
