import { gql } from '@apollo/client'

export const GET_CUSTOMERS = gql`
  query getCustomers {
    customer(order_by: { name: asc }) {
      id
      name
      features
      createdAt: created_at
    }
  }
`

export const SWITCH_CUSTOMER = gql`
  mutation switchCustomer($customerId: uuid!) {
    switch_customer(customer_id: $customerId) {
      status
    }
  }
`
