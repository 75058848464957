import React, { memo } from 'react'
import { useAppDispatch, useAppSelector } from '@core/store'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useToggle from '@author/hooks/useToggle'
import EditableText from '@author/components/Shared/EditableText'
import * as sessionStorage from '@core/utils/session-storage'
import { actions } from '@pages/author/author-slice'
import { subTitles } from '@author/constants/questionsTypes'
import { CUSTOM_PASSAGE, PASSAGE } from './constants'

interface PassageArrowProps {
  show: boolean
  onClick: () => void
}

export const PassageArrow = ({ show, onClick }: PassageArrowProps) => {
  const content = show ? 'Hide Stimulus' : 'Show Stimulus'
  return (
    <AccordionSummary aria-label={content} expandIcon={<ExpandMoreIcon />} onClick={onClick}>
      <Typography variant="subtitle2" align="center">
        {content}
      </Typography>
    </AccordionSummary>
  )
}

const Passage = () => {
  const name = PASSAGE
  const dispatch = useAppDispatch()
  const contentType = useAppSelector((state) => state.author.item?.contentType)
  const jobId = useAppSelector((state) => state.author.item?.job.id)
  const passage = useAppSelector((state) => state.author.content?.passage)
  const customPassage = useAppSelector((state) => state.author.content?.custom_passage)
  const canEditPassage = useAppSelector((state) => state.author.content?.can_edit_passage)

  const showStimulusKey = `${jobId}-show-stimulus`
  const showStimulus = sessionStorage.get(showStimulusKey, true)
  const { status, toggleStatus } = useToggle(showStimulus)

  const nameType = passage ? PASSAGE : CUSTOM_PASSAGE
  const contentText = customPassage || passage

  const onToggleStatus = () => {
    sessionStorage.set(showStimulusKey, !status)
    toggleStatus()
  }

  if (!contentText) return null

  return (
    <Accordion
      expanded={status}
      disableGutters
      elevation={0}
      sx={{ bgcolor: 'transparent', '::before': { bgcolor: 'transparent' } }}
      aria-label="Passage accordion"
      aria-expanded={status}
    >
      <Box display="flex" justifyContent="space-between">
        <h4 data-testid={`subtitle-${name}`}>{subTitles[contentType][name]}:</h4>
        <PassageArrow show={status} onClick={onToggleStatus} />
      </Box>
      <AccordionDetails>
        {!canEditPassage ? (
          <Box
            sx={{ resize: 'vertical', overflow: 'auto', whiteSpace: 'pre-line' }}
            aria-label={name}
          >
            {contentText}
          </Box>
        ) : (
          <Box sx={{ '& .DraftEditor-root': { resize: 'vertical', overflow: 'auto' } }}>
            <EditableText
              text={contentText}
              removeNewlines={false}
              name={nameType}
              onChange={(_, text) => {
                // @ts-ignore
                dispatch(actions.setContent({ [nameType]: text }))
              }}
              ariaLabel={`editable ${name}`}
              hasContentLimit={canEditPassage}
            />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default memo(Passage)
