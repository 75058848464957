import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography, Container, Box, StyleProps } from '@mui/material'

import logo from 'src/assets/images/logo-ft-generate@2x.png'
import { AUTHOR_ROUTE } from '@core/constants/routes'

const styles: StyleProps = {
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'calc(100vh - 56px)',
  },
  subtitle: {
    pt: 2,
    pb: 8,
    fontWeight: 400,
  },
  logo: {
    mb: 12,
    width: 200,
  },
}

export default function NotFound() {
  return (
    <Container sx={styles.container}>
      <Box src={logo} component="img" alt="Finetune generate logo" sx={styles.logo} />

      <Typography variant="h1">404 - Page Not Found</Typography>
      <Typography variant="h3" sx={styles.subtitle}>
        The page you were looking for does not exist.
      </Typography>
      <Button variant="contained" color="secondary" to={AUTHOR_ROUTE} component={Link}>
        Back to home
      </Button>
    </Container>
  )
}
