import React from 'react'
import Box from '@mui/material/Box'
import { SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Reference } from '../references-types'

const styles: Record<string, SxProps> = {
  citationLink: {
    textDecoration: 'none',
    color: 'text.slateBlue',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}

interface Props {
  items: Reference[]
  onSelect: (value: string) => void
}

const Citations = ({ items, onSelect }: Props) => {
  return (
    <div>
      <Typography variant="h5">
        {items?.length ? 'Suggested for you' : 'Citations will be displayed here'}
      </Typography>
      <Typography variant="body2" sx={{ my: 1 }}>
        Ordered by relevance
      </Typography>
      <Box sx={{ px: 2, mb: 4 }}>
        {items?.map(({ id, citation }) => (
          <Box
            key={`citation-${id}`}
            href="#"
            component="a"
            sx={styles.citationLink}
            onClick={() => onSelect(id)}
            data-testid="citation-item"
          >
            <Typography variant="body2" sx={{ py: 1 }}>
              {citation}
            </Typography>
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default Citations
