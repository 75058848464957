import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

import { AUTHOR_ROUTE } from '@core/constants/routes';
import GenerateLogo from 'src/assets/images/logo-ft-generate-dark.svg';

const useStyles = makeStyles({
  logo: {
    textDecoration: 'none',
    '& svg': {
      height: 48,
      display: 'block',
    },
  },
});

/**
 * Renders finetune generate logo
 * */
const Logo = ({ className }) => {
  const classes = useStyles();
  return (
    <Link to={AUTHOR_ROUTE} className={clsx(classes.logo, className)}>
      <GenerateLogo data-testid="finetune-logo" />
    </Link>
  );
};

Logo.displayName = 'Logo';

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: null,
};

export default Logo;
