import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  draggable: {
    cursor: 'grabbing !important',
  },
}));

export const CustomDroppable = ({ children, className }) => {
  return (
    <Droppable droppableId="droppable">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={clsx({
            [className]: snapshot.isDraggingOver,
          })}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

CustomDroppable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

CustomDroppable.defaultProps = {
  children: null,
  className: null,
};

export const CustomDraggable = ({ children, id, index, disabled, className }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={`id-${id}`} index={index} isDragDisabled={disabled}>
      {(dragProvided, snapshot) => (
        <div
          ref={dragProvided.innerRef}
          {...dragProvided.draggableProps}
          {...dragProvided.dragHandleProps}
          className={clsx({
            [classes.draggable]: !disabled,
            [className]: snapshot.isDragging,
          })}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

CustomDraggable.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomDraggable.defaultProps = {
  children: null,
  id: null,
  className: null,
  disabled: false,
};

export const CustomDragContext = ({ items, blocksStatus, onDragEndCallback, children }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);
    const newBlocksStatus = reorder(blocksStatus, result.source.index, result.destination.index);
    onDragEndCallback(newItems, newBlocksStatus);
  };
  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
};

CustomDragContext.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array,
  blocksStatus: PropTypes.array,
  onDragEndCallback: PropTypes.func.isRequired,
};

CustomDragContext.defaultProps = {
  children: null,
  items: [],
  blocksStatus: [],
};
