import { gql } from '@apollo/client'

export const GET_USERS = gql`
  subscription getUsers($userId: String!) {
    users: user(where: { id: { _neq: $userId } }, order_by: { email: asc }) {
      id
      email
      role
      firstName: first_name
      lastName: last_name
      lastAccess: last_access
      createdAt: created_at
    }
  }
`

export const GET_ALL_PROJECTS = gql`
  subscription getAllProjects {
    project(where: { can_deliver: { _eq: true } }, order_by: { created_at: desc }) {
      id
      name
      owner {
        id
        email
      }
      access {
        read
        user {
          id
          email
        }
      }
      ownerId: owner_id
      items(where: { is_archived: { _neq: true } }) {
        aiModel: ai_model {
          id
          name
        }
      }
    }
  }
`

export const CREATE_USERS = gql`
  mutation createUsers($users: [CreateUserInput!]!) {
    create_users(users: $users) {
      responses
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUsers($user: UpdateUserInput!, $create_password_link: Boolean) {
    update_user_info(user: $user, create_password_link: $create_password_link) {
      response
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUsers($users: [String!]!) {
    update_user(where: { id: { _in: $users } }, _set: { customer_id: null }) {
      affected_rows
    }
  }
`

export const ASSIGN_ROLE = gql`
  mutation assignRole($users: [String!]!, $role: String!) {
    update_user(where: { id: { _in: $users } }, _set: { role: $role }) {
      affected_rows
    }
  }
`

export const GET_MODELS = gql`
  subscription getModels {
    models: ai_model(where: { is_archived: { _eq: false } }) {
      id
      name
      internalName: internal_model_name
      permissions {
        id
        userId: user_id
        read
      }
    }
  }
`

export const INSERT_AI_MODEL_PERMISSIONS = gql`
  mutation updateModelPermissions(
    $items: [ai_model_permission_insert_input!]!
    $aiModelInternalName: String!
    $userIds: [String!]!
  ) {
    deleted: delete_ai_model_permission(
      where: { ai_model_internal_name: { _eq: $aiModelInternalName }, user_id: { _in: $userIds } }
    ) {
      affected_rows
    }

    result: insert_ai_model_permission(objects: $items) {
      returning {
        id
        userId: user_id
        read
      }
    }
  }
`
