import React, { useState, Children, useEffect, useMemo } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Accordion, AccordionSummary, Box, Button, Typography } from '@mui/material'
import ContentEditable from '@core/components/ContentEditable/ContentEditable'
import { useConfirmDialog } from '@core/components/dialogs/dialog-confirm'
import * as TIMEOUTS from '@core/constants/timeouts'
import { debounce } from 'lodash'

interface Rational {
  value: string
}
interface RationaleAccordionProps {
  rationales: Rational[] | undefined
  show: boolean
  onChange: (text: Rational[]) => void
  confirmDeleteRationale: ReturnType<typeof useConfirmDialog>
}

interface RationaleInputProps {
  text: string
  handleChange: (text: string, index: number) => void
  index: number
  handleDelete: (index: number) => void
}

interface ListRationaleProps {
  list: Rational[]
  onChange: (text: string, index: number) => void
  handleDelete: (index: number) => void
}

interface RationaleArrowProps {
  show: boolean
  onClick: () => void
  disabled: boolean
}

export const RationaleArrow = ({ show, onClick, disabled }: RationaleArrowProps) => {
  const content = show ? 'Hide Rationale' : 'Show Rationale'
  return (
    <AccordionSummary
      aria-label={content}
      expandIcon={show ? <ExpandLessIcon sx={{ mb: 0.5 }} /> : <ExpandMoreIcon sx={{ mb: 0.5 }} />}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography variant="subtitle2" gutterBottom>
        {content}
      </Typography>
    </AccordionSummary>
  )
}

const RationaleInput = ({ index, text, handleChange, handleDelete }: RationaleInputProps) => {
  const handleClean = () => {
    handleDelete(index)
  }
  return (
    <Box mx={2} mt={index > 0 ? 1 : 0} data-testid={`rationale-${index}`}>
      <Typography variant="subtitle2" gutterBottom>
        Rationale
      </Typography>
      <ContentEditable
        text={text}
        name="rationale"
        ariaLabel="rationale input"
        onChange={(textContent) => handleChange(textContent, index)}
        placeholder="Type or paste your text here"
        clearButton
        handleClear={handleClean}
      />
    </Box>
  )
}

const ListRationale = ({ list, onChange, handleDelete }: ListRationaleProps) => (
  <Box>
    {Children.toArray(
      list.map(({ value }, index) => (
        <RationaleInput
          key={index}
          index={index}
          handleDelete={handleDelete}
          text={value}
          handleChange={onChange}
        />
      ))
    )}
  </Box>
)

const emptyRationale = [{ value: '' }]

const RationaleAccordion = ({
  rationales,
  show,
  onChange,
  confirmDeleteRationale,
}: RationaleAccordionProps) => {
  const [clientRationale, setClientRationale] = useState<Rational[]>(emptyRationale)

  useEffect(() => {
    if (show) {
      setClientRationale(rationales?.length ? rationales : emptyRationale)
    }
  }, [show, JSON.stringify(rationales)])

  const onChangeDebounce = useMemo(
    () =>
      debounce((newRationales: Rational[]) => {
        setClientRationale(newRationales)
        onChange(newRationales)
      }, TIMEOUTS.AUTO_SAVE_TIMEOUT),
    [onChange]
  )

  const onTextChange = (text, index) => {
    const newRationales = Array(...clientRationale)
    newRationales[index] = { value: text }
    onChangeDebounce(newRationales)
  }

  const addNew = () => {
    const newRationale = [...clientRationale, { value: '' }]
    setClientRationale(newRationale)
  }

  const onDelete = (index) => {
    const newRationales = Array(...clientRationale)
    newRationales.splice(index, 1)
    setClientRationale(newRationales)
    onChange(newRationales)
  }

  const handleDelete = (index) => {
    confirmDeleteRationale.open(async () => onDelete(index), { hideKey: `rationale-delete` })
  }
  if (!show) return null
  return (
    <Accordion elevation={0} expanded={show} sx={{ backgroundColor: 'transparent' }}>
      <ListRationale list={clientRationale} onChange={onTextChange} handleDelete={handleDelete} />
      <Box display="flex" justifyContent="end">
        <Button variant="text" color="secondary" onClick={addNew}>
          Add Another
        </Button>
      </Box>
    </Accordion>
  )
}

export default RationaleAccordion
