import React from 'react'
import { Box } from '@mui/material'

import ConfirmDialog, {
  useConfirmDialog,
  FormComponent,
} from '@core/components/dialogs/dialog-confirm'
import { useProjects } from '@containers/projects/projects-hooks'
import { useAppSelector } from '@core/store'
import FTGDropdown from '@core/components/FTGDropdown'

interface AskFormValues {
  selectedProject: { name: string; id: string; projectName: string } | null
}

type ConfirmDialog = ReturnType<typeof useConfirmDialog<AskFormValues>>
type Projects = ReturnType<typeof useProjects>
type Open = (
  action: (projectId: string | void, projectName: string | void) => void,
  lastProjectId?: string | null,
  callFailed?: boolean | null
) => void

interface ConfirmSave extends Omit<ConfirmDialog, 'open'> {
  open: Open
}

function Content({ values, onChange, formProps }: FormComponent<AskFormValues, Projects>) {
  const user = useAppSelector((state) => state.main.user)
  const isAdmin = user?.role === 'Admin'

  if (!formProps.items) return null

  const handleChange = (project) => {
    onChange({ target: { value: project, name: 'selectedProject' } })
  }

  const options = [
    {
      name: 'Create a folder',
      action: () => formProps.open(handleChange),
      tooltip: 'test',
    },
    ...formProps.items.map((project) => {
      const [access] = isAdmin ? [{ write: true }] : project.access
      const isOwner = user?.id === project.owner?.id
      const canWrite = access ? access?.write : isOwner

      let name = <Box component="span">{project.name}</Box>

      if (!canWrite) {
        name = (
          <Box>
            {name}
            <br />
            <Box typography="caption">You do not have write access to this folder</Box>
          </Box>
        )
      }

      return {
        ...project,
        projectName: project.name,
        disabled: !canWrite,
        name,
        tooltip: project.name,
        tooltipMaxWidth: 200,
      }
    }),
  ]

  return (
    <Box>
      <FTGDropdown
        options={options}
        setValue={handleChange}
        value={values.selectedProject?.id}
        buttonLabel={values.selectedProject?.name}
        placeholder="Select or create a folder"
        sx={{ bgcolor: 'background.button', my: 2 }}
        menuSx={{ minWidth: 300 }}
        optionsSx={{ maxWidth: 400 }}
        testid="save-item-menu"
      />

      <Box>Please ensure that you are saving this item to the desired folder.</Box>
    </Box>
  )
}

export function useConfirmSave(projects: Projects): ConfirmSave {
  const confirmSave = useConfirmDialog<AskFormValues>()

  const open: Open = async (action, lastProjectId = projects.lastProjectId, callFailed = false) => {
    // eslint-disable-next-line no-console
    if (!projects.items) return console.warn('missing projects')

    const initialValues = {
      selectedProject: lastProjectId ? projects.items.find((i) => i.id === lastProjectId) : null,
      callFailed,
    }

    confirmSave.open(
      async (values) => {
        const id = values.selectedProject?.id
        if (!id) {
          if (callFailed) action()
          return
        }
        projects.saveLastId(id)
        return action(id, values.selectedProject?.projectName)
      },
      { initialValues }
    )
  }

  return { ...confirmSave, open }
}

interface Props {
  state: ConfirmSave
  formProps?: Record<string, any>
  title?: string
}

export default function DialogAskProject({
  state,
  formProps,
  title = 'Your item will be saved to the folder:',
}: Props) {
  return (
    <ConfirmDialog
      state={state}
      id="save-item"
      title={title}
      validate={(values) => (values.selectedProject ? null : 'Please select a folder')}
      RenderContent={Content}
      formProps={formProps}
    />
  )
}
