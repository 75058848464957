import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@core/store'
import { Box, SxProps, Typography } from '@mui/material'

import { HOT_TEXT, subTitles } from '@author/constants/questionsTypes'
import { checkpoint } from '@core/tracking'

import ContentEditable from '@core/components/ContentEditable/ContentEditable'
import RegenerateDialog, { useConfirmRegenerate } from '@core/components/dialogs/dialog-regenerate'

import * as authorSelectors from '@pages/author/author-selectors'
import * as thunkActions from '@pages/author/author-thunk'
import { actions } from '@pages/author/author-slice'
import RegenerateButton from './RegenerateButton'

import VoteWithData from './Vote/vote'

import { QUESTION } from './constants'

const styles: Record<string, SxProps> = {
  subTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    minHeight: 40,
    '& h4': {
      fontWeight: 600,
      fontSize: 16,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}

const QuestionForm = () => {
  const name = QUESTION
  const dispatch = useAppDispatch()
  const contentType = useAppSelector((state) => state.author.item?.contentType)
  const { itemId: selectedItemId } = useParams<{ itemId: string }>()
  const generatedItemId = useAppSelector((state) => state.author.generatedItemId)
  const userId = useAppSelector((state) => state.main.user?.id)
  const itemId = selectedItemId || generatedItemId
  const isRegenerating = useAppSelector((state) => state.author.loading.regenerate)
  const loading = useAppSelector((state) => state.author.loading.generate)
  const isContentEditOn = useAppSelector((state) => state.author.isContentEditOn)
  const votes = useAppSelector((state) => state.author.item?.votes)
  const isRegenerateOptionsDisabled = isRegenerating || loading || isContentEditOn
  const canRegenerateText = useAppSelector(authorSelectors.canRegenerateText)
  const contentQuestion = useAppSelector((state) => state.author.content?.question) as string
  const confirmRegenerate = useConfirmRegenerate()

  const handleRegenerateQuestion = async () => {
    checkpoint('Regenerate')
    // @ts-ignore
    const userConfirmed = await confirmRegenerate.open(null, {
      hideKey: 'regenerate-question',
    })
    if (userConfirmed) dispatch(thunkActions.regenerateItem({ type: QUESTION }))
  }

  if (!contentType) return null
  return (
    <Box sx={styles.contentContainer} key={name}>
      <RegenerateDialog state={confirmRegenerate} />

      <Box sx={styles.subTitleContainer}>
        <Typography variant="h4" data-testid={`subtitle-${name}`}>
          {subTitles[contentType][name]}:
          {canRegenerateText && contentType !== HOT_TEXT && (
            <RegenerateButton
              name={subTitles[contentType][name]}
              disabled={isRegenerateOptionsDisabled}
              onRegenerate={handleRegenerateQuestion}
              type={name}
            />
          )}
        </Typography>
        {itemId && <VoteWithData itemId={itemId} votes={votes} userId={userId} />}
      </Box>
      {!isRegenerating && (
        <ContentEditable
          text={contentQuestion}
          name={name}
          onChange={(text) => {
            dispatch(actions.setContent({ question: text }))
          }}
          ariaLabel={`editable ${name}`}
        />
      )}
    </Box>
  )
}

export default memo(QuestionForm)
