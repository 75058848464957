import { ITEM_SET, CLOZE, MCQ } from '@author/constants/questionsTypes'
import { Item, ItemRoot, Answer, AnswerStatus, Content } from './author-cloze-types'

export const watchers = {
  [ITEM_SET]: ['stimulus', 'comments'],
  [CLOZE]: ['options'],
  [MCQ]: ['question', 'answers'],
}

const rootFields = ['qualityMetrics']
const contentFields = ['question', 'answers', 'cloze_ids', 'mcq_ids', 'stem', 'options', 'stimulus']

export function checkJSON(prev: any, curr: any) {
  return JSON.stringify(prev) !== JSON.stringify(curr)
}

export function filterExcluded(answers: Answer[]) {
  return answers.filter((answer) => ![AnswerStatus.excluded, null].includes(answer.status))
}

// this is for the final content and the last content
export function checkUnsavedChanges(item: Item | ItemRoot): string | false {
  const { lastContent, finalContent } = item

  if (!finalContent) return false

  const checkAnswers = (name: 'answers' | 'options') => {
    if (
      checkJSON(filterExcluded(lastContent[name] || []), filterExcluded(finalContent[name] || []))
    ) {
      return true
    }
  }

  if ('answers' in lastContent && 'answers' in finalContent) {
    if (checkAnswers('answers')) {
      return 'answers'
    }
  }

  if ('options' in lastContent && 'options' in finalContent) {
    if (checkAnswers('options')) {
      return 'options'
    }
  }

  for (const field of ['question', 'cloze_ids', 'mcq_ids', 'stem', 'stimulus']) {
    if (field in lastContent && field in finalContent) {
      if (checkJSON(lastContent[field], finalContent[field])) {
        return field
      }
    }
  }

  return false
}

// this is only for the last content, but comparing with the previous last content
export function checkContentChanged(prev: Item | ItemRoot, curr: Item | ItemRoot): boolean {
  for (const field of rootFields) {
    if (field in prev && field in curr) {
      if (checkJSON(prev[field], curr[field])) {
        return true
      }
    }
  }

  const lastContent = prev.lastContent as Content
  const currContent = curr.lastContent as Content

  for (const field of contentFields) {
    if (field in lastContent && field in currContent) {
      if (checkJSON(lastContent[field], currContent[field])) {
        return true
      }
    }
  }

  return false
}

export function checkLoading(item?: Item | ItemRoot): boolean {
  if (!item) return true
  return item.status === 'NOT_STARTED' || item.status === 'IN_PROGRESS'
}

export function getOptions(item: Item | ItemRoot): Answer[] {
  const field = item.type === CLOZE ? 'options' : item.type === MCQ ? 'answers' : null

  if (!field || !item.lastContent || !(field in item.lastContent)) return []

  const items: Answer[] = item.lastContent[field]

  return items
}
