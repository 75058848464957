import { TabValues } from '@author/components/ModelSidebar'
import { hasLessThanChars, validateInputCheck } from '@core/utils/text'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authorClear } from './author-actions'

import * as thunk from './author-thunk'
import { HotTextOptions, State, Option } from './author-types'

export const MIN_WORDS_LENGTH = 3

export function getInitialState(): State {
  return {
    customer: null,
    models: null,
    selectedModel: null,
    selectedProject: null,
    generatedItemId: null,
    itemInputs: {},
    paginatedItemsIds: [],
    generatedJobId: null,
    customPassageEnabled: false,
    customPassage: '',
    selectedFlavors: [],
    isContentEditOn: false,
    item: null,
    isStoryModal: false,
    content: null,
    loading: {
      savingContent: false,
      generate: false,
      deliver: false,
      discard: false,
      regenerate: false,
    },
    error: null,
    qualityMetricsAndStatus: {},
    selectedTab: TabValues.TAB_GENERATE,
    selectedCreativity: null,
  }
}
export { authorClear }

type FinalContentAnswer = {
  answer: string
  index: number
  name: string
}

type FinalContentAnswers = {
  answers: FinalContentAnswer[]
}

type FinalContentPart = {
  part: string
  index: number
  name: string
}

type FinalContentParts = {
  parts: FinalContentPart[]
}

type FinalContentOption = {
  option: string
  index: number
  name: string
}

type FinalContentOptions = {
  options: FinalContentOption[]
}

type Passage = { passage: string }
type CustomPassage = { custom_passage: string }
type Question = { question: string }

type FinalContentIndex = FinalContentAnswer | FinalContentPart | FinalContentOption
type FinalContent = Passage | CustomPassage | Question
type FinalContentValue = FinalContentAnswers | FinalContentParts | FinalContentOptions

interface Rationales {
  value: string
}
interface Answer {
  value: string
  status: string | null
  rationales?: Rationales[]
}

const minLengthError = {
  name: 'minLength',
  params: [3],
  errorMessage: 'Less than 3 characters',
}

export const slice = createSlice({
  name: 'author',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    stopLoading(state: State) {
      state.loading = {
        generate: false,
        deliver: false,
        discard: false,
        regenerate: false,
        savingContent: false,
      }
    },
    setAnswers(state: State, action: PayloadAction<Answer[]>) {
      if (state.content) state.content.answers = action.payload
    },
    setListInput(
      state: State,
      action: PayloadAction<{ name: string; value: string; line?: number; type: 'list' | 'item' }>
    ) {
      if (state.selectedModel?.inputSettings) {
        const { type, name, line = '', value } = action.payload
        const inputSetting = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!inputSetting) return

        const elements = ((itemInputs && itemInputs[name]) as string[]) || []

        if (type === 'list') {
          elements.push(value)

          const validation = validateInputCheck(inputSetting.checks, elements)
          const lessThanThree = hasLessThanChars(elements, MIN_WORDS_LENGTH)
          inputSetting.errorMessage = lessThanThree ? minLengthError : validation
        }
        if (type === 'item' && elements) {
          elements[line] = value
          const validation = validateInputCheck(inputSetting.checks, elements)
          const lessThanThree = hasLessThanChars(elements, MIN_WORDS_LENGTH)
          inputSetting.errorMessage = lessThanThree ? minLengthError : validation
        }

        state.itemInputs = { ...itemInputs, [name]: elements }
      }
    },
    deleteInput(state: State, action: PayloadAction<{ name: string; line: number }>) {
      if (state.selectedModel?.inputSettings) {
        const { name, line } = action.payload
        const inputSetting = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!inputSetting) return

        const elements = (itemInputs && (itemInputs[name] as string[])) || []
        elements.splice(line, 1)

        const validation = validateInputCheck(inputSetting.checks, elements)
        const lessThanThree = hasLessThanChars(elements, MIN_WORDS_LENGTH)
        inputSetting.errorMessage = lessThanThree ? minLengthError : validation

        state.itemInputs = { ...itemInputs, [name]: elements }
      }
    },
    setInputText(state: State, action: PayloadAction<{ name: string; value: string }>) {
      if (state.selectedModel?.inputSettings) {
        const { name, value } = action.payload
        const inputSetting = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!inputSetting) return

        state.itemInputs = { ...itemInputs, [name]: value }
        const validation = validateInputCheck(inputSetting.checks, value)

        if (!inputSetting.required && !value) inputSetting.errorMessage = null
        else inputSetting.errorMessage = validation
      }
    },
    setOptions(state: State, action: PayloadAction<{ name: string; value: string }>) {
      if (state.selectedModel?.inputSettings) {
        const { name, value } = action.payload
        const input = state.selectedModel.inputSettings.find((x) => x.name === name)
        const { itemInputs } = state

        if (!input || !input?.options) return
        const selectedOption = input.options.find((x) => x.text === value) as Option

        state.itemInputs = { ...itemInputs, [name]: selectedOption }
        const validation = validateInputCheck(input.checks, value)

        if (!input.required && !input.value) input.errorMessage = null
        else input.errorMessage = validation
      }
    },
    setEditorContent(
      state: State,
      action: PayloadAction<{ html: string; options: HotTextOptions[] | any[] }>
    ) {
      if (state.content) {
        state.content.passage = action.payload.html
        if (state.content?.answers) state.content.answers = action.payload.options
      }
    },
    setHotContent(state: State, action: PayloadAction<{ html: string; options: HotTextOptions }>) {
      if (state.content) {
        state.content.passage = action.payload.html

        if (!state.content.answers) {
          state.content.answers = [action.payload.options]
          return
        }
      }
      state.content?.answers?.push(action.payload.options)
    },
    deleteHotContent(state: State, action: PayloadAction<{ html: string; id: string }>) {
      if (state.content) {
        state.content.passage = action.payload.html

        if (state.content?.answers) {
          const answerIndex = state.content.answers.findIndex(
            (answer) => answer.id === action.payload.id
          )
          state.content.answers.splice(answerIndex, 1)
        }
      }
    },
    setContent(
      state: State,
      action: PayloadAction<FinalContent | FinalContentIndex | FinalContentValue>
    ) {
      if ('answer' in action.payload && state.content?.answers)
        state.content.answers[action.payload.index][action.payload.name] = action.payload.answer

      if ('answers' in action.payload && state.content?.answers)
        state.content.answers = action.payload.answers

      if ('part' in action.payload && state.content?.parts)
        state.content.parts[action.payload.index][action.payload.name] = action.payload.part

      if ('parts' in action.payload && state.content?.parts)
        state.content.parts = action.payload.parts

      if ('option' in action.payload && state.content?.options)
        state.content.options[action.payload.index][action.payload.name] = action.payload.option

      if ('options' in action.payload && state.content?.options)
        state.content.options = action.payload.options

      if ('question' in action.payload && state.content)
        state.content.question = action.payload.question

      if ('passage' in action.payload && state.content)
        state.content.passage = action.payload.passage

      if ('custom_passage' in action.payload && state.content)
        state.content.custom_passage = action.payload.custom_passage
    },
    setLoading(state: State, action: PayloadAction<Partial<State['loading']>>) {
      Object.assign(state.loading, action.payload)
    },
    reset(state) {
      Object.assign(state, getInitialState())
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunk.getModels.fulfilled, (state, action) => {
      state.models = action.payload
    })
    builder.addCase(thunk.fetchItem.pending, (state) => {
      state.error = null
    })
    builder.addCase(thunk.fetchItem.fulfilled, (state, action) => {
      state.item = action.payload.item
      state.itemInputs = action.payload.item.inputs
    })
    builder.addCase(thunk.fetchItem.rejected, (state, action) => {
      state.error = action.error
    })
    builder.addCase(thunk.deliverItem.pending, (state) => {
      state.loading.deliver = true
    })
    builder.addCase(thunk.deliverItem.fulfilled, (state) => {
      state.loading.deliver = false
    })
    builder.addCase(thunk.deliverItem.rejected, (state) => {
      state.loading.deliver = false
    })
    builder.addCase(thunk.discardItem.pending, (state) => {
      state.loading.discard = true
    })
    builder.addCase(thunk.discardItem.fulfilled, (state) => {
      state.loading.discard = false
    })
    builder.addCase(thunk.generateItem.pending, (state) => {
      state.loading.generate = true
    })
    builder.addCase(thunk.generateItem.rejected, (state) => {
      state.loading.generate = false
    })
    builder.addCase(thunk.regenerateItem.pending, (state) => {
      state.loading.regenerate = true
    })
    builder.addCase(thunk.createClone.fulfilled, (state) => {
      state.loading.regenerate = false
    })
    builder.addCase(thunk.regenerateItem.rejected, (state) => {
      state.loading.regenerate = false
    })
    builder.addCase(authorClear, (state: State) => {
      const { models } = state

      Object.assign(state, {
        ...getInitialState(),
        models,
      })
    })
  },
})

export default slice

export const { actions } = slice
