import React from 'react'
import { Box, Tooltip, TooltipProps } from '@mui/material'
import { makeStyles } from '@mui/styles'

type Props = TooltipProps & {
  withMargin?: boolean
  disabled?: boolean
  // if it should put a div around the children to use as ref
  wrap?: boolean
  maxWidth?: number
}
type styleProps = {
  withMargin?: boolean
  maxWidth?: number
}
const useStyles = makeStyles<any, styleProps>((theme) => ({
  tooltip: {
    margin: ({ withMargin }) => (withMargin ? `0 ${theme.spacing(1)}` : 0),
    maxWidth: ({ maxWidth }) => maxWidth || 300,
    fontSize: '0.85rem',
    overflowWrap: 'normal',
  },
}))

const FTGTooltip = ({ withMargin, children, disabled, wrap, maxWidth, ...props }: Props) => {
  const classes = useStyles({ withMargin, maxWidth })

  if (disabled) {
    return children
  }

  return (
    <Tooltip classes={{ tooltip: classes.tooltip }} {...props} role="tooltip">
      {wrap ? <Box width="100%">{children}</Box> : children}
    </Tooltip>
  )
}

export default FTGTooltip
