import parse from 'html-react-parser'
import DOMPurify from 'dompurify'
import checkFunctions from './check'

export const countWords = (text: string) => {
  return text.split(' ').filter((x) => x).length
}

export const truncate = (text: string, maxWords: number) => {
  return text.split(' ').splice(0, maxWords).join(' ')
}

/**
 * Check if string is empty by removing spaces and backslash
 */

export const isEmpty = (str = '') => !str.replace(/\s+/g, ' ').trim()

/**
 * Sanitize and parse html
 * @param {*} html
 * @param {*} opts
 * @returns
 */
export const html = (_html, opts = {}) => {
  return parse(DOMPurify.sanitize(_html), {
    ...opts,
  })
}

export const minWords = (text: string, min: number) => {
  if (countWords(text) < min) {
    return false
  }
  return true
}

export const maxWords = (text: string, max: number) => {
  if (countWords(text) > max) {
    return false
  }
  return true
}

export const validateInputCheck = (checks: any[], value: string | string[]) => {
  for (const check of checks) {
    const checkFunction = checkFunctions[check.name]
    if (checkFunction) {
      const validated = checkFunction(value, check.params)
      if (!validated) return check
    }
  }
  return null
}

export const hasLessThanChars = (values: string[], numberChars: number) => {
  return values.some((value) => value.replaceAll(' ', '').length < numberChars)
}
