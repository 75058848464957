import { gql } from '@apollo/client'

export const GET_BATCH_LIST = gql`
  subscription get_batch_list($ownerId: String!) {
    job(
      where: {
        owner_id: { _eq: $ownerId }
        items_required: { _gt: 1 }
        # @TODO: check if will be necessary to filter cloze items
        status: { _neq: DISCARDED }
      }
      order_by: { created_at: desc }
    ) {
      id
      createdAt: created_at
      itemsRequired: items_required
      aiModel: ai_model {
        id
        name
        minTemperature: public_mapper(path: "$.min_temperature")
        maxTemperature: public_mapper(path: "$.max_temperature")
      }
      items(limit: 1, order_by: { created_at: asc }) {
        selectedFlavors: selected_flavors
        customPassage: last_content_version(path: "$.content.custom_passage")
        temperatePercentage: last_content_version(path: "$.content.temperature")
      }
    }
  }
`

export const GET_BATCH_ITEMS = gql`
  subscription get_batch_items($jobId: uuid!) {
    job: job_by_pk(id: $jobId) {
      id
      itemsFailed: items_failed
      itemsPassed: items_passed
      items(order_by: [{ created_at: asc }, { id: desc }]) {
        id
        createdAt: created_at
        projectId: project_id
        step
        status
        lastQuestion: last_content_version(path: "$.content.question")
        finalQuestion: final_content_version(path: "$.content.question")
        lastAnswers: last_content_version(path: "$.content.answers")
        clonedFromId: cloned_from_id
        content_versions(order_by: { created_at: asc }, limit: 1) {
          created_at
        }
        qualityMetrics: last_content_version(path: "$.quality_metrics")
      }
    }
  }
`

export const DISCARD_BATCH_JOB = gql`
  mutation discard_batch_job($ids: [uuid!]!) {
    update_job(where: { id: { _in: $ids } }, _set: { status: DISCARDED }) {
      affected_rows
    }
  }
`

export const REGENERATE_BATCH_ITEMS = gql`
  mutation regenerate_batch_items(
    $modelId: uuid
    $jobId: uuid
    $itemsRequired: Int
    $temperatePercentage: Float
    $flavors: [String]
    $customPassage: String
  ) {
    generate_item(
      ai_model_id: $modelId
      items_required: $itemsRequired
      temperature_percentage: $temperatePercentage
      flavors: $flavors
      custom_passage: $customPassage
      autogenerate_blocks: false
    ) {
      items_ids
      job_id
    }
    update_job(where: { id: { _eq: $jobId } }, _set: { status: DISCARDED }) {
      affected_rows
    }
  }
`
