import React from 'react'
import { useAppSelector } from '@core/store'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import CommentsTab from '@containers/comments/comments-tab'
import { selectComments } from './feedback-selectors'

export default function FeedBackTab() {
  const { itemId: selectedItemId } = useParams<{ itemId: string }>()
  const generatedItemId = useAppSelector((state) => state.author.generatedItemId)
  const itemId = selectedItemId || generatedItemId
  const isItemGenerating = useAppSelector((state) => state.author.loading.generate)
  const isItemReGenerating = useAppSelector((state) => state.author.loading.regenerate)
  const comments = useAppSelector(selectComments)

  return (
    <Box mt={4}>
      <Box mx={4}>
        {!itemId || (!comments && <p>Please generate an item before submitting any comment.</p>)}
      </Box>
      {!isItemGenerating && !isItemReGenerating && itemId && (
        <CommentsTab itemId={itemId} comments={comments} />
      )}
    </Box>
  )
}
