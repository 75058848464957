import React from 'react'
import { Box } from '@mui/material'
import { useAppDispatch } from '@core/store'
import { actions } from '@pages/author/author-slice'
import { Inputs, InputType } from '@pages/author/author-types'

import { FlexibleInputText } from './flexible-input-text'
import { FlexibleInputTextArea } from './flexible-input-textarea'
import { FlexibleInputList } from './flexible-input-list'
import { FlexibleOptions } from './flexible-input-options'

interface FlexibleProps {
  inputs: Inputs[]
  isLoading?: boolean
}

export default function Flexible(props: FlexibleProps) {
  const { inputs, ...rest } = props
  const dispatch = useAppDispatch()

  if (inputs?.length === 0) return null

  return (
    <Box mt={2}>
      {inputs?.map((input, index) => {
        const inputProps = { ...rest, input, key: index }

        if (input.type === InputType.text)
          return (
            <FlexibleInputText
              onChange={(e) =>
                dispatch(actions.setInputText({ name: input.name, value: e.target.value }))
              }
              {...inputProps}
            />
          )
        if (input.type === InputType.textarea) {
          return (
            <FlexibleInputTextArea
              onChange={(e) =>
                dispatch(actions.setInputText({ name: input.name, value: e.target.value }))
              }
              onClear={() => dispatch(actions.setInputText({ name: input.name, value: '' }))}
              {...inputProps}
            />
          )
        }
        if (input.type === InputType.list) {
          return (
            <FlexibleInputList
              handleClose={(i) => {
                dispatch(
                  actions.deleteInput({
                    name: input.name,
                    line: i,
                  })
                )
              }}
              handleEdit={(e, i) => {
                dispatch(
                  actions.setListInput({
                    name: input.name,
                    line: i,
                    value: e.target.value,
                    type: 'item',
                  })
                )
              }}
              onKeyDown={(e) => {
                dispatch(
                  actions.setListInput({
                    name: input.name,
                    value: e.target.value,
                    type: InputType.list,
                  })
                )
              }}
              {...inputProps}
            />
          )
        }
        if (input.type === InputType.options) {
          return (
            <FlexibleOptions
              onChange={(value) => {
                dispatch(actions.setOptions({ name: input.name, value }))
              }}
              {...inputProps}
            />
          )
        }
        return null
      })}
    </Box>
  )
}
