import { LIKERT, MCQ, MPFRQ } from './questionsTypes';

export const SELECTED = 'SELECTED';
export const EXCLUDED = 'EXCLUDED';
export const KEY = 'KEY';
export const DISTRACTOR = 'DISTRACTOR';
export const SUGGEST_CORRECT = 'SUGGEST_CORRECT';
export const SUGGEST_DISTRACTOR = 'SUGGEST_DISTRACTOR';

export const MCQ_STATUSES = {
  [KEY]: 'CORRECT',
  DISTRACTOR,
  EXCLUDED,
  SUGGEST_CORRECT,
  SUGGEST_DISTRACTOR,
};

export const MPFRQ_STATUSES = {
  SELECTED,
  EXCLUDED,
};

export const optionsStatus = {
  [MCQ]: MCQ_STATUSES,
  [MPFRQ]: MPFRQ_STATUSES,
  [LIKERT]: MPFRQ_STATUSES,
};
