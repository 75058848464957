export const DEBOUNCE_TIMEOUT = 500
export const COMMENT_SAVE_DEBOUNCE_TIMEOUT = 500
export const STORY_DEBOUNCE_TIMEOUT = 1000
export const AUTO_SAVE_TIMEOUT = 1000
export const SAVE_ITEM_DEBOUNCE_TIMEOUT = 1500

export const TIMEOUTS = {
  COMMENT_SAVE_DEBOUNCE_TIMEOUT: 500,
}

export default { ...TIMEOUTS, AUTO_SAVE_TIMEOUT, LAST_ACCESS: 58 * 1000 }
