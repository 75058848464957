import React from 'react'
import { makeStyles } from '@mui/styles'
import ProjectTag from '@deliver/components/Shared/Tag'
import MultilineTag from '@deliver/components/Shared/MultilineTag'
import CircularProgress from '@mui/material/CircularProgress'

import { useAppSelector } from '@core/store'
import * as selectors from './batches-selectors'

const useStyles = makeStyles((theme) => ({
  aImodelInfo: {
    // @ts-ignore
    background: theme.palette.background.grey,
    '& dl': {
      margin: 0,
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& dd': {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '& dt': {
        margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(2)}`,
      },
      '& .MuiChip-label': {
        padding: 0,
      },
    },
  },
  names: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  loader: {
    marginLeft: theme.spacing(1),
  },
  done: {
    backgroundColor: `rgba(0, 0, 0, 0.12)`,
    color: 'rgba(0, 0, 0, 0.26)',
    fontWeight: 500,
  },
}))

const DONE = 'DONE'
const RUNNING = 'Running'

export default function AIModelInfo() {
  const classes = useStyles()
  const selected = useAppSelector(selectors.selectCurrentBatch)

  if (!selected) return null

  const { flavors, isLoading, itemsDone, itemsRequired, itemsPassed = 0 } = selected
  const label = isLoading ? RUNNING : DONE

  return (
    <div className={classes.aImodelInfo}>
      <div className={classes.names}>
        {flavors.map((name) => (
          <MultilineTag key={`ai-model-name-${name}`} data-testid="ai-model-name" label={name} />
        ))}
      </div>
      <dl data-testid="generated-items">
        <dt>Generated</dt>
        <dd>
          <ProjectTag
            key="ai-model-generated"
            size="small"
            bgcolor="batch"
            label={`${itemsDone.length}/${itemsRequired}`}
          />
        </dd>
      </dl>
      <dl data-testid="saved-items">
        <dt>Saved</dt>
        <dd>
          <ProjectTag
            key="ai-model-delivered"
            size="small"
            bgcolor="batch"
            label={`${itemsPassed}/${itemsRequired}`}
          />
        </dd>
      </dl>
      {label === 'Running' ? (
        <CircularProgress color="secondary" size={20} className={classes.loader} />
      ) : null}
    </div>
  )
}
