import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@core/store'
import { CLOZE } from '@author/constants/questionsTypes'
import { checkLoading, checkUnsavedChanges, getOptions } from './author-cloze-utils'

export const selectRootContent = (state: RootState) => state.authorCloze.items?.root.lastContent

export const selectItemPartial = createSelector(
  (state: RootState) => state.authorCloze.items?.root.project,
  (state: RootState) => state.authorCloze.items?.root.job,
  (project, job) => ({ project, job })
)

export const selectCounters = createSelector(selectRootContent, (rootContent) => {
  return [rootContent?.mcq_ids?.length || 0, rootContent?.cloze_ids?.length || 0]
})

export const selectStats = createSelector(
  (state: RootState) => state.authorCloze.items,
  (items) => {
    const result = { disableSaveItem: false, unsavedChanges: false, reason: '' }

    if (!items || !items.root.lastContent) {
      result.reason = 'Still loading'
      result.disableSaveItem = true
      return result
    }

    // disable until qualityMetrics is created
    if (items.root.qualityMetrics?.length === 0) {
      result.reason = 'No quality metrics'
      result.disableSaveItem = true
      return result
    }

    const { cloze_ids: clozeIds, mcq_ids: mcqIds } = items.root.lastContent

    if (!clozeIds || !mcqIds) {
      result.reason = 'Probably still loading'
      result.disableSaveItem = true
      return result
    }

    const ids = ['root', ...clozeIds, ...mcqIds]

    for (const [key, item] of Object.entries(items)) {
      if (!ids.includes(key)) continue

      const isLoading = checkLoading(item)

      if (isLoading) {
        result.reason = `Item ${item.index} is loading`
        result.disableSaveItem = true
      }

      const options = getOptions(item)
      if (!options.length && item.type === CLOZE) {
        result.reason = `Item ${item.index} has no generated answers`
        result.disableSaveItem = true
      }

      const changed = checkUnsavedChanges(item)

      if (changed) {
        result.unsavedChanges = true
      }
    }

    if (!result.unsavedChanges && items.root.project) {
      result.reason = 'No changes to update'
      result.disableSaveItem = true
    }

    return result
  }
)

export const selectComments = createSelector(
  (state: RootState) => state.authorCloze.items?.root?.comments,
  (state: RootState) => state.comments.comments,
  (itemComments, comments) => {
    if (comments.length > 0) return comments
    return itemComments
  }
)
