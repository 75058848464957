import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector, RootState } from '@core/store'

export const claims = 'https://hasura.io/jwt/claims'

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never

interface Access {
  write: boolean
  download?: boolean
}

interface PartialProject {
  access: Access[]
}

interface PartialItem {
  job?: {
    ownerId: string
  }
  project?: PartialProject | null
}

export function selectUserRole(state: RootState) {
  const { user } = state.main
  if (!user) throw new Error('Missing user on redux main state.')

  const isAdmin = user.role === 'Admin'

  return { user, role: isAdmin ? 'customer_admin' : 'AUTHOR', isAdmin }
}

export function useUserRole() {
  const data = useAppSelector(selectUserRole)

  return useMemo(
    () => ({
      ...data,
      getAccess({ job, project }: PartialItem): Access {
        if (data.isAdmin) return { write: true, download: true }
        // if the user can select the project, but the access is not configured,
        // this means that he is the owner
        const isOwner = job?.ownerId === data.user.id || project?.access.length === 0
        const access = project?.access[0]

        if (access) return access

        return isOwner ? { write: true, download: true } : { write: false, download: false }
      },
    }),
    [data]
  )
}

export function useQuery() {
  const location = useLocation()
  return {
    query: new URLSearchParams(location.search),
    location,
  }
}

/**
 * Check if it should call the configure_user mutation to sync the user on Auth0
 */
export function checkUserConfigure(user?: object, role?: 'User' | 'Admin'): [boolean, string] {
  const token = user && user[claims]

  // still loading/building state
  if (!token) {
    return [false, 'loading token']
  }

  // If the role is public, it means that is the first time the user opens Generate
  // Could happens when he logins from google, but has a email/password configured
  if (token['x-hasura-default-role'] === 'public') {
    return [true, 'still public']
  }

  if (!role) {
    return [false, 'loading user role']
  }

  const allowedRoles = token['x-hasura-allowed-roles']
  const hasCustomerAdminRole = allowedRoles.includes('customer_admin')

  if (role === 'User' && hasCustomerAdminRole) {
    return [true, 'user with customer_admin']
  }

  if (role === 'Admin' && !hasCustomerAdminRole) {
    return [true, 'admin without customer_admin']
  }

  return [false, 'properly configured']
}
