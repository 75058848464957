import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import FTGTooltip from '@core/components/FTGTooltip';

import { withStyles, makeStyles } from '@mui/styles';

const DiscreteSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.text.blue,
    height: 4,
    '&.Mui-disabled': {
      color: theme.palette.other.sliderDisabled,
    },
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: `2px solid ${theme.palette.background.notification}`,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&.Mui-disabled': {
      height: 16,
      width: 16,
      backgroundColor: theme.palette.other.sliderDisabled,
      border: `2px solid ${theme.palette.other.sliderDisabled}`,
    },
  },
  active: {},
  mark: { display: 'none' },
  markLabel: { fontWeight: 500, color: theme.palette.text.slateBlue },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
    backgroundColor: theme.palette.other.ghostBlue,
    opacity: 1,
  },
  disabled: {
    '& .MuiSlider-rail': {
      backgroundColor: theme.palette.other.sliderDisabled,
    },
    '& .MuiSlider-markLabel': {
      opacity: 0.5,
    },
  },
}))(Slider);

const useStyles = makeStyles(() => ({
  disabled: {
    '& h5': {
      opacity: 0.5,
    },
  },
}));

/**
 * Renders FTG Slider component
 * */

const FTGSlider = ({ title, className, disabled, onChange }) => {
  const classes = useStyles();
  const lowValue = 0;
  const mediumValue = 50;
  const highValue = 100;
  const marks = [
    {
      value: lowValue,
      label: 'Low',
    },
    {
      value: mediumValue,
      label: 'Medium',
    },
    {
      value: highValue,
      label: 'High',
    },
  ];

  return (
    <div
      className={clsx(className, {
        [classes.disabled]: disabled,
      })}
      aria-label="ftg-slider"
    >
      {title && (
        <Typography variant="h5" gutterBottom id="slider-label">
          {title}
        </Typography>
      )}
      <FTGTooltip
        data-testid="ftg-slider-tooltip"
        title={disabled ? 'The model you have selected does not support creativity levels' : ''}
        placement="left"
        leaveDelay={200}
      >
        <div>
          <DiscreteSlider
            defaultValue={mediumValue}
            aria-labelledby="slider-label"
            step={null}
            valueLabelDisplay="off"
            marks={marks}
            color="secondary"
            disabled={disabled}
            min={lowValue}
            max={highValue}
            onChange={onChange}
          />
        </div>
      </FTGTooltip>
    </div>
  );
};

FTGSlider.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FTGSlider.defaultProps = {
  title: '',
  className: '',
  disabled: false,
};

export default FTGSlider;
