import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@core/store'

import { actions } from './projects-slice'
import { OnCreate } from './projects-types'

export function useProjects() {
  const dispatch = useAppDispatch()
  const { items, lastProjectId } = useAppSelector((state) => state.projects)

  return useMemo(
    () => ({
      open(onCreate?: OnCreate) {
        dispatch(actions.set({ onCreate, open: true }))
      },
      saveLastId(id: string) {
        dispatch(actions.set({ lastProjectId: id }))
      },
      items,
      lastProjectId,
    }),
    [items, lastProjectId, dispatch]
  )
}
