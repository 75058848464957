import React from 'react'
import GridQualityMetrics from '@author/components/GridQualityMetrics'
import { Box } from '@mui/material'

export default function MetricsTab({ qualityMetricsOptions }: any) {
  return (
    <Box m={4}>
      <GridQualityMetrics title="Item Metrics" options={qualityMetricsOptions} />
      <Box height={50} />
    </Box>
  )
}
