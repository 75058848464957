import React, { memo, useEffect } from 'react'
import { SxProps, Box } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'
import Dialog from '@mui/material/Dialog'
import Container from '@mui/material/Container'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'

const styles: Record<string, SxProps> = {
  closeContainer: {
    textAlign: 'right',
    pt: 1,
  },
  content: {
    margin: 0,
    flex: 1,
    p: 0,
  },
  scrollableContainer: {
    minHeight: '100vh',
  },
  dialog: {
    bgcolor: 'white',
  },
  nav: {
    height: 'min-content',
    display: 'flex',
    justifyContent: 'flex-end',
    '& .close-button': {
      display: 'flex',
      fontSize: '1rem',
      '& svg': {
        ml: 14,
      },
    },
  },
}

// eslint-disable-next-line prefer-arrow-callback
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props} timeout={800} in>
      {/* @ts-ignore */}
      <Fade ref={ref} {...props} />
    </Slide>
  )
})

interface ModalProps {
  open: boolean
  onClose: (e?: any) => void
  children: React.ReactNode
  breakpoint?: number
  closeOnEscape?: boolean
}

function Modal({ open, onClose, children, closeOnEscape }: ModalProps) {
  useEffect(() => {
    if (!closeOnEscape || !open) return

    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [open])

  return (
    <Dialog
      maxWidth="md"
      sx={styles.dialog}
      TransitionComponent={Transition}
      data-testid="modal"
      fullScreen
      disableEscapeKeyDown
      open={open}
    >
      <DialogContent sx={styles.content}>
        <Container data-testid="modal-container">
          <Box sx={styles.closeContainer}>
            <Button data-testid="close-button" onClick={onClose}>
              Close <CloseIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
          {children}
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default memo(Modal)
