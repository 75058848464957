import React, { useCallback, useEffect, useState } from 'react'
import { WordCount } from '@core/components'
import ContentEditable from '@core/components/ContentEditable/ContentEditable'

interface EditableTextProps {
  text: string
  name: string
  ariaLabel: string
  onChange: (name: string, value: any) => void
  hasContentLimit: boolean
  removeNewlines?: boolean
}

const EditableText = (props: EditableTextProps) => {
  const { text, name, onChange, hasContentLimit, ariaLabel, removeNewlines = true } = props
  const MAX_WORDS = hasContentLimit ? 250 : 0
  const [_text, setText] = useState(text)

  const handleChange = useCallback(
    (textContent) => {
      setText(textContent)
      return onChange(name, textContent)
    },
    [name, onChange]
  )

  useEffect(() => {
    if (text) setText(text)
  }, [text])

  return (
    <>
      <ContentEditable
        removeNewlines={removeNewlines}
        ariaLabel={ariaLabel}
        onChange={handleChange}
        text={_text}
        maxWords={MAX_WORDS}
      />
      {hasContentLimit && <WordCount text={_text} maxWords={MAX_WORDS} />}
    </>
  )
}
export default EditableText
