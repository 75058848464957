import React from 'react'
import { Box, SxProps } from '@mui/material'
import debounce from 'lodash/debounce'
import { convertToRaw, EditorState, DraftHandleValue, Editor, getDefaultKeyBinding } from 'draft-js'

import { useAppDispatch } from '@core/store'

import { actions, thunkActions } from '../author-cloze-slice'
import { getEntityRangeByCommand } from './utils'
import { decorators, fromHTML, toHTML } from './cloze-html'
import ClozePopper from './cloze-popper'

export const plugin = {
  handleKeyCommand: (command: string, editorState: EditorState): DraftHandleValue => {
    if (command === 'backspace' || command === 'delete') {
      const entity = getEntityRangeByCommand(editorState, command)

      // If there is an entity at the current selection, block it.
      if (entity && entity.end - entity.start < 3) {
        return 'handled'
      }
    } else if (command === 'split-block') {
      const entity = getEntityRangeByCommand(editorState, 'range')
      if (entity) {
        return 'handled'
      }
    }
    return 'not-handled'
  },
  handleBeforeInput: (chars: string, editorState: EditorState): DraftHandleValue => {
    const selection = editorState.getSelection()
    if (selection.isCollapsed()) {
      const rangeEntity = getEntityRangeByCommand(editorState, 'range')
      const originalSelection = document.getSelection()
      if (rangeEntity !== null && originalSelection && originalSelection?.anchorOffset > 0) {
        const detail = {
          text: chars,
          rangeEntity,
        }
        dispatchEvent(new CustomEvent('insertStickyTextEntity', { detail }))
        return 'handled'
      }
    }
    return 'not-handled'
  },
  handlePastedText: (text: string, html: string, editorState: EditorState): DraftHandleValue => {
    const rangeEntity = getEntityRangeByCommand(editorState, 'range')
    const originalSelection = document.getSelection()
    if (rangeEntity !== null && originalSelection && originalSelection?.anchorOffset > 0) {
      const detail = {
        text,
        rangeEntity,
      }
      dispatchEvent(new CustomEvent('insertStickyTextEntity', { detail }))
      return 'handled'
    }
    return 'not-handled'
  },
  keyBindingFn: (e) => {
    if (e.nativeEvent.shiftKey && e.keyCode === 32) {
      dispatchEvent(new CustomEvent('insertSpaceText'))
      return 'handled'
    }
    return getDefaultKeyBinding(e)
  },
}

const styles: Record<string, SxProps> = {
  root: {
    '& .public-DraftEditor-content': {
      background: '#f7faff',
      p: 2,
      color: 'primary.main',
      lineHeight: 2,
      borderRadius: 2,
      border: '3px solid #f7faff',
      transition: '.3s ease',
      '&:focus': {
        borderColor: 'secondary.main',
      },
    },
    '& textarea': {
      width: '100%',
      height: 200,
    },
  },
}

type Props = {
  initialHTML: string
  clozeThunkActions?: typeof thunkActions | any
}

function CustomEditor({ initialHTML, clozeThunkActions = thunkActions }: Props) {
  const dispatch = useAppDispatch()
  const [editor, setEditor] = React.useState(
    EditorState.createWithContent(fromHTML(initialHTML), decorators)
  )

  const handleSave = (newEditor: EditorState) => {
    const raw = convertToRaw(newEditor.getCurrentContent())
    const entities =
      raw?.blocks.flatMap((block) =>
        block.entityRanges.map((item) => ({
          ...raw.entityMap[item.key].data,
          text: block.text.slice(item.offset, item.offset + item.length),
        }))
      ) || []

    dispatch(actions.updateFromEditor({ entities, html: toHTML(raw) }))
  }

  const debouncedSave = React.useMemo(
    () =>
      debounce((newEditor) => {
        handleSave(newEditor)
      }, 300),
    []
  )

  const handleChange = (newEditor: EditorState) => {
    setEditor(newEditor)
    debouncedSave(newEditor)
  }

  return (
    <Box sx={styles.root} data-testid="cloze-editor">
      <Editor
        editorState={editor}
        onChange={handleChange}
        stripPastedStyles
        handleKeyCommand={plugin.handleKeyCommand}
        handleBeforeInput={plugin.handleBeforeInput}
        handlePastedText={plugin.handlePastedText}
        keyBindingFn={plugin.keyBindingFn}
        spellCheck
      />
      <ClozePopper
        getEditorState={() => editor}
        onChange={handleChange}
        clozeThunkActions={clozeThunkActions}
      />
    </Box>
  )
}

export default React.memo(CustomEditor)
