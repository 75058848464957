import { aiProgressSteps } from '@author/constants/progressSteps'

export * from '@core/utils/random'
export * from '@core/utils/date'
export * from './project'

/**
 * Checks if the step is in the AI steps
 */
export const isAIProgress = (step: string): boolean => Object.keys(aiProgressSteps).includes(step)
