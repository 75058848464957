import { OptionPrefixType } from '@core/utils/option-prefix'

export interface Check {
  name: string
  params: number[]
  errorMessage: string
}

export enum InputType {
  text = 'text',
  textarea = 'textarea',
  list = 'list',
  options = 'options',
}

export interface Option {
  value: string[]
  text: string
}
export interface Inputs {
  checks: Check[]
  name: string
  placeholder: string
  required: boolean
  title: string
  type: InputType
  elements?: any[]
  errorMessage: Check | null
  value: string | Option
  options?: Option[]
  group: string
}

export interface AiModel {
  id: string
  comments: object[]
  flavors: any
  public_mapper: Record<string, any>
  name: string
  passages: any
  qualityMetrics: any[]
  type: string
  references: string[] | null
  inputSettings: Inputs[] | null
  internalName: string
  optionPrefix?: OptionPrefixType
}

export interface ContentVersion {
  [key: string]: any
}

export interface Item {
  id: string
  isArchived: boolean
  flavors: string[]
  contentType: string
  job: {
    id: string
    itemsRequired: number
    aiModel: AiModel
    ownerId: string
  }
  project?: {
    id: string
    name: string
  }
  finalContentVersion?: ContentVersion
  contentFilter?: Record<string, any>
  originalContentVersion: ContentVersion
  lastContentVersion: ContentVersion
  votes: { vote: number }[]
  lastResponse: any[]
  inputs: Record<string, string | string[]>
}

export interface FinalContent {
  options?: any[]
  passage?: string
  answers?: any[]
  parts?: any[]
  can_edit_passage?: boolean
  custom_passage?: string
  question: string
}

export interface Loading {
  deliver: boolean
  discard: boolean
  generate: boolean
  regenerate: boolean
  savingContent: boolean
}

export interface HotTextOptions {
  id: string
  value: string
  status: string
}
export interface State {
  customer: null
  models: AiModel[] | null
  selectedModel: AiModel | null
  selectedProject: null
  generatedItemId: null
  itemInputs: Record<string, string | string[] | Option> | null
  paginatedItemsIds: string[]
  generatedJobId: string | null
  content: FinalContent | null
  customPassageEnabled: false
  qualityMetricsAndStatus: any
  customPassage: string
  isStoryModal: boolean
  error: any
  loading: Loading
  selectedFlavors: []
  isContentEditOn: false
  selectedTab: number
  selectedCreativity: number | null
  item: Item | null
}
