import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import '@core/api/Raven'
import { useStore } from '@core/store'
import ProtectedRoute from '@containers/main/main-route-protected'

import { routeMap } from '@core/constants/routes'

import LoginPage from '@pages/auth/login-page'
import NotFound from '@pages/404'

import { AppTheme } from '@core/styles/theme'
import AuthorizedApolloProvider from '@core/api/apollo'
import ErrorBoundary from '@core/components/ErrorBoundary'
import { ContextProviders } from '@core/providers'

import Header from '@core/components/Header'
import SnackbarNotification from '@author/components/Notifications/SnackbarNotification'
import Auth0Provider from './auth0provider'

const AuthorClozePage = React.lazy(() => import('@pages/author-cloze/author-cloze-page'))
const UsersPage = React.lazy(() => import('@pages/users/users-page'))
const AuthorPage = React.lazy(() => import('@pages/author/author-page'))
const DeliverPage = React.lazy(() => import('@pages/deliver/deliver-page'))
const DeliverProjectDetail = React.lazy(() => import('@deliver/pages/ProjectDetail'))
const DraftPage = React.lazy(() =>
  import('@deliver/pages/ProjectDetail').then((module) => ({ default: module.DraftPage }))
)

function Routes() {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path={routeMap.LOGIN_ROUTE} component={LoginPage} />
        <ProtectedRoute
          exact
          path={[routeMap.AUTHOR_ROUTE, routeMap.AUTHOR_ITEM_ROUTE]}
          component={AuthorPage}
        />
        <ProtectedRoute exact path={routeMap.USERS_ROUTE} component={UsersPage} />
        <ProtectedRoute exact path={routeMap.AUTHOR_CLOZE_ROUTE} component={AuthorClozePage} />
        <ProtectedRoute exact path={routeMap.DELIVER_ROUTE} component={DeliverPage} />
        <ProtectedRoute exact path={routeMap.DELIVER_DRAFT} component={DraftPage} />
        <ProtectedRoute
          exact
          path={routeMap.DELIVER_PROJECT_ROUTE}
          component={DeliverProjectDetail}
        />
        <Route path="*" component={NotFound} />
      </Switch>

      <SnackbarNotification />
    </>
  )
}

function ProviderRedux(props: { children: React.ReactNode }) {
  const store = useStore()

  return <Provider store={store}>{props.children}</Provider>
}

export default function App() {
  return (
    <Router>
      <Auth0Provider>
        <AuthorizedApolloProvider>
          <ContextProviders>
            <ProviderRedux>
              <AppTheme>
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
              </AppTheme>
            </ProviderRedux>
          </ContextProviders>
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </Router>
  )
}
