import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { routeLeave } from '@containers/main/main-slice'

import * as thunks from './references-thunk'
import { State } from './references-types'

export function getInitialState(): State {
  return {
    items: [],
    loading: false,
    showHelperInfo: true,
    keywords: [],
  }
}

export const slice = createSlice({
  name: 'references',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    clear(state: State) {
      Object.assign(state, { ...getInitialState(), showHelperInfo: state.showHelperInfo })
    },
  },
  extraReducers(builder) {
    builder
      .addCase(thunks.getReferences.pending, (state) => {
        state.loading = true
      })
      .addCase(thunks.getReferences.fulfilled, (state, action) => {
        state.loading = false
        state.items = action.payload
      })
      .addCase(thunks.getReferences.rejected, (state) => {
        state.loading = false
      })

    // clear reference state when leave current item route
    builder.addCase(routeLeave, (state, action) => {
      if (action.payload.path === '/author/item/:itemId') {
        Object.assign(state, { ...getInitialState(), showHelperInfo: state.showHelperInfo })
      }
    })
  },
})

export default slice
export const { actions } = slice
