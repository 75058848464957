/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import FTGTooltip from '@core/components/FTGTooltip';

const useStyles = makeStyles((theme) => ({
  notification: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    background: theme.palette.background.white,
    padding: `${theme.spacing(2.5)} ${theme.spacing(7)}`,
    boxShadow:
      '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14)',
    '&.warning': {
      background: theme.palette.suggestAnswer.distractor,
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

/**
 * Renders Notification component
 * */

const Notification = ({ children, onClose, type, className }) => {
  const classes = useStyles();

  return (
    <div id="notification" className={clsx(classes.notification, type, className)}>
      {children}
      {onClose && (
        <FTGTooltip title="Close" placement="left">
          <IconButton
            className={classes.closeButton}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </FTGTooltip>
      )}
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
};

Notification.defaultProps = {
  type: 'default',
  onClose: null,
  className: '',
};

export default Notification;
