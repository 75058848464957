import React from 'react'

import {
  Tab as MuiTab,
  TabProps as MuiTabProps,
  Tabs as MuiTabs,
  TabsProps as MuiTabsProps,
  Box,
  BoxProps,
  SxProps,
} from '@mui/material'

const styles: Record<string, SxProps> = {
  tabs: {
    '& .MuiTabs-indicator': {
      bgColor: 'other.downriver',
    },
    '& .MuiTabScrollButton-root': {
      width: 30,
    },
  },
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}

interface TabPanelProps extends BoxProps {
  children: React.ReactNode
  value: number
  index: number
}

export const TabPanel = ({ children, value, index, ...props }: TabPanelProps) => {
  if (value !== index) return null

  return (
    <Box role="tabpanel" sx={styles.tabPanel} {...props}>
      {children}
    </Box>
  )
}

export const Tab = (props: MuiTabProps) => <MuiTab {...props} />

export const Tabs = (props: MuiTabsProps) => {
  return <MuiTabs sx={styles.tabs} variant="scrollable" scrollButtons="auto" {...props} />
}
