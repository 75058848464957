import React from 'react'
import { Box, Typography } from '@mui/material'
import { TextField } from '@core/components/textfield/textfield'
import { Inputs } from '@pages/author/author-types'

interface FlexibleProps {
  input: Inputs
  isLoading?: boolean
  onChange: (e: any) => void
}

export const FlexibleInputText = (props: FlexibleProps) => {
  const { input, isLoading = false, onChange } = props
  const { title, value, errorMessage, placeholder, required } = input

  return (
    <Box mb={1}>
      <Typography variant="h5">{title}</Typography>
      <TextField
        value={value}
        disabled={isLoading}
        errorMessage={errorMessage?.errorMessage}
        inputProps={{
          'aria-label': title,
        }}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
      />
    </Box>
  )
}
