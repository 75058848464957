import React from 'react'
import PropTypes from 'prop-types'

import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client'
import fetch from 'cross-fetch'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { useAuth0 } from '@auth0/auth0-react'

import Raven from '@core/api/Raven'

interface Context {
  role?: string
}

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, logout } = useAuth0()

  const getToken = async (headers = {}, context: Context = {}) => {
    const token = await getAccessTokenSilently()
    const role = context.role || headers['x-hasura-role'] || 'AUTHOR'

    return {
      headers: {
        ...headers,
        'x-hasura-role': role,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...context,
    }
  }

  const authMiddleware = setContext(async (_, { headers, ...context }) => {
    return getToken(headers, context)
  })

  const httpLink = createHttpLink({
    uri: ({ operationName }) => `${FTG_API_URL}?op=${operationName}`,
    fetch,
    credentials: 'same-origin',
  })

  const makeWSRoleLink = (role: string) =>
    new GraphQLWsLink(
      createClient({
        url: FTG_WS_URL,
        connectionParams: () => getToken({ 'x-hasura-role': role }),
        shouldRetry: () => true,
        lazy: true,
      })
    )

  const composeWSRoleLinks = (head, ...tail) => {
    const [newHead, ...newTail] = [...tail].flat()

    return ApolloLink.split(
      (operation) => operation.getContext().role === head,
      makeWSRoleLink(head),
      newTail.length > 0 ? composeWSRoleLinks(newHead, newTail) : makeWSRoleLink(newHead)
    )
  }

  // as the headers are sent only on connection begins, is necessary to have one connection per role
  const wsLinks = composeWSRoleLinks('customer_admin', 'AUTHOR')

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLinks,
    httpLink
  )

  const client = React.useMemo(() => {
    return new ApolloClient({
      link: ApolloLink.from(
        [
          onError((err) => {
            const { operation, networkError, graphQLErrors } = err
            const { source } = operation.query.loc || {}

            const messages = [
              'Item not found or without access',
              'Without write access to the item',
            ]

            const errorMessage = graphQLErrors?.[0].message

            if (errorMessage && messages.includes(errorMessage)) {
              // eslint-disable-next-line no-alert
              window.alert('You lost access to this item. Please refresh your browser to continue.')
              window.location.reload()
            }

            if (source && Raven.setContext) {
              Raven.setContext('source', { source })
            }

            const { response } = operation.getContext()

            if (networkError?.message === 'Login required') {
              return
            }

            if (response?.status === 401) {
              Raven.configureScope((scope) => {
                scope.setUser({ id: '' })
              })

              logout({ logoutParams: { federated: true } })
            }

            if (networkError) {
              Raven.captureMessage(networkError.message, {
                level: 'error',
                contexts: {
                  networkError: networkError.message,
                  operation: {
                    name: operation.operationName,
                    variables: operation.variables,
                  },
                },
              })
            }
          }),
          authMiddleware,
          splitLink,
        ].filter(Boolean)
      ),
      cache: new InMemoryCache(),
    })
  }, [])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
}

export default AuthorizedApolloProvider
