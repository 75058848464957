import { PASSAGE_BUILDER, STORY } from '@author/constants/questionsTypes'
import type { Project, Item } from './deliver-types'

/**
 * Aggregate project/models by user
 */
export function buildProjectUsers(list: Project[], isAdmin: boolean, currentUserEmail: string) {
  if (!isAdmin) return { users: {}, projects: list }

  const users = {}
  const projects = {}

  for (const project of list) {
    const projectUsers = {}
    const models = {}

    let ignoreOwner = project.owner?.email === currentUserEmail
    ignoreOwner = ignoreOwner || project.owner?.role === 'Admin'

    for (const name of project.stats?.models || []) {
      models[name] = { name }
    }

    for (const { user, read } of project.access) {
      if (user && user.email === project.owner?.email) {
        ignoreOwner = true
      }

      if (read && user && user.email !== currentUserEmail && !projectUsers[user.email]) {
        projectUsers[user.email] = user
        if (!users[user.email]) {
          users[user.email] = { ...user, count: 0, models: {} }
        }
        // eslint-disable-next-line
        users[user.email].count++
        Object.assign(users[user.email].models, models)
      }
    }

    // ignore yourself as customer_admin
    if (!ignoreOwner && project.owner) {
      const user = project.owner
      projectUsers[user.email] = user
      if (!users[user.email]) {
        users[user.email] = { ...user, count: 0, models: {} }
      }
      // eslint-disable-next-line
      users[user.email].count++
      Object.assign(users[user.email].models, models)
    }

    projects[project.id] = { ...project, users: projectUsers, models }
  }

  return { projects: Object.values(projects), users }
}

export type ProjectItems = Project & { items: Item[] }

export function mountProjects(projects: ProjectItems[], draft: Item[] = []) {
  const allItems = [...projects.flatMap((project) => project.items), ...draft]
  const domainTags = {}
  const topics = new Set()
  const qualityMetricNames = new Set()

  let hasClones = false

  for (const item of allItems) {
    const { domain, qualityMetrics } = item.aiModel
    if (item.topic && [PASSAGE_BUILDER, STORY].includes(item.contentType)) {
      topics.add(item.topic)
    } else if (domain) {
      domainTags[domain] = { name: domain, id: domain }
    }

    qualityMetrics?.forEach(({ name }) => qualityMetricNames.add(name))

    hasClones = Boolean(hasClones || item.clonedFromId || item.clones.aggregate.count)
  }

  return {
    hasClones: Boolean(hasClones),
    domainTags: Object.values(domainTags),
    topics: Array.from(topics),
    qualityMetricNames: Array.from(qualityMetricNames),
  }
}
