import React, { useContext } from 'react';
import clsx from 'clsx';

import { Snackbar, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Notification from '@author/components/Shared/Notification';
import ToastNotification from '@author/components/Shared/Notification/Toast';
import { SnackbarContext } from '@core/providers';

const useStyles = makeStyles((theme) => ({
  anchorOriginTopCenter: {
    top: '88px',
    [theme.breakpoints.down('md')]: {
      top: '24px',
    },
  },
  snackbar: { left: 0, right: 0, bottom: 0, transform: 'none' },
}));

const SnackbarNotification = () => {
  const classes = useStyles();
  const { notification, clearNotification } = useContext(SnackbarContext);

  if (!notification) return null;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    clearNotification(false);
  };

  const { message, type, options } = notification;

  return (
    <Snackbar
      open={!!message}
      onClose={handleClose}
      autoHideDuration={6000}
      classes={{
        anchorOriginTopCenter: clsx({
          [classes.anchorOriginTopCenter]: options?.belowHeader,
        }),
        root: clsx({
          [classes.snackbar]: !options?.toast,
        }),
      }}
      data-testid="notification"
      anchorOrigin={options?.anchorOrigin}
    >
      <Box width="100%">
        {message &&
          (options?.toast ? (
            <ToastNotification onClose={handleClose} type={type}>
              {message}
            </ToastNotification>
          ) : (
            <Notification onClose={handleClose} type={type}>
              {message}
            </Notification>
          ))}
      </Box>
    </Snackbar>
  );
};

export default SnackbarNotification;
