import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { TextArea } from '@core/components/textfield/textarea'

import { Inputs } from '@pages/author/author-types'
import { countWords } from '@core/utils/text'

interface MaxKeywords {
  number: any
  value: string
}

interface FlexibleProps {
  input: Inputs
  isLoading?: boolean
  onChange: (e) => void
  onClear: (e) => void
}

const LimitCharacters = (props: MaxKeywords) => {
  const { number = 0, value = '' } = props
  return (
    <Box
      fontSize={14}
      letterSpacing="normal"
      fontWeight={500}
      color="text.grayishBlue"
      textAlign="left"
    >
      {countWords(value)} of {number} words used
    </Box>
  )
}

export const FlexibleInputTextArea = (props: FlexibleProps) => {
  const { input, isLoading = false, onChange, onClear } = props
  const { checks, title, value, errorMessage, placeholder, required } = input
  const maxWordsCount = checks.find((x) => x.name === 'maxWords')?.params

  return (
    <Box mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {title && <Typography variant="h5">{title}</Typography>}
        <Button
          onClick={onClear}
          sx={{
            width: 110,
            opacity: 0.8,
          }}
        >
          Clear Text
        </Button>
      </Box>
      <TextArea
        disabled={isLoading}
        required={required}
        value={value}
        errorMessage={errorMessage?.errorMessage}
        placeholder={placeholder}
        inputProps={{
          'aria-label': title,
        }}
        helperContent={<LimitCharacters value={value as string} number={maxWordsCount} />}
        onChange={onChange}
      />
    </Box>
  )
}
