/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  PaletteColor,
  Theme,
  SxProps,
} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import {
  background,
  common,
  tag,
  text,
  histogram,
  fgProgress,
  linkBlue,
  other,
  suggestAnswer,
} from './colors'

interface CustomPalette {
  tertiary: PaletteColor
  purple: PaletteColor
  other: typeof other
  text: typeof text
  background: typeof background
}

type CustomBackground = typeof background

declare module '@mui/material/styles' {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
  interface TypeBackground extends CustomBackground {}
}

declare module '@mui/styles/defaultTheme' {
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
  interface DefaultTheme extends Theme {
    palette: Palette
    paletteOptions: PaletteOptions
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
    purple: true
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true
    purple: true
  }
}

const defaultTheme = createTheme()

function customScroll(selector: string) {
  return {
    [`${selector}::-webkit-scrollbar`]: {
      width: 10,
      background: 'rgba(0, 0, 0, 0.1)',
      borderRadius: 4,
    },
    [`${selector}::-webkit-scrollbar-thumb`]: {
      width: 10,
      background: 'rgba(0, 0, 0, 0.4)',
      borderRadius: 4,
    },
  }
}

const tagColors = {}

for (const [name, value] of Object.entries(tag)) {
  tagColors[name] = defaultTheme.palette.augmentColor({
    name,
    color: {
      main: value.main,
      contrastText: value.contrastText,
    },
  })
}

export const theme = createTheme({
  palette: {
    primary: { main: text.slateBlue, contrastText: common.white },
    secondary: {
      main: fgProgress,
      contrastText: common.white,
    },
    purple: defaultTheme.palette.augmentColor({
      color: { main: background.purple },
      name: 'purple',
    }),
    tertiary: defaultTheme.palette.augmentColor({
      color: {
        main: '#f5f7f8',
        dark: '#ecedf0',
        contrastText: '#42526e',
      },
      name: 'tertiary',
    }),
    background: { default: background.grey, ...background },
    // @ts-ignore
    text: { primary: text.slateBlue, secondary: text.greyBlue, ...text, linkBlue },
    tag: tagColors,
    histogram,
    other,
    suggestAnswer,
  },
  typography: {
    h1: { fontSize: '2rem', fontWeight: 500 },
    h2: { fontSize: '1.75rem', fontWeight: 500 },
    h3: { fontSize: '1.5rem', fontWeight: 500 },
    h4: { fontSize: '1.25rem', fontWeight: 500 },
    h5: { fontSize: '1rem', fontWeight: 500 },
    h6: { fontSize: '1rem', fontWeight: 500 },
    button: {
      textTransform: 'initial',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '1rem',
          lineHeight: 1.625,
          fontWeight: 400,
          letterSpacing: '-0.13px',
          ...customScroll('&'),
          ...customScroll('*'),
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '3px',
        },
        contained: {
          '&.Mui-disabled': {
            backgroundColor: background.disabledButton,
          },
        },
        containedSecondary: {
          backgroundColor: linkBlue,
        },
        outlinedSecondary: {
          borderColor: linkBlue,
          color: linkBlue,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorSuccess: {
          background: '#e2ffee',
          color: '#006644',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: text.slateBlue,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0, 3, 3, 0),
        },
      },
    },
  },
})

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.theme = theme
}

declare module '@mui/material' {
  type StyleProps = Record<string, SxProps<typeof theme>>
}

export function AppTheme({
  children,
  cssReset = true,
}: {
  children: React.ReactNode
  cssReset?: boolean
}) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {cssReset && <CssBaseline />}
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default theme
