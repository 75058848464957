/* eslint-disable no-console */
import { useMemo, useEffect } from 'react'
import {
  configureStore,
  AsyncThunkPayloadCreator,
  AsyncThunk,
  AsyncThunkOptions,
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useApolloClient, DocumentNode, Context } from '@apollo/client'
import { useSnack } from '@core/providers/snack'

import * as author from '@pages/author/author-slice'
import * as authorCloze from '@pages/author-cloze/author-cloze-slice'
import * as users from '@pages/users/users-slice'
import * as batches from '@containers/batches/batches-slice'
import * as projects from '@containers/projects/projects-slice'
import * as main from '@containers/main/main-slice'
import * as deliver from '@pages/deliver/deliver-slice'
import * as comments from '@containers/comments/comments-slice'
import * as references from '@containers/references/references-slice'

export const reducer = {
  main: main.slice.reducer,
  author: author.slice.reducer,
  projects: projects.slice.reducer,
  batches: batches.slice.reducer,
  authorCloze: authorCloze.slice.reducer,
  users: users.slice.reducer,
  deliver: deliver.slice.reducer,
  comments: comments.slice.reducer,
  references: references.slice.reducer,
}

export function createStore(
  history: ReturnType<typeof useHistory>,
  client: ReturnType<typeof useApolloClient>,
  snack: ReturnType<typeof useSnack>,
  preloadedState = {}
) {
  return configureStore({
    reducer,
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { history, client, snack },
        },
        serializableCheck: false,
      }),
  })
}

export function useStore(initialState = {}) {
  const history = useHistory()
  const client = useApolloClient()
  const snack = useSnack()

  // never should rebuild the store, so empty array
  return useMemo(() => createStore(history, client, snack, initialState), [])
}

export type Store = ReturnType<typeof useStore>
export type AppDispatch = ReturnType<typeof createStore>['dispatch']
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

declare module '@reduxjs/toolkit' {
  type AsyncThunkConfig = {
    state?: unknown
    dispatch?: AppDispatch
    extra?: { history: any; client: any }
    rejectValue?: unknown
    serializedErrorType?: unknown
  }

  function createAsyncThunk<
    Returned,
    ThunkArg = void,
    ThunkApiConfig extends AsyncThunkConfig = {
      state: RootState
      dispatch: AppDispatch
      extra: {
        history: ReturnType<typeof useHistory>
        client: ReturnType<typeof useApolloClient>
        snack: ReturnType<typeof useSnack>
      }
    }
  >(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
    options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>
}

/**
 * custom useSubscription that dispatch the response to the store
 */

type Variables = Record<string, any>

type Subscription<T> = {
  query: DocumentNode
  variables?: Variables
  onData: (data: T) => any
  onError?: (error: { message: string }) => any
  skip?: boolean
  context?: Context
}

function getDependencies(variables: Variables = {}) {
  return Object.entries(variables).map(([key, value]) => `${key}_${value}`)
}

export function useSubscription<T>({
  query,
  variables,
  context,
  onData,
  onError,
  skip,
}: Subscription<T>) {
  const client = useApolloClient()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (skip) {
      return
    }
    const observable = client.subscribe({
      query,
      variables,
      context,
      fetchPolicy: 'no-cache',
    })

    const subscription = observable.subscribe({
      next: ({ data }: { data: T }) => {
        const result = onData(data)
        if (result) {
          dispatch(result)
        }
      },
      error: (error) => {
        if (onError) {
          const result = onError(error)
          if (result) {
            dispatch(result)
          }
        }
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [...getDependencies(variables), skip])
}
