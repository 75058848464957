import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { State } from '@pages/deliver/deliver-types'
import { FilterType } from './deliver-types'
import * as thunk from './deliver-thunk'

export const thunkActions = thunk

export function getInitialState(): State {
  return {
    filter: {
      type: FilterType.all,
      domain: '',
      topic: '',
    },
    draftItemsLength: 0,
    projects: null,
    sortParams: {
      column: '',
      order: 'asc',
    },
    domainTags: {
      tags: [],
      topics: [],
      hasClones: false,
    },
    loading: true,
  }
}

export const slice = createSlice({
  name: 'deliver',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    setFilter(state, action: PayloadAction<Partial<State['filter']>>) {
      state.filter = {
        type: action.payload.type || state.filter.type,
        domain:
          action.payload.domain === state.filter.domain
            ? ''
            : action.payload.domain ?? state.filter.domain,
        topic:
          action.payload.topic === state.filter.topic
            ? ''
            : action.payload.topic ?? state.filter.topic,
      }
    },
    setSortParams(state: State, action: PayloadAction<string>) {
      state.sortParams.column = action.payload
      state.sortParams.order = state.sortParams.order === 'asc' ? 'desc' : 'asc'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkActions.fetchProjects.fulfilled, (state: State, action) => {
      Object.assign(state, { ...action.payload, loading: false })
    })
    builder.addCase(
      thunkActions.fetchDrafts.fulfilled,
      (state: State, action: PayloadAction<number>) => {
        state.draftItemsLength = action.payload
      }
    )
  },
})

export const { actions } = slice
