import React, { useEffect } from 'react'
import { Box, Button, SxProps } from '@mui/material'
import CommentFeedback from '@author/components/CommentFeedback/comment-feedback'
import { useAppDispatch, useAppSelector } from '@core/store'
import { actions } from '@containers/comments/comments-slice'
import { WatchComments } from './comments-watch'

const styles: Record<string, SxProps> = {
  commentFeedbackContainer: {
    '& .comment-feedback-scrollable': {
      p: '16px !important',
    },
    mb: 5,
    px: 4,
  },
  addTextContainer: {
    bgcolor: 'background.blueGrey',
    height: 48,
    width: '100%',
    justifyContent: 'flex-start',
    border: '2px dashed',
    borderColor: 'other.ghostBlue',
    p: 1.5,
    mt: 3,
    color: 'secondary.dark',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&[disabled]': {
      cursor: 'not-allowed',
      pointerEvents: 'all',
      '&:hover': {
        bgcolor: 'background.blueGrey',
        textDecoration: 'none',
      },
    },
  },
}

const AddNewField = () => {
  const itemId = useAppSelector((state) => state.comments.itemId)
  const dispatch = useAppDispatch()

  if (!itemId) return null
  return (
    <Button
      onClick={() => {
        dispatch(actions.createComment())
      }}
      sx={styles.addTextContainer}
    >
      Add new field
    </Button>
  )
}
interface FeedBackProps {
  itemId: string | null
  comments: any
}
const FeedbackTab = ({ itemId, comments }: FeedBackProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (itemId && comments) {
      dispatch(actions.set({ itemId }))
      dispatch(actions.setComments({ comments }))
    }
  }, [])

  if (!itemId || !comments) return null

  return (
    <Box sx={styles.commentFeedbackContainer} data-testid="feedback-container">
      <WatchComments />
      {comments.map((comment) => {
        return (
          <CommentFeedback
            key={comment.id}
            id={comment.id}
            name={comment.name}
            value={comment.value}
          />
        )
      })}
      <AddNewField />
    </Box>
  )
}

export default FeedbackTab
