import withStyles from '@mui/styles/withStyles'
import Chip from '@mui/material/Chip'

/**
 * Project tag component based on Chip component with color props
 */
export default withStyles((theme) => ({
  root: {
    backgroundColor: ({ bgcolor }) => theme.palette.tag[bgcolor].main,
    color: ({ bgcolor }) => theme.palette.tag[bgcolor].contrastText,
    borderRadius: 5,
    padding: `0 ${theme.spacing(1)}`,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    height: theme.spacing(2.5),
    opacity: ({ selected }) => (selected === undefined || selected ? '1' : '0.5'),
  },
  clickable: {
    '&:hover, &:focus': {
      backgroundColor: ({ bgcolor }) => theme.palette.tag[bgcolor].dark,
    },
  },
  avatar: {
    backgroundColor: ({ bgcolor }) => `${theme.palette.tag[bgcolor].avatarBg} !important`,
  },
}))(Chip)
