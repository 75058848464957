import { RootState } from '@core/store'
import { createSelector } from '@reduxjs/toolkit'
import { ITEM_SET, PASSAGE_BUILDER, STORY, HOT_TEXT } from '@author/constants/questionsTypes'
import { getMetadataFromSelectedFlavors } from '@core/utils/tree'
import { isEqual } from 'lodash'
import { isCommentsMatch } from '@containers/comments/comments-utils'

export const isStoryItem = createSelector(
  (state: RootState) => state.author.selectedModel?.type,
  (state: RootState) => state.author.item?.job?.aiModel.type,
  (selectModelType, jobType) => {
    const jobModel = selectModelType || jobType
    if (jobModel) return [STORY, PASSAGE_BUILDER].includes(jobModel)
    return false
  }
)

export const canSave = createSelector(
  (state: RootState) => state.author.loading,
  (state: RootState) => state.author.content,
  (loading, content) => {
    return !(loading.generate || loading.regenerate) && content && !!content?.question
  }
)

export const hasAllEmptyAnswerOrParts = createSelector(
  (state: RootState) => state.author.content,
  (latestContent) => {
    return (
      Boolean(latestContent?.answers?.every((answer) => answer.value === '')) ||
      Boolean(latestContent?.parts?.every((part) => part.value === '')) ||
      Boolean(latestContent?.options?.every((option) => option.value === ''))
    )
  }
)
export const canSaveItem = createSelector(
  canSave,
  (state: RootState) => state.author.content,
  (state: RootState) => state.author.item?.project?.id,
  (state: RootState) => state.author.item?.finalContentVersion,
  (state: RootState) => state.comments.comments,
  (isSaved, latestContent, projectId, finalContentVersion, lastComments) => {
    const { comments, content } = finalContentVersion || {}
    if (!projectId) return isSaved && !projectId
    const isCommentsDifferent = !isCommentsMatch(comments, lastComments)
    return isSaved && (!isEqual(latestContent, content) || isCommentsDifferent)
  }
)

export const canRegenerateText = createSelector(
  (state: RootState) => state.author.content?.passage,
  (state: RootState) => state.author.content?.custom_passage,
  (state: RootState) => state.author.content?.parts,
  (state: RootState) => state.author.content?.answers,
  (passage, customPassage, parts, answers) => {
    if (parts?.length) {
      return Boolean((passage || customPassage) && parts.length)
    }

    return Boolean((passage || customPassage) && answers?.length)
  }
)

export const currentPassageName = createSelector(
  (state: RootState) => state.author.selectedModel?.flavors,
  (state: RootState) => state.author.selectedFlavors,
  (flavors, selectedFlavors) => {
    const metadata = getMetadataFromSelectedFlavors(flavors, selectedFlavors)
    // @ts-ignore
    if (metadata && metadata._passage_name) {
      // @ts-ignore
      return metadata._passage_name
    }
    return null
  }
)

export const showItem = createSelector(
  isStoryItem,
  (state: RootState) => state.author.generatedItemId,
  (state: RootState) => state.author.item?.id,
  (state: RootState) => state.author.content,
  (state: RootState) => state.author.loading.generate,
  (state: RootState) => state.author.loading.deliver,
  (storyItem, generatedItemId, selectedItemId, content, isGenerating, isItemDelivering) => {
    return (
      !storyItem &&
      !!(generatedItemId || selectedItemId) &&
      content &&
      !(isGenerating || isItemDelivering)
    )
  }
)

export const isItemSet = createSelector(
  (state: RootState) => state.author.selectedModel,
  (selectedModel) => selectedModel?.type === ITEM_SET
)

export const isHotText = createSelector(
  (state: RootState) => state.author.selectedModel,
  (selectedModel) => selectedModel?.type === HOT_TEXT
)
