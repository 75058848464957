import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import { LoadingButton } from '@mui/lab';

import MultilineTag from '@deliver/components/Shared/MultilineTag';
import FTGTooltip from '@core/components/FTGTooltip';
import { CLONE_ITEM } from '@author/components/Item/constants';
import * as selectors from '@pages/author/author-selectors';
import { useAppDispatch, useAppSelector } from '@core/store';
import * as thunkActions from '@pages/author/author-thunk';
import { MCQ } from '@author/constants/questionsTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  saveItem: {
    display: 'flex',
    '& > button:nth-child(1)': { marginLeft: 'auto', whiteSpace: 'nowrap' },
  },
  flavorsContainer: {
    width: 'calc(100% - 172px)',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const HeaderFlavors = ({ itemFlavors }) => {
  const classes = useStyles();
  return (
    <div className={classes.flavorsContainer}>
      {itemFlavors?.map((flavor, i) => (
        <MultilineTag key={`flavor-${flavor}-${i}`} data-testid="flavor" label={flavor} />
      ))}
    </div>
  );
};

HeaderFlavors.propTypes = {
  itemFlavors: PropTypes.array,
};

HeaderFlavors.defaultProps = {
  itemFlavors: [],
};

export const ItemHeader = ({ onSaveAction, onSaveActionLabel, contentType, children }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const itemFlavors = useAppSelector((state) => state.author.content?.item_flavors);
  const passage = useAppSelector((state) => state.author.content?.passage);
  const savingContent = useAppSelector((state) => state.author.loading.savingContent);

  const canSaveItem = useAppSelector(selectors.canSaveItem);
  const hasAllEmptyAnswerOrParts = useAppSelector(selectors.hasAllEmptyAnswerOrParts);
  const isHotText = useAppSelector(selectors.isHotText);
  const canCloneItem = useAppSelector(selectors.canSave);

  return (
    <Box className={classes.container}>
      <Box className={classes.headerRow}>
        <HeaderFlavors itemFlavors={itemFlavors} />

        <Box display="flex">
          <FTGTooltip
            title={`You can't save an item with empty ${contentType === MCQ ? 'answers' : 'parts'}`}
            disabled={!hasAllEmptyAnswerOrParts || isHotText}
          >
            <Box className={classes.saveItem}>
              <LoadingButton
                variant="outlined"
                color="secondary"
                className="save-item-button"
                onClick={onSaveAction}
                disabled={!canSaveItem || (!isHotText && hasAllEmptyAnswerOrParts)}
                aria-label={onSaveActionLabel}
                loading={savingContent}
              >
                {onSaveActionLabel}
              </LoadingButton>
            </Box>
          </FTGTooltip>

          {contentType === 'MCQ' && (
            <FTGTooltip
              title={`Create clone item reusing this ${passage ? 'stimulus' : 'stem'}`}
              placement="top"
              withMargin
            >
              <div>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => dispatch(thunkActions.regenerateItem({ type: CLONE_ITEM }))}
                  aria-label="Clone item stem"
                  disabled={!canCloneItem || savingContent}
                  sx={{ ml: 0.5, minWidth: 44 }}
                >
                  <CopyIcon />
                </Button>
              </div>
            </FTGTooltip>
          )}
        </Box>
      </Box>

      {children}
    </Box>
  );
};

ItemHeader.propTypes = {
  onSaveAction: PropTypes.func.isRequired,
  onSaveActionLabel: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ItemHeader.defaultProps = {
  contentType: '',
};
