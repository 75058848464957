import { useState, useCallback, useMemo } from 'react';

const useToggle = (start = false) => {
  const [status, setStatus] = useState(start);

  const toggleStatus = useCallback(() => {
    setStatus((prevStatus) => !prevStatus);
  }, []);

  const values = useMemo(
    () => ({
      status,
      toggleStatus,
    }),
    [status, toggleStatus]
  );

  return values;
};

export default useToggle;
