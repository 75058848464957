import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './app'

const container = document.getElementById('app-container')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

root.render(<App />)

if (['local', 'dev', 'qa'].includes(process.env.ENV as string)) {
  // eslint-disable-next-line
  const axe = require('@axe-core/react')

  axe(React, root, 1000, {
    runOnly: {
      type: 'tag',
      values: ['wcag2a', 'wcag2aa'],
    },
  })
}
