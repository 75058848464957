import React from 'react'
import {
  Alert,
  TextField as MuiTextField,
  SxProps,
  FormControl,
  StandardTextFieldProps,
} from '@mui/material'

const styles: Record<string, SxProps> = {
  root: {
    my: 1,
    '& fieldset': { border: 'none' },
    '& .MuiOutlinedInput-root': {
      bgcolor: '#f5f6f8',
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
    },
  },
}

interface Props extends StandardTextFieldProps {
  errorMessage?: string
}

export const TextField = (props: Props) => {
  const { title, errorMessage, required, helperText = 'Required*', ...rest } = props
  return (
    <FormControl fullWidth>
      <MuiTextField
        variant="outlined"
        size="small"
        required={required}
        helperText={required && helperText}
        FormHelperTextProps={{
          sx: {
            mt: 0.5,
            ml: 0,
            color: 'text.grayishBlue',
          },
        }}
        sx={styles.root}
        {...rest}
      />
      {errorMessage && (
        <Alert
          icon={false}
          severity="error"
          sx={{
            fontWeight: 500,
            borderRadius: 0,
            color: 'text.milanoRed',
            mb: 1,
          }}
        >
          {errorMessage}
        </Alert>
      )}
    </FormControl>
  )
}
