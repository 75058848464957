import React, { useState, useContext, useCallback, createContext, useMemo } from 'react'
import { Snackbar, Alert, AlertColor } from '@mui/material'

interface Notification {
  key: string
  message: React.ReactNode
  open: boolean
  severity: AlertColor
}

interface AddArgs {
  message: Notification['message']
  severity?: Notification['severity']
}

interface State {
  openKey: string
  items: Record<string, Notification>
}

interface Props {
  children: React.ReactNode
}

interface RenderSnacksProps extends State {
  setState: React.Dispatch<React.SetStateAction<State>>
}

const initialState: State = { items: {}, openKey: '' }

const SnackbarContext = createContext<{
  add: (args: AddArgs) => void
}>({ add: () => {} })

export function useSnack() {
  return useContext(SnackbarContext)
}

function remove(prev: State, key: string): State {
  const copy = { ...prev.items }
  delete copy[key]
  return { ...prev, items: copy }
}

/**
 * It only render one snackbar at a time. It automatically opens the next one, if available.
 */
function RenderSnacks({ items, openKey, setState }: RenderSnacksProps) {
  const handleClose = (index: number) => {
    const keys = Object.keys(items)
    const nextKey = keys[index + 1] || ''

    setState((state) => ({ ...state, openKey: nextKey }))
    setTimeout(() => setState((prev) => remove(prev, keys[index])), 500)
  }

  return (
    <>
      {Object.values(items).map((item, index) => {
        return (
          <Snackbar
            key={item.key}
            open={item.key === openKey}
            onClose={() => handleClose(index)}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert onClose={() => handleClose(index)} severity={item.severity}>
              {item.message}
            </Alert>
          </Snackbar>
        )
      })}
    </>
  )
}

export function SnackbarContextProvider({ children }: Props) {
  const [state, setState] = useState<State>(initialState)

  const add = useCallback(
    ({ message, severity = 'info' }: AddArgs) => {
      const key = `snack-${Date.now()}`
      const notification = { message, severity, key, open: true }

      setState((prevState) => ({
        ...prevState,
        openKey: prevState.openKey || key,
        items: {
          ...prevState.items,
          [key]: notification,
        },
      }))
    },
    [setState]
  )

  const value = useMemo(() => ({ add }), [add])

  return (
    <SnackbarContext.Provider value={value}>
      <RenderSnacks {...state} setState={setState} />
      {children}
    </SnackbarContext.Provider>
  )
}
