import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'
import { Route } from '@core/constants/routes'

export interface RouteMatch {
  path: Route
  url: string
  params: Record<string, any>
  isExact: boolean
}

interface features {
  references: boolean
  admin_v2: boolean
  export_file: string[]
}

export interface Customer {
  id: string
  name: string
  hasOptionRationale: boolean
  features: features
}

export type Role = 'User' | 'Admin'

export interface User {
  id: string
  firstName: string
  lastName: string
  email: string
  name: string
  role: Role
  canResetCustomerData: boolean
  canDeleteProjects: boolean
  picture: string
  internalAdmin: boolean
}

interface Download {
  url: string | null
  id: string
  project: string
}

interface State {
  customer: Customer | null
  user: User | null
  routeMatch: RouteMatch | null
  downloadItems: Download[]
  downloadNotification: boolean
}

export function getInitialState(): State {
  return {
    customer: null,
    user: null,
    routeMatch: null,
    downloadItems: [],
    downloadNotification: false,
  }
}

export const routeEnter = createAction<RouteMatch>('main/routeEnter')
export const routeLeave = createAction<RouteMatch>('main/routeLeave')

export const slice = createSlice({
  name: 'main',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    setDownloadItems(state: State, action: PayloadAction<Download>) {
      const index = state.downloadItems.findIndex((x) => x?.id === action.payload.id)
      if (index === -1) state.downloadItems.push(action.payload)
      state.downloadItems[index] = action.payload
    },
    removeDownload(state: State, action: PayloadAction<{ id: string }>) {
      state.downloadItems = state.downloadItems.filter((obj) => obj.id !== action.payload.id)
    },
    resetDownloadItems(state: State) {
      state.downloadItems = getInitialState().downloadItems
    },
    reset(state: State) {
      Object.assign(state, getInitialState())
    },
  },
  extraReducers: (builder) => {
    builder.addCase(routeEnter, (state, action) => {
      state.routeMatch = action.payload
    })
  },
})

export default slice

export const actions = {
  ...slice.actions,
  routeEnter,
  routeLeave,
}
