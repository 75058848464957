import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import clsx from 'clsx'

import { ListItem, ListItemIcon, SvgIcon, CircularProgress, Skeleton, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CopyIcon from '@mui/icons-material/FileCopy'

import CheckCircleIcon from 'src/assets/images/icon-circle-check-24.svg'
import { AUTHOR_ITEM_ROUTE } from '@core/constants/routes'
import { isAIProgress } from '@author/utils'
import { IN_PROGRESS, NOT_STARTED, FAILED } from '@author/constants/progressSteps'
import { Item, ItemFake } from './batches-types'

const useStyles = makeStyles((theme) => ({
  listItem: {
    '& .MuiListItemIcon-root': {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
  },
  item: {
    fontSize: '1rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textDecoration: 'none',
    color: theme.palette.text.slate,
    fontWeight: 500,
    '&.item-visited': {
      color: theme.palette.text.slate,
      fontWeight: 'normal',
    },
    '&.item-saved': {
      color: theme.palette.text.slate,
    },
    '&.item-selected': {
      textDecoration: 'underline',
    },
    '&.item-archived': {
      pointerEvents: 'none',
      color: theme.palette.text.disabled,
    },
    '& svg': {
      fill: theme.palette.text.slate,
    },
  },
  cloneIcon: {
    color: theme.palette.text.slate,
    fontSize: 20,
  },
}))

interface Props {
  selectedItemId?: string
  item: Item | ItemFake
  isRegenerating: boolean
}

function ItemSkeleton() {
  return (
    <Box my={1}>
      <Skeleton data-testid="loading-skeleton" animation="wave" height={34} />
    </Box>
  )
}

export function BatchItem({ selectedItemId, item, isRegenerating }: Props) {
  const classes = useStyles()

  const stepAI = isAIProgress(item?.step)

  if (!item || (stepAI && [NOT_STARTED, IN_PROGRESS].includes(item.status)) || isRegenerating)
    return <ItemSkeleton />

  const {
    id,
    isArchived,
    lastQuestion,
    finalQuestion,
    lastAnswers,
    status,
    projectId,
    clonedFromId,
  } = item as Item

  const question = finalQuestion || lastQuestion

  if ((!lastAnswers && !question) || (stepAI && status === FAILED)) return null

  const title = lastAnswers && lastAnswers.length > 0 && !question ? 'Untitled' : question

  const className = clsx(classes.item, {
    'item-selected': id === selectedItemId,
    'item-visited': item?.qualityMetrics && item?.qualityMetrics.length,
    'item-saved': Boolean(projectId),
    'item-archived': isArchived,
  })

  return (
    <ListItem
      data-testid="batch-list-item"
      key={`batch-item-${id}`}
      role={undefined}
      disableGutters
      className={classes.listItem}
    >
      {status === IN_PROGRESS && (
        <ListItemIcon>
          <CircularProgress color="secondary" size={20} data-testid="loading-circular" />
        </ListItemIcon>
      )}
      {clonedFromId && (
        <ListItemIcon className={classes.cloneIcon}>
          <CopyIcon data-testid={`clone-icon-${id}`} fontSize="small" />
        </ListItemIcon>
      )}

      {projectId && (
        <ListItemIcon>
          <SvgIcon
            data-testid="delivered-icon"
            component={CheckCircleIcon}
            style={{ fontSize: 20 }}
          />
        </ListItemIcon>
      )}

      <Link
        to={!isArchived ? generatePath(AUTHOR_ITEM_ROUTE, { itemId: id }) : '#'}
        data-testid="batch-list-item-a"
        className={className}
      >
        {title}
      </Link>
    </ListItem>
  )
}

export default BatchItem
