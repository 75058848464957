import { ContentBlock, EditorState, Modifier, ContentState } from 'draft-js'

export type EntityRange = {
  start: number
  end: number
  entity?: string
  keyBlock?: string
}

export type EntityData = {
  id: string
  index: number
  text: string
}

const END_TYPES = ['?', '.', '.', '!', '-', ';', ':', ',']

export function removePunctuationAtEnd(text: string): string {
  return END_TYPES.includes(text[text.length - 1]) ? text.slice(0, -1) : text
}

export const transformRegex = (text: string) => {
  return new RegExp(removePunctuationAtEnd(text).replace(/[*+?^${}()|[\]\\]/gi, '\\$&'), 'gi')
}

export function findEnd(text: string, end: number) {
  const current = text[end]
  const next = text[end + 1]

  if (text[end - 1] === ' ') {
    return end - 1
  }

  if (current === ' ') return end

  const isMoreThanLimit = end >= text.length - 1

  if (isMoreThanLimit) return text.length

  if (END_TYPES.includes(next)) return end + 2

  if (!next || next === ' ') return end + 1

  return findEnd(text, end + 1)
}

export function findStart(text: string, start: number) {
  if (start === 0) return 0
  const current = text[start]
  const prev = text[start - 1]

  if (current === ' ') return start + 1

  if (!prev || prev === ' ') return start

  if (END_TYPES.includes(prev)) return start + 1

  return findStart(text, start - 1)
}

export const getEntityRangeByCommand = (
  editorState: EditorState,
  command: string
): EntityRange | null => {
  const selectionState = editorState.getSelection()
  const selectionKey = selectionState.getStartKey()
  const contentState = editorState.getCurrentContent()
  const contentBlock = contentState.getBlockForKey(selectionKey)

  const position = selectionState.getStartOffset()
  const startPosition = selectionState.getStartOffset()
  const endPosition = selectionState.getEndOffset()

  let entityRange: any = null
  let entity = ''
  contentBlock.findEntityRanges(
    (character) => {
      entity = character.getEntity()
      if (entity) {
        return true
      }
      return false
    },
    (start, end) => {
      switch (command) {
        case 'backspace': {
          if (position >= start && position <= end) {
            entityRange = { entity, start, end }
          }
          break
        }
        case 'delete': {
          if (position >= start && position < end) {
            entityRange = { entity, start, end }
          }
          break
        }
        case 'range': {
          if (startPosition <= end && start <= endPosition) {
            entityRange = { entity, start, end }
          }
          break
        }
        default: {
          return null
        }
      }
    }
  )

  return entityRange
}

export function removeEntity(editorState: EditorState): EditorState {
  const contentState = editorState.getCurrentContent()
  const selectionState = editorState.getSelection()
  const isBackward = editorState.getSelection().getIsBackward()
  const oldState = {
    anchorOffset: isBackward ? selectionState.getEndOffset() : selectionState.getStartOffset(),
    focusOffset: isBackward ? selectionState.getStartOffset() : selectionState.getEndOffset(),
  }
  const entityRange = getEntityRangeByCommand(editorState, 'range')

  if (!entityRange) {
    return editorState
  }
  const { start, end } = entityRange

  let entitySelection: any = null
  entitySelection = selectionState.merge({
    anchorOffset: isBackward ? end : start,
    focusOffset: isBackward ? start : end,
  })

  if (!entitySelection) return editorState

  const newContentState = Modifier.applyEntity(contentState, entitySelection, null)
  const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity')

  return EditorState.forceSelection(newEditorState, newEditorState.getSelection().merge(oldState))
}

export function removeLastUndo(state: EditorState, skip = 0): EditorState {
  const undoStack = state.getUndoStack()
  const filtered = undoStack.filter((_, index) => index !== undoStack.size - skip)
  return EditorState.set(state, { undoStack: filtered })
}

export const getOverlapEntityKey = (
  start: number,
  end: number,
  contentBlock: ContentBlock,
  contentState?: ContentState
): EntityData | undefined => {
  let overlappedEntityKey: string | undefined
  let entityKey: string | undefined
  contentBlock.findEntityRanges(
    (character) => {
      entityKey = character.getEntity()
      if (entityKey) {
        return true
      }
      return false
    },
    (startEntity, endEntity) => {
      if (!overlappedEntityKey && start <= endEntity && startEntity <= end)
        overlappedEntityKey = entityKey
    }
  )
  return contentState && overlappedEntityKey
    ? contentState.getEntity(overlappedEntityKey).getData()
    : overlappedEntityKey
}

export const checkOverlapEntity = (start: number, end: number, contentBlock: ContentBlock) => {
  return getOverlapEntityKey(start, end, contentBlock) !== null
}

export const forceSelection = (
  editorState: EditorState,
  entityRange?: EntityRange,
  isForce = false
) => {
  let entitySelection: any = null
  const isBackward = editorState.getSelection().getIsBackward()
  const selectionState = editorState.getSelection()
  if (!entityRange) {
    const contentState = editorState.getCurrentContent()
    const startKey = selectionState.getStartKey()
    const contentBlock = contentState.getBlockForKey(startKey)
    const startOffset = selectionState.getStartOffset()

    const entity = contentBlock.getEntityAt(startOffset)
    if (!entity) {
      return editorState
    }
    contentBlock.findEntityRanges(
      (character) => character.getEntity() === entity,
      (start, end) => {
        entitySelection = selectionState.merge({
          anchorOffset: isBackward ? end : start,
          focusOffset: isBackward ? start : end,
        })
      }
    )
  } else {
    const { start, end } = entityRange
    entitySelection = selectionState.merge({
      anchorOffset: isBackward ? end : start,
      focusOffset: isBackward ? start : end,
    })
  }

  if (!entitySelection) return editorState

  return isForce
    ? EditorState.forceSelection(editorState, entitySelection)
    : EditorState.acceptSelection(editorState, entitySelection)
}

export const findEntityByItemId = (id, editorState: EditorState): EntityRange => {
  const contentState = editorState.getCurrentContent()
  let entityRange: any = null
  let entity = ''

  contentState.getBlockMap().forEach((block) => {
    block?.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity()
        if (entityKey) {
          const data = contentState.getEntity(entityKey).getData()
          if (data.id === id) {
            entity = entityKey
            return true
          }
        }
        return false
      },
      (start, end) => {
        entityRange = { start, end, entity, keyBlock: block.getKey() }
      }
    )
  })

  return entityRange
}

export const replaceSelectionEntity = (
  editorState: EditorState,
  detail: EntityData
): EditorState => {
  const currentContent = editorState.getCurrentContent()
  const text = detail.text.trim() ? detail.text : 'CANNOT BE EMPTY.'
  const contentStateWithEntity = currentContent.createEntity('SELECTED', 'MUTABLE', {
    ...detail,
    text,
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

  const selectionState = editorState.getSelection()

  const contentState = editorState.getCurrentContent()
  const entityRange = findEntityByItemId(detail.id, editorState)

  if (!entityRange) return editorState

  const isBackward = selectionState.getIsBackward()
  const { start, end, keyBlock } = entityRange
  const selection = selectionState.merge({
    anchorOffset: isBackward ? end : start,
    focusOffset: isBackward ? start : end,
    anchorKey: keyBlock,
    focusKey: keyBlock,
  })

  const updatedEditorWithText = Modifier.replaceText(
    contentState,
    selection,
    text,
    editorState.getCurrentInlineStyle(),
    entityKey
  )

  return EditorState.push(editorState, updatedEditorWithText, 'insert-characters')
}

export const replaceEntityByStart = (
  editorState: EditorState,
  text: string,
  startOffSet: number
): EditorState => {
  const currentContent = editorState.getCurrentContent()

  const selectionState = editorState.getSelection()

  const selectionKey = selectionState.getStartKey()
  const contentState = editorState.getCurrentContent()
  const contentBlock = contentState.getBlockForKey(selectionKey)
  let entityRange: any = null

  contentBlock.findEntityRanges(
    (character) => {
      const entity = character.getEntity()
      if (entity) {
        return true
      }
      return false
    },
    (start, end) => {
      if (start <= startOffSet && startOffSet <= end) {
        entityRange = { start, end }
      }
    }
  )

  if (!entityRange) return editorState

  const { start, end } = entityRange
  const detail = contentState.getEntity(contentBlock.getEntityAt(start)).getData()

  const isBackward = selectionState.getIsBackward()
  const selection = selectionState.merge({
    anchorOffset: isBackward ? end : start,
    focusOffset: isBackward ? start : end,
  })

  const contentStateWithEntity = currentContent.createEntity('SELECTED', 'MUTABLE', {
    ...detail,
    text,
  })
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

  const updatedEditorWithText = Modifier.replaceText(
    contentState,
    selection,
    text,
    editorState.getCurrentInlineStyle(),
    entityKey
  )

  return EditorState.push(editorState, updatedEditorWithText, 'insert-characters')
}

export function rebuildDataIndex(editorState: EditorState, mcqCount: number) {
  let newState = editorState
  const entities: any[] = []
  const contentState = editorState.getCurrentContent()

  newState
    .getCurrentContent()
    .getBlockMap()
    .forEach((block) =>
      block?.findEntityRanges(
        (character) => {
          const charEntity = character.getEntity()

          if (charEntity) {
            const contentEntity = contentState.getEntity(charEntity)
            entities.push(contentEntity.getData())
          }

          return true
        },
        // callback not necessary, only to skip TS error
        () => {}
      )
    )

  entities.forEach((data, index) => {
    newState = replaceSelectionEntity(newState, {
      ...data,
      index: index + mcqCount + 1,
    })
  })

  return newState
}

export const insertStickyTextEntity = (
  editorState: EditorState,
  text: string,
  rangeEntity: EntityRange
): EditorState => {
  const selection = editorState.getSelection()
  const style = editorState.getCurrentInlineStyle()
  const content = editorState.getCurrentContent()
  const originalSelection = document.getSelection()

  if (originalSelection && originalSelection?.anchorOffset > 0) {
    const newContent = selection.isCollapsed()
      ? Modifier.insertText(content, selection, text, style, rangeEntity.entity)
      : Modifier.replaceText(content, selection, text, style, rangeEntity.entity)

    const editor = EditorState.push(editorState, newContent, 'insert-characters')
    const nextOffSet = editor.getSelection().getAnchorOffset()

    const newSelection = editor.getSelection().merge({
      focusOffset: nextOffSet,
      anchorOffset: nextOffSet,
    })

    return EditorState.acceptSelection(editor, newSelection)
  }
  return editorState
}

export const insertSpaceText = (editorState: EditorState) => {
  const selectionState = editorState.getSelection()
  if (!selectionState.isCollapsed()) {
    return
  }
  const entityRange = getEntityRangeByCommand(editorState, 'range')

  const startPosition = selectionState.getStartOffset()
  const content = editorState.getCurrentContent()
  const text = content.getPlainText()
  const isBackward = selectionState.getIsBackward()
  const oldState = {
    anchorOffset: isBackward ? selectionState.getEndOffset() : selectionState.getStartOffset(),
    focusOffset: isBackward ? selectionState.getStartOffset() : selectionState.getEndOffset(),
  }

  const newContent = Modifier.insertText(content, selectionState, ' ')
  const newEditor = EditorState.push(editorState, newContent, 'insert-characters')

  if (entityRange?.end === startPosition && startPosition === text.length) {
    return EditorState.forceSelection(newEditor, newEditor.getSelection().merge(oldState))
  }
  if (entityRange?.start === startPosition && startPosition === 0) {
    return EditorState.forceSelection(
      newEditor,
      selectionState.merge({
        anchorOffset: oldState.anchorOffset - 1,
        focusOffset: oldState.anchorOffset - 1,
      })
    )
  }
  return editorState
}
