import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorHandler from '@core/api/ErrorHandler'
import { gql } from '@apollo/client'
import { Reference } from './references-types'

export const queryReferences = gql`
  query references($query: String!, $aiModelId: uuid!) {
    search_references(params: { query: $query, ai_model_id: $aiModelId }) {
      response
    }
  }
`

export const getReferences = createAsyncThunk<Reference[], { query: string; aiModelId: string }>(
  'references/query',
  async ({ query, aiModelId }, { extra }) => {
    try {
      const { data } = await extra.client.query({
        query: queryReferences,
        variables: { query, aiModelId },
      })

      return data.search_references.response
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({ severity: 'error', message })
      throw error
    }
  }
)
