import React from 'react'
import { convertFromRaw, ContentState, CompositeDecorator } from 'draft-js'
import { convertFromHTML, convertToHTML } from 'draft-convert'
import { Box, SxProps } from '@mui/material'

import { stripHTML } from '@core/utils/strip-html'

type PropLink = {
  entityKey: string
  contentState: ContentState
  children: React.ReactNode
}

const styles: Record<string, SxProps> = {
  selected: {
    display: 'inline-flex',
    justifyContent: 'center',
    borderRadius: 1,
    overflow: 'hidden',
    color: 'primary.main',
    bgcolor: '#71e8c0',
    lineHeight: 1.5,
    pr: 0.5,
    '&:before': {
      typography: 'caption',
      userSelect: 'none',
      lineHeight: 2,
      px: 0.5,
      mr: 0.5,
      bgcolor: '#172b4d',
      color: '#fff',
      content: 'attr(data-index)',
    },
  },
  noBorder: {
    display: 'inline-flex',
    justifyContent: 'center',
    borderRadius: 1,
    overflow: 'hidden',
    color: 'primary.main',
    bgcolor: '#71e8c0',
    mt: 1,
    alignItems: 'baseline',
    lineHeight: 1.5,
    pr: 0.5,
    '&:before': {
      typography: 'caption',
      userSelect: 'none',
      lineHeight: 2,
      mr: 0.5,
      color: '#fff',
      content: 'attr(data-index)',
    },
  },
}

function Link({ entityKey, contentState, children }: PropLink) {
  const { index, id } = contentState.getEntity(entityKey).getData()

  return (
    <Box id={id} component="a" data-index={index} sx={styles.selected}>
      {children}
    </Box>
  )
}

function LinkNoBorder({ entityKey, contentState, children }: PropLink) {
  const { index, id } = contentState.getEntity(entityKey).getData()

  return (
    <Box id={id} component="a" data-index={index} sx={styles.noBorder}>
      {children}
    </Box>
  )
}

const SELECTED = {
  type: 'SELECTED',
  decorator: Link,
  attributes: ['href', 'index', 'id', 'className'],
}

const importerConfig = {
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a' && node.classList.contains('selected')) {
      return createEntity(SELECTED.type, 'MUTABLE', {
        id: node.getAttribute('href').slice(1),
        index: node.getAttribute('data-index'),
        text: node.textContent,
      })
    }

    return null
  },
  entityToHTML: (entity, originalText) => {
    if (entity.type === SELECTED.type) {
      return (
        <a className="selected" href={`#${entity.data.id}`} data-index={entity.data.index}>
          {stripHTML(originalText)}
        </a>
      )
    }

    return originalText
  },
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === SELECTED.type
  }, callback)
}

export const decorators = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
])

export const decoratorsNoBorder = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: LinkNoBorder,
  },
])

export const fromHTML = (html) => convertFromHTML(importerConfig)(html)
export const toHTML = (raw) => (raw ? convertToHTML(importerConfig)(convertFromRaw(raw)) : '')
