import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getSelectableAnswerStatus } from '@author/utils/project'
import { useAppDispatch, useAppSelector } from '@core/store'
import { actions } from '@pages/author/author-slice'
import { CustomDragContext, CustomDraggable, CustomDroppable } from '@core/components/CustomDnD'
import ContentOption from './ContentOption'

export * from './constants'

export interface AnswersFormProps {
  isDragEnabled: boolean
}

const useStyles = makeStyles((theme) => ({
  isDragging: {
    background: theme.palette.background.paleGrey,
  },
  isDraggingOver: {
    background: theme.palette.background.blueGrey,
  },
}))

const AnswersForm = ({ isDragEnabled }: AnswersFormProps) => {
  const clasess = useStyles()
  const selectableStatuses = getSelectableAnswerStatus()
  const originalContent = useAppSelector((state) => state.author.item?.originalContentVersion)

  const dispatch = useAppDispatch()
  const finalAnswers = useAppSelector((state) => state.author.content?.answers)
  const { itemId } = useParams<{ itemId: string }>()
  const hasOptionRationale = useAppSelector((state) => state.main.customer?.hasOptionRationale)

  const changeAnswer = (index, name, value) => {
    dispatch(actions.setContent({ index, name, answer: value }))
  }

  const onDragEnd = (newOptions) => {
    dispatch(actions.setContent({ answers: newOptions }))
  }

  return (
    <Box>
      <CustomDragContext items={finalAnswers} onDragEndCallback={onDragEnd}>
        <CustomDroppable className={clasess.isDraggingOver}>
          {finalAnswers?.map(({ rationales }, index) => {
            const dragId = `dragId-${index}`
            return (
              <Box key={index}>
                <CustomDraggable
                  key={dragId}
                  id={dragId}
                  index={index}
                  disabled={!isDragEnabled}
                  className={clasess.isDragging}
                >
                  <ContentOption
                    key={`${itemId}-answer-${index}`}
                    isDragEnabled={isDragEnabled}
                    index={index}
                    rationales={rationales}
                    originalStatus={originalContent?.content?.answers[index]?.status}
                    selectableStatuses={selectableStatuses}
                    setValue={changeAnswer}
                    hasOptionRationale={hasOptionRationale}
                  />
                </CustomDraggable>
              </Box>
            )
          })}
        </CustomDroppable>
      </CustomDragContext>
    </Box>
  )
}
export default AnswersForm
