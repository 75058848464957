import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorHandler from '@core/api/ErrorHandler'

import * as queries from './projects-queries'
import { User, ProjectUserAccess, Project } from './projects-types'

export const createProject = createAsyncThunk<
  { error?: string; project?: Project },
  { name: string }
>('projects/create', async ({ name }, { extra, getState }) => {
  const customerId = getState().main.customer?.id

  if (!customerId) throw new Error('No customer selected')

  try {
    const projectData = { name, customer_id: customerId, can_author: true }

    const { data } = await extra.client.mutate({
      mutation: queries.INSERT_PROJECT,
      variables: { projectData },
    })

    return { project: data.project }
  } catch (error) {
    if (error.message.includes('project_customer_id_name_key')) {
      const message = 'Folder name already exists'
      extra.snack.add({ severity: 'error', message })
      return { error: message }
    }

    const { message } = ErrorHandler(error)
    extra.snack.add({ severity: 'error', message })

    return { error: message }
  }
})

export const updateProject = createAsyncThunk<
  { error?: string; project?: Project },
  { id: string; name: string }
>('projects/update', async ({ id, name }, { extra }) => {
  try {
    const { data } = await extra.client.mutate({
      mutation: queries.UPDATE_PROJECT,
      variables: { id, name },
    })

    return { project: data.project }
  } catch (error) {
    if (error.message.includes('project_customer_id_name_key')) {
      const message = 'Folder name already exists'
      extra.snack.add({ severity: 'error', message })
      return { error: message }
    }

    const { message } = ErrorHandler(error)
    extra.snack.add({ severity: 'error', message })
    return { error: message }
  }
})

export const deleteProject = createAsyncThunk(
  'projects/delete',
  async ({ id, name }: { id: string; name: string }, { extra }) => {
    try {
      const archivedTime = new Date().toISOString()
      const variables = { id, isArchived: true, name: `Archived-${archivedTime} ${name}` }

      const { data } = await extra.client.mutate({
        mutation: queries.DELETE_PROJECT,
        variables,
      })

      return data?.update_project_by_pk
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({ severity: 'error', message })
    }
  }
)

export const getUserByProject = createAsyncThunk<User[], { projectId: string }>(
  'projects/getUser',
  async ({ projectId }, { extra }) => {
    const context = { headers: { 'x-hasura-role': 'customer_admin' } }

    try {
      const { data } = await extra.client.query({
        query: queries.GET_USER_PROJECT,
        variables: { projectId },
        context,
        fetchPolicy: 'no-cache',
      })

      return data.user
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({ severity: 'error', message })
    }
  }
)

export const updateProjectUser = createAsyncThunk<ProjectUserAccess, ProjectUserAccess>(
  'projects/updateProjectUser',
  async (variables, { extra }) => {
    const context = { headers: { 'x-hasura-role': 'customer_admin' } }

    try {
      const { data } = await extra.client.mutate({
        mutation: queries.UPDATE_PROJECT_USER,
        variables,
        context,
      })

      return data.projectUser
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({ severity: 'error', message })
    }
  }
)
