import React from 'react';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  nav: {
    '& li [class*="selected"]': {
      background: theme.palette.tag.dusk.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        background: theme.palette.tag.dusk.main,
      },
    },
  },
}));

const FTPagination = (props) => {
  const classes = useStyles();

  return (
    <Pagination classes={{ root: classes.root, ul: classes.nav }} shape="rounded" {...props} />
  );
};

export default FTPagination;
