import { createAsyncThunk } from '@reduxjs/toolkit'

import ErrorHandler from '@core/api/ErrorHandler'
import { selectUserRole } from '@containers/main/main-utils'
import { getDateAgo } from '@deliver/utils/project'
// import { mountProjects } from '@pages/deliver/deliver-utils'

import * as queries from './deliver-queries'
import { buildProjectUsers } from './deliver-utils'

export const fetchProjects = createAsyncThunk(
  'deliver/fetchProjects',
  async (_, { extra, getState }) => {
    const {
      role,
      isAdmin,
      user: { email },
    } = selectUserRole(getState())

    try {
      const { data } = await extra.client.query({
        fetchPolicy: 'network-only',
        query: queries.GET_PROJECTS,
        context: { role },
      })

      const tags = new Set()
      const topics = new Set()
      let clones = 0

      for (const project of data.projects) {
        if (project.stats.clones) {
          clones += 1
        }

        project.stats.topics.forEach((value) => topics.add(value))
        project.stats.domains.forEach((value) => tags.add(value))
      }

      const { users, projects } = buildProjectUsers(data.projects, isAdmin, email)

      return {
        projects,
        domainTags: {
          tags: Array.from(tags).map((i) => ({ name: i, id: i })),
          hasClones: clones > 0,
          topics: Array.from(topics),
        },
        users,
      }
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({
        message: `Error while fetching folders. ${message}`,
        severity: 'error',
      })
      throw error
    }
  }
)

export const fetchDrafts = createAsyncThunk(
  'deliver/fetchDrafts',
  async (_, { extra, getState }) => {
    const { role, user } = selectUserRole(getState())
    const draftDate = getDateAgo(5 * 24 * 60 * 60)

    try {
      const { data } = await extra.client.query({
        fetchPolicy: 'network-only',
        query: queries.GET_DRAFTS,
        context: { role },
        variables: { draftDate, userId: user.id },
      })

      return data.items.aggregate.count
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({
        message: `Error while fetching draft items. ${message}`,
        severity: 'error',
      })
      throw error
    }
  }
)

export const deleteProjects = createAsyncThunk('deliver/deleteProjects', async (_, { extra }) => {
  try {
    const { data } = await extra.client.mutate({
      mutation: queries.DELETE_PROJECTS,
    })

    if (data) {
      extra.snack.add({
        message: 'Folders deleted successfully!',
        severity: 'success',
      })
    }
  } catch (error) {
    const { message } = ErrorHandler(error) as any
    extra.snack.add({
      message: `Error when trying to delete folders. ${message}`,
      severity: 'error',
    })
    throw error
  }
})

export const resetCustomerData = createAsyncThunk(
  'deliver/resetCustomerData',
  async (_, { extra }) => {
    try {
      const { data } = await extra.client.mutate({
        mutation: queries.RESET_CUSTOMER_DATA,
      })

      if (data) {
        extra.snack.add({
          message: `Customer data reset successfully!`,
          severity: 'success',
        })
      }
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({
        message: `Error when trying to reset customer data. ${message}`,
        severity: 'error',
      })
      throw error
    }
  }
)
