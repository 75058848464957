/* globals */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// eslint-disable-next-line import/no-mutable-exports
let Raven = null;

if (SENTRY_DSN) {
  Raven = Sentry;

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.ENV,
    release: `ftg-saas-ui@${SENTRY_RELEASE}`,
    integrations: [
      new Integrations.BrowserTracing({
        tracePropagationTargets: [/.*-hasura.generate.finetunelearning.com\/.*/],
      }),
    ],
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  });
} else {
  Raven = {
    captureException(exception = '') {
      // eslint-disable-next-line
      console.warn('Raven - Caught exception: ', exception);
    },
    captureMessage(message, contextualData) {
      console.warn('Raven - Info: ', message, contextualData);
    },
    configureScope() {},
    withScope() {},
  };
}

export default Raven;
