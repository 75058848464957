/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { countWords } from '@core/utils/text'
import { routeEnter } from '@containers/main/main-slice'
import { CLOZE } from '@author/constants/questionsTypes'
import { stripHTML } from '@core/utils/strip-html'

import { State, StateLoaded, AnswerStatus, ItemCloze, ItemMCQ } from './author-cloze-types'
import * as thunk from './author-cloze-thunk'
import { getOptions } from './author-cloze-utils'

export const thunkActions = thunk

export function getInitialState(): State {
  return {
    initialized: false,
    contentTab: 0,
    sidebarTab: 0,
    notFound: false,
    items: null,
    // the current accordion opened
    expanded: null,
    savingDraft: false,
    savingItem: false,
    // match of selection in sidebar - used to highlight the passage
    match: null,
    // used to force a full rebuild of the editor
    key: 1,
  }
}

function buildQualityMetrics(question: string) {
  return [
    {
      name: 'Word Count',
      max: 500,
      min: 0,
      value: countWords(stripHTML(question) as string),
    },
  ]
}

export const slice = createSlice({
  name: 'author-cloze',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    // only for MCQ
    setItemQuestion(state: StateLoaded, action: PayloadAction<{ itemId: string; value: string }>) {
      const { itemId, value } = action.payload
      const item = state.items[itemId] as ItemMCQ
      item.lastContent.question = value
    },
    setAnswerValue(
      state: StateLoaded,
      action: PayloadAction<{ itemId: string; value: string; answerIndex: number }>
    ) {
      const { itemId, value, answerIndex } = action.payload
      const item = state.items[itemId]
      const options = getOptions(item)
      options[answerIndex].value = value
    },

    setAnswerKey(
      state: StateLoaded,
      action: PayloadAction<{ itemId: string; value: AnswerStatus; answerIndex: number }>
    ) {
      const { itemId, value, answerIndex } = action.payload
      const item = state.items[itemId]
      const options = getOptions(item)

      if (value === AnswerStatus.key) {
        options.forEach((answer) => {
          if (answer.status === AnswerStatus.key) {
            answer.status = AnswerStatus.distractor
          }
        })
      }

      options[answerIndex].status = value
    },
    statusUpdate(
      state: StateLoaded,
      action: PayloadAction<{ id: string; status: string; step: string }[]>
    ) {
      const rootId = state.items.root.id

      for (const item of action.payload) {
        if (item.id === rootId) {
          state.items.root.status = item.status
          state.items.root.step = item.step
        } else if (state.items[item.id]) {
          state.items[item.id].status = item.status
          state.items[item.id].step = item.step
        } else {
          state.items[item.id] = {
            type: CLOZE,
            id: item.id,
            status: item.status,
            step: item.step,
            lastContent: {},
          }
        }
      }
    },

    buildQualityMetrics(state: StateLoaded, action: PayloadAction<string>) {
      state.items.root.qualityMetrics = buildQualityMetrics(action.payload)
    },

    updateFromEditor(state: StateLoaded, action: PayloadAction<{ entities: any; html: string }>) {
      const { entities, html } = action.payload

      state.items.root.lastContent.stimulus = html
      state.items.root.lastContent.cloze_ids = entities.map((i) => i.id)
      state.items.root.qualityMetrics = buildQualityMetrics(html)

      for (const { id, text, index } of entities) {
        const item = state.items[id] as ItemCloze

        if (!item) {
          state.items[id] = {
            id,
            type: CLOZE,
            status: 'NOT_STARTED',
            step: 'AI',
            index,
            lastContent: {
              options: [{ value: text, status: AnswerStatus.key }],
            },
          }
        } else {
          const answer = item.lastContent?.options?.find((i) => i.status === AnswerStatus.key)

          if (answer && item.lastContent.options) {
            answer.value = text
          }

          item.index = index
        }
      }

      return state
    },

    discardItems(state: StateLoaded) {
      for (const item of Object.values(state.items)) {
        if (item.finalContent) item.lastContent = item.finalContent
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.fetchItemSet.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(thunkActions.fetchItemSet.rejected, (state, action) => {
        state.fatalError = action.error.message
      })

    builder.addCase(thunkActions.fetchItemContent.fulfilled, (state: StateLoaded, action) => {
      const isRoot = state.items.root.id === action.payload.id

      if (isRoot) {
        Object.assign(state.items.root, action.payload)
      } else {
        Object.assign(state.items[action.payload.id], action.payload)
      }
    })

    builder.addCase(thunkActions.regenerateItem.pending, (state: StateLoaded, action) => {
      const id = action.meta.arg.itemId
      state.items[id].status = 'NOT_STARTED'
    })

    builder
      .addCase(thunkActions.saveItem.pending, (state: StateLoaded) => {
        state.savingItem = true
      })
      .addCase(thunkActions.saveItem.fulfilled, (state: StateLoaded) => {
        state.savingItem = false
      })
      .addCase(thunkActions.saveItem.rejected, (state: StateLoaded) => {
        state.savingItem = false
      })

    builder.addCase(thunkActions.regenerateRoot.pending, (state: StateLoaded) => {
      const { root } = state.items
      root.lastContent.stimulus = undefined
      root.lastContent.cloze_ids = []
      root.qualityMetrics = undefined
      root.status = 'NOT_STARTED'
      root.step = 'AI'
    })

    // clear the cloze state when enter the page
    builder.addCase(routeEnter, (state: State, action) => {
      if (action.payload.path === '/author/cloze/:itemId') {
        Object.assign(state, getInitialState())
      }
    })
  },
})

export default slice
export const { actions } = slice
