import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@apollo/client';
import { get } from 'lodash';

import { makeStyles } from '@mui/styles';

import FTGProgress from '@core/components/FTGProgress';

import Steps from '@author/components/Item/Steps';
import {
  aiProgressSteps,
  AI,
  AI_CREATE_STEM,
  AI_REMAINING_ANSWERS,
  AI_SUGGEST_ANSWERS,
  FAILED,
  DONE,
} from '@author/constants/progressSteps';
import { getOnCompleteTimeout } from '@core/utils/random';

import { GET_ITEM_UPDATES } from '@author/queries';
import { useUserRole } from '@containers/main/main-utils';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .progress-label': {
      fontSize: '1.25rem',
      fontWeight: 500,
      marginTop: theme.spacing(2),
    },
    '& .progress-bar': {
      width: '50%',
      backgroundColor: theme.palette.grey['300'],
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(3),
    },
  },
}));

/**
 * Displays item ai state progress
 */
const ItemAiProgress = ({ itemId, onCompleted, onFailed, showStepsProgress }) => {
  const { role } = useUserRole();
  const [steps, setSteps] = useState([]);
  const timeoutRef = useRef();
  const failedTimeoutRef = useRef();

  const { data } = useSubscription(GET_ITEM_UPDATES, {
    variables: { itemId },
    context: { role },
    skip: !itemId,
  });

  useEffect(() => {
    if (data?.item_by_pk) {
      const { step, status, aiResponses } = data.item_by_pk;

      const progressStatus = get(aiProgressSteps, [step, status]);
      const aiSteps = [AI, AI_CREATE_STEM, AI_SUGGEST_ANSWERS, AI_REMAINING_ANSWERS];

      if (progressStatus) {
        setSteps([...steps, progressStatus]);
      }

      if (aiSteps.includes(step) && status === FAILED) {
        const failedTimeout = getOnCompleteTimeout();
        failedTimeoutRef.current = setTimeout(() => {
          onFailed(aiResponses.length === 1 ? aiResponses[0].error : null);
        }, failedTimeout);
        return () => clearTimeout(failedTimeoutRef.current);
      }

      if ([AI, AI_REMAINING_ANSWERS].includes(step) && status === DONE) {
        const timeout = getOnCompleteTimeout();
        timeoutRef.current = setTimeout(() => {
          onCompleted();
        }, timeout);
        return () => clearTimeout(timeoutRef.current);
      }
    }
    return undefined;
  }, [data]);

  const classes = useStyles();
  const lastStepIndex = steps.length - 1;

  return (
    <div className={classes.container} data-testid="item-generate-progress">
      <div className="progress-label">Generate AI Engine at work...</div>
      <FTGProgress
        classes={{ linearProgress: 'progress-bar' }}
        aria-label="Item generation progress"
      />
      {showStepsProgress && <Steps steps={steps} stepIndex={lastStepIndex} />}
    </div>
  );
};

ItemAiProgress.propTypes = {
  itemId: PropTypes.string,
  /** callback for when progress is completed */
  onCompleted: PropTypes.func.isRequired,
  /** callback for when progress is failed */
  onFailed: PropTypes.func.isRequired,
  /** Display steps progress */
  showStepsProgress: PropTypes.bool,
};

ItemAiProgress.defaultProps = {
  itemId: null,
  showStepsProgress: true,
};

export default ItemAiProgress;
