import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { actions } from '@pages/author/author-slice'
import { getSelectablePartsStatus } from '@author/utils/project'
import { useAppDispatch, useAppSelector } from '@core/store'
import { CustomDragContext, CustomDraggable, CustomDroppable } from '@core/components/CustomDnD'
import ContentOption from './ContentOption'

const useStyles = makeStyles((theme) => ({
  isDragging: {
    background: theme.palette.background.paleGrey,
  },
  isDraggingOver: {
    background: theme.palette.background.blueGrey,
  },
}))
export interface QuestionPartsProps {
  isDragEnabled: boolean
}

const QuestionParts = ({ isDragEnabled }: QuestionPartsProps) => {
  const clasess = useStyles()
  const selectableStatuses = getSelectablePartsStatus()
  const dispatch = useAppDispatch()
  const { itemId: selectedItemId } = useParams<{ itemId: string }>()
  const generatedItemId = useAppSelector((state) => state.author.generatedItemId)
  const itemId = selectedItemId || generatedItemId
  const finalParts = useAppSelector((state) => state.author.content?.parts)

  const changeQuestionsParts = (index, name, value) => {
    dispatch(actions.setContent({ index, name, part: value }))
  }

  const onDragEnd = (newOptions) => {
    dispatch(actions.setContent({ parts: newOptions }))
  }

  return (
    <Box>
      <CustomDragContext items={finalParts} onDragEndCallback={onDragEnd}>
        <CustomDroppable className={clasess.isDraggingOver}>
          {finalParts?.map((_, index) => {
            const dragId = `dragId-${index}`
            return (
              <Box key={index}>
                <CustomDraggable
                  key={dragId}
                  id={dragId}
                  index={index}
                  disabled={!isDragEnabled}
                  className={clasess.isDragging}
                >
                  <ContentOption
                    key={`${itemId}-part-${index}`}
                    isDragEnabled={isDragEnabled}
                    index={index}
                    selectableStatuses={selectableStatuses}
                    setValue={changeQuestionsParts}
                  />
                </CustomDraggable>
              </Box>
            )
          })}
        </CustomDroppable>
      </CustomDragContext>
    </Box>
  )
}
export default QuestionParts
