import React, { useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import * as sessionStorage from '@core/utils/session-storage'

type Value = Record<string, any>

type Action<T = Value | boolean> = (values: T) => Promise<any>

interface Options {
  hideKey?: string
  initialValues?: Value
}

interface BaseProps {
  title: string
  confirmText?: string
  cancelText?: string
  loading?: boolean
}

interface State<T = boolean> {
  resolvePromise?: (result: T) => void
  isOpen: boolean
  loading?: boolean
  action?: Action<T>
  options?: Options
  initialValues: Value
}

type MockEvent = (e: { target: object }) => void

export interface FormComponent<T = Value, Props = Record<string, any>> {
  onChange: MockEvent
  values: T
  formProps: Props
  options?: Options
}

interface RenderState extends Omit<State, 'resolvePromise' | 'action'> {
  onResolve: (result: boolean | Value) => void
}

interface FormProps extends Omit<BaseProps, 'message'> {
  id: string
  state: RenderState
  RenderContent?: React.FC<FormComponent>
  content?: React.ReactNode
  formProps?: Record<string, any>
  validate?: (values: Value) => string | null
}

export function useConfirmDialog<T = boolean>() {
  const mounted = React.useRef<boolean>(true)
  const [state, setState] = useState<State<T>>({ isOpen: false, initialValues: {} })

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const onResolve = useCallback(
    async (result: T) => {
      if (state.action && result) {
        try {
          setState({ ...state, loading: true })
          await state.action(result)
          // instant close after success, so the loading effect keep on until it's disappear
          setState({ ...state, isOpen: false })
        } catch (error) {
          setState({ ...state, loading: false })
        }
      }

      if (state.resolvePromise) {
        state.resolvePromise(result)
      }

      if (!result && state.action && state.initialValues.callFailed) {
        state.action(result)
      }
      setState((s) => ({ ...s, isOpen: false }))

      // some time for the animation to finish and avoid flicking the UI
      setTimeout(() => {
        if (mounted.current) {
          setState({ isOpen: false, initialValues: {} })
        }
      }, 300)
    },
    [state.action, state.resolvePromise]
  )

  const open = useCallback(
    async (action?: Action<T>, options?: Options) => {
      const initialValues: Value = options?.initialValues || {}

      if (options?.hideKey) {
        const hideValue = sessionStorage.get(options.hideKey, false)

        if (hideValue) {
          return action ? action(initialValues as T) : Promise.resolve(true)
        }

        initialValues[options.hideKey] = false
      }

      return new Promise((resolve) => {
        const resolvePromise = (result: T) => {
          resolve(result)
        }
        setState({ resolvePromise, isOpen: true, action, options, initialValues })
      })
    },
    [onResolve]
  )

  return {
    ...state,
    onResolve,
    open,
  }
}

function ConfirmDialog(props: FormProps) {
  const {
    title,
    id,
    RenderContent,
    content,
    formProps,
    validate,
    confirmText,
    cancelText,
    state: { isOpen, onResolve, initialValues, options, loading },
  } = props

  const [state, setState] = useState(initialValues)

  useEffect(() => {
    if (isOpen) setState(initialValues)
  }, [isOpen])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (options?.hideKey && state[options.hideKey]) {
      sessionStorage.set(options.hideKey, true)
    }

    onResolve(state)
  }

  const error = validate && validate(state)

  const innerContent = RenderContent ? (
    <RenderContent
      onChange={handleChange}
      values={state}
      formProps={formProps || {}}
      options={options}
    />
  ) : (
    content
  )

  return (
    <Dialog
      open={isOpen}
      onClose={() => !loading && onResolve(false)}
      maxWidth="xs"
      aria-label={`confirm dialog for ${title}`}
      sx={{ '& .MuiPaper-root': { maxWidth: 480, px: 1.5, py: 2 } }}
      data-testid={`dialog-${id}`}
    >
      <DialogTitle>
        <Box typography="h3" component="span">
          {title}
        </Box>
      </DialogTitle>

      <DialogContent>
        <form id={id} onSubmit={handleSubmit}>
          {innerContent}
        </form>
      </DialogContent>

      <DialogActions>
        {options?.hideKey && (
          <FormControlLabel
            sx={{ display: 'flex', flex: 1, pl: 3 }}
            control={
              <Checkbox
                name={options.hideKey}
                inputProps={{ form: id }}
                onChange={handleChange}
                size="small"
              />
            }
            label={<Typography variant="caption">Don&apos;t show again</Typography>}
          />
        )}

        <Button color="secondary" onClick={() => onResolve(false)} disabled={loading}>
          {cancelText || 'Cancel'}
        </Button>
        <LoadingButton
          color="secondary"
          loading={loading}
          variant="contained"
          type="submit"
          form={id}
          disabled={Boolean(error) || loading}
        >
          {confirmText || 'Confirm'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ConfirmDialog)
