import React from 'react'

import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useAuth0 } from '@auth0/auth0-react'
import FormHelperText from '@mui/material/FormHelperText'
import { Box } from '@mui/material'

import Logo from '@core/components/Logo'

const useStyles = makeStyles((theme) => ({
  signInContainer: {
    width: '80%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& button': {
      marginTop: theme.spacing(1),
    },
  },
  logo: {
    marginBottom: theme.spacing(8),
    '& svg': {
      height: 96,
    },
  },
  errorMessagecontainer: {
    maxWidth: '480px',
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    fontSize: '1rem',
    fontWeight: '500',
    color: theme.palette.text.slateBlue,
  },
}))

export default function SignInAuth0({ error }: { error: { message: string } | null }) {
  const classes = useStyles()
  const { loginWithRedirect } = useAuth0()

  return (
    <div className={classes.signInContainer}>
      <Logo className={classes.logo} />
      <Typography variant="h2" data-testid="sign-in-header">
        Sign In
      </Typography>
      <div>
        <Button
          onClick={() => loginWithRedirect()}
          variant="contained"
          color="secondary"
          disableElevation
        >
          Sign In with Auth0
        </Button>
        <Box className={classes.errorMessagecontainer}>
          <FormHelperText className={classes.errorMessage}>{error?.message}</FormHelperText>
        </Box>
      </div>
    </div>
  )
}
