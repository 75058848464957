/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { generatePath } from 'react-router-dom'

import ErrorHandler from '@core/api/ErrorHandler'
import { ITEM_SET, CLOZE } from '@author/constants/questionsTypes'
import { AUTHOR_ITEM_ROUTE } from '@core/constants/routes'
import { selectUserRole } from '@containers/main/main-utils'
import { actions as commentsActions } from '@containers/comments/comments-slice'

import * as queries from './cloze-queries'
import { ItemMap, ItemRoot, Item, StateLoaded } from './author-cloze-types'

/**
 * Fetch initial item data
 */
export const fetchItemSet = createAsyncThunk<Partial<StateLoaded>, string>(
  'author/fetchItemSet',
  async (id, { extra, getState, dispatch }) => {
    try {
      const { role } = selectUserRole(getState())

      const { data } = await extra.client.query({
        fetchPolicy: 'network-only',
        query: queries.GET_ITEM_SET,
        variables: { id },
        context: { role },
      })

      if (!data.item) {
        extra.history.push('/404')
        return { notFound: true }
      }

      const { children, ...root } = data.item

      if (root.aiModel.type !== ITEM_SET) {
        const path = generatePath(AUTHOR_ITEM_ROUTE, { itemId: id })
        extra.history.replace(path)
        return {}
      }

      const qualityMetrics = root.qualityMetrics || []

      const { cloze_ids: clozeIds = [], mcq_ids: mcqIds = [] } = root.lastContent

      const items: ItemMap = { root: { ...root, qualityMetrics } }
      let startIndex = 1

      for (const item of children) {
        items[item.id] = item
      }

      for (const itemId of [...mcqIds, ...clozeIds]) {
        const item = items[itemId]

        if (!item) {
          console.error('item not found', itemId)
          continue
        }

        items[itemId] = { ...item, index: startIndex }
        startIndex += 1
      }

      if (items) {
        // @ts-ignore
        dispatch(commentsActions.set({ itemId: items.root.id, comments: items.root.comments }))
      }

      return { initialized: true, items }
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error while fetching the item. ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

/**
 * Generate answers for item
 */
export const regenerateItem = createAsyncThunk<void, { itemId: string; clearStem?: boolean }>(
  'author/regenerateItem',
  async ({ itemId, clearStem = false }, { extra }) => {
    try {
      await extra.client.mutate({
        mutation: queries.REGENERATE_ITEM,
        variables: { itemId, clearStem },
      })
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error while regenerating ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

/**
 * Generate answers for item
 */
interface GenerateParams {
  itemId?: string
  empty?: boolean
  aiModelId?: string
  rootId?: string
  flavors?: string[]
}

export const generateItem = createAsyncThunk(
  'author/generateItem',
  async ({ empty, itemId }: GenerateParams, { extra, getState }) => {
    const { items } = getState().authorCloze
    if (!items || !items.root) return

    const variables: GenerateParams = { empty, itemId, flavors: items.root.selectedFlavors }
    const clozeItem = Object.values(items).find((i) => i.type === CLOZE)

    variables.aiModelId = clozeItem?.aiModelId

    if (empty) {
      variables.rootId = items.root.id
    }

    try {
      const { data } = await extra.client.mutate({
        mutation: queries.GENERATE_ITEM,
        variables,
      })

      return data.generate
    } catch (error) {
      console.log('generateItem error ->', error)
      ErrorHandler(error)

      extra.snack.add({
        message: `Error while generating ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

/**
 * Save/deliver an item
 */
export const saveItem = createAsyncThunk<
  ItemRoot,
  { itemId: string; projectId: string; projectName: string }
>('author/saveItem', async ({ itemId, projectId, projectName }, { extra, getState, dispatch }) => {
  try {
    const { data } = await extra.client.mutate({
      mutation: queries.SAVE_ITEM,
      variables: { id: itemId, projectId },
    })

    extra.snack.add({
      message: `Your item has been saved to the project ${
        projectName || getState().authorCloze.items?.root.project?.name
      } in Deliver`,
      severity: 'success',
    })

    dispatch(fetchItemSet(itemId))

    return data.item
  } catch (error) {
    ErrorHandler(error)

    extra.snack.add({
      message: `Error saving item ${error.message}`,
      severity: 'error',
    })

    throw error
  }
})

export const updateRootContent = createAsyncThunk<void, { html: string; emptyItemId: string }>(
  'author/updateRootContent',
  async ({ html, emptyItemId }, { extra, getState }) => {
    try {
      const { root } = getState().authorCloze.items as ItemMap

      const ids = new Set(root.lastContent.cloze_ids)
      ids.add(emptyItemId)

      await extra.client.mutate({
        mutation: queries.UPDATE_ITEM_ROOT,
        variables: {
          id: root.id,
          content: { ...root.lastContent, stimulus: html, cloze_ids: Array.from(ids) },
          qualityMetrics: root.qualityMetrics || [],
        },
      })
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error updating item: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

/**
 * Update draft items
 */
export const updateItemsContent = createAsyncThunk<void, string[]>(
  'author/updateItemsContent',
  async (keys, { extra, getState }) => {
    const itemsMap = getState().authorCloze.items as ItemMap

    if (!itemsMap || !keys.length) return
    const promises: Promise<any>[] = []

    for (const key of keys) {
      if (key === 'root') {
        const item = itemsMap.root

        promises.push(
          extra.client.mutate({
            mutation: queries.UPDATE_ITEM_ROOT,
            variables: {
              id: item.id,
              content: item.lastContent,
              qualityMetrics: item.qualityMetrics || [],
            },
          })
        )
      } else {
        const item = itemsMap[key]

        promises.push(
          extra.client.mutate({
            mutation: queries.UPDATE_ITEM_CONTENT,
            variables: {
              id: item.id,
              content: item.lastContent,
            },
          })
        )
      }
    }

    try {
      await Promise.all(promises)
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error while updating an item ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

/**
 * Regenerate root question
 */
export const regenerateRoot = createAsyncThunk(
  'author/regenerateRoot',
  async (_, { extra, getState }) => {
    try {
      const { items } = getState().authorCloze
      if (!items) return
      const clozeItem = Object.values(items).find((i) => i.type === CLOZE)

      await extra.client.mutate({
        mutation: queries.REGENERATE_ROOT_QUESTION,
        variables: { itemId: clozeItem?.id, aiModelId: clozeItem?.aiModelId },
      })
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error while regenerating the response letter: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

/**
 * Refetch an item content after some status change
 */

/**
 * Refetch an item root content after some status change
 * If the response do not include the stimulus, wait some time and try again
 */
interface FetchItemContentParams {
  isRoot: boolean
  id: string
}

const wait = (t: number) => new Promise((r) => setTimeout(r, t))

async function tryFetchRoot(makeRequest, n = 1) {
  console.log('try fetch ->', n)
  if (n > 20) {
    throw new Error('Could not fetch root stimulus')
  }

  const { data } = await makeRequest()

  if (!data.item.lastContent.stimulus) {
    await wait(200 * n)
    return tryFetchRoot(makeRequest, n + 1)
  }

  return data.item
}

export const fetchItemContent = createAsyncThunk<Item, FetchItemContentParams>(
  'author/fetchItemContent',
  async ({ isRoot, id }, { extra, getState }) => {
    const { role } = selectUserRole(getState())
    try {
      const makeRequest = () =>
        extra.client.query({
          fetchPolicy: 'network-only',
          query: queries.GET_ITEM_CONTENT,
          variables: { id },
          context: { role },
        })

      if (isRoot) return tryFetchRoot(makeRequest)

      const { data } = await makeRequest()

      return data.item
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error while fetching an item ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)
