import React, { useCallback, useState, useEffect } from 'react'
import { TextField, Typography, Box, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import FTGTooltip from '@core/components/FTGTooltip'
import { useAppDispatch, useAppSelector } from '@core/store'
import { AUTHOR_ROUTE } from '@core/constants/routes'
import { actions } from '@pages/author/author-slice'
import * as thunkActions from '@pages/author/author-thunk'
import * as authorSelectors from '@pages/author/author-selectors'
import * as selectors from './selectors/generate-items-selectors'

const ITEMS_NUMBER = 10

const GenerateItems = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const selectedModelId = useAppSelector((state) => state.author.selectedModel?.id)
  const disableGenerateButton = useAppSelector(selectors.disableGenerate)
  const disableButtonReason = useAppSelector(selectors.disableGenerateReason)
  const isItemSet = useAppSelector(authorSelectors.isItemSet)
  const isStoryItem = useAppSelector(authorSelectors.isStoryItem)
  const isHotText = useAppSelector(authorSelectors.isHotText)

  const [numberOfItems, setNumberOfItems] = useState(ITEMS_NUMBER)

  const handleOnChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const num = parseInt(event.target.value, ITEMS_NUMBER)
    setNumberOfItems(num > ITEMS_NUMBER ? ITEMS_NUMBER : num)
  }, [])

  useEffect(() => {
    if (isStoryItem || isItemSet || isHotText) setNumberOfItems(1)
    else setNumberOfItems(ITEMS_NUMBER)
  }, [isStoryItem, isItemSet, isHotText, selectedModelId])

  const handleGenerate = () => {
    if (isStoryItem) {
      history.push(AUTHOR_ROUTE)
      return dispatch(actions.set({ isStoryModal: true }))
    }
    return dispatch(thunkActions.generateItem(numberOfItems))
  }

  return (
    <Box>
      <Typography fontWeight={500}>Generate Items</Typography>
      <Box display="flex" justifyContent="space-between">
        <Box component="label" htmlFor="number-of-items-input">
          Number of Items to Generate
        </Box>
        <TextField
          autoFocus
          value={numberOfItems}
          sx={{
            position: 'relative',
            borderRadius: 0.8,
            pl: 1,
            pt: 0.5,
            bgcolor: 'background.button',
            width: 56,
            height: 32,
          }}
          size="small"
          variant="standard"
          inputProps={{
            'data-testid': 'number-of-items-input',
            id: 'number-of-items-input',
            type: 'number',
            maxLength: 2,
            step: 1,
            min: 1,
            max: ITEMS_NUMBER,
            style: {
              fontSize: 14,
            },
          }}
          required
          onChange={handleOnChange}
          disabled={disableGenerateButton || isStoryItem || isItemSet || isHotText}
        />
      </Box>
      <FTGTooltip
        title={disableButtonReason}
        placement="left"
        withMargin
        disabled={!disableGenerateButton}
      >
        <Box>
          <Button
            onClick={handleGenerate}
            fullWidth
            variant="contained"
            color="secondary"
            aria-label="Run Generate"
            sx={{
              fontSize: 14,
              mt: 3,
            }}
            disabled={disableGenerateButton || !numberOfItems}
          >
            Run Generate
          </Button>
        </Box>
      </FTGTooltip>
    </Box>
  )
}

export default GenerateItems
