import React, { useState, useMemo, useRef } from 'react'
import { Box, SxProps, Button, ClickAwayListener, Alert } from '@mui/material'
import { getVisibleSelectionRect } from 'draft-js'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import Modal from '@core/components/Modal'
import { useAppDispatch, useAppSelector } from '@core/store'

import { actions } from './references-slice'
import { ReferencePopper } from './references-popper'
import { Reference } from './references-types'

const styles: Record<string, SxProps> = {
  content: {
    px: 20,
    outline: 'none',
    pb: 50,
  },
  arrowLeft: {
    position: 'absolute',
    flexShrink: 0,
    left: 0,
    top: '0%',
  },
  arrowRight: {
    position: 'absolute',
    flexShrink: 0,
    right: 0,
    top: '0%',
  },
}

type onUseSelection = (value: string) => void

interface Props {
  items: Reference[]
  selectedCitationId: string | null
  open: boolean
  onClose: () => void
  onSelect: (value: string) => void
  onUseSelection: onUseSelection
}

interface State {
  open: boolean
  content: string
  bounds: HTMLElement | null
}

const initialState = { open: false, bounds: null, content: '' }

function Content({ item, onUseSelection }: { item: Reference; onUseSelection: onUseSelection }) {
  const ref = useRef<HTMLDivElement>()
  const [state, setState] = useState<State>(initialState)

  const paragraphs = useMemo(() => {
    return [...item.siblings].sort((a, b) => a.order - b.order)
  }, [item.siblings])

  const handleSelectChange = () => {
    const selection = document.getSelection()?.toString().trim() || ''

    if (selection?.length < 3) {
      setState({ ...state, open: false })
      return
    }

    const bounding = getVisibleSelectionRect(window)
    const getBoundingClientRect = () => bounding

    if (!bounding) {
      setState({ ...state, open: false })
      return
    }

    setState({
      bounds: { getBoundingClientRect } as HTMLElement,
      open: true,
      content: selection,
    })
  }

  // prevent the contentEditable to change
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.ctrlKey && event.key === 'c') {
      return ''
    }

    // allow arrows to select using keyboard
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
      return ''
    }

    event.preventDefault()
    event.stopPropagation()
  }

  const handleClose = () => {
    if (!state.open) return
    setState({ ...state, open: false })
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Box
          sx={styles.content}
          ref={ref}
          onSelect={handleSelectChange}
          onKeyDown={handleKeyDown}
          contentEditable
          suppressContentEditableWarning
          aria-label="references-content"
          spellCheck="false"
          onDragOver={(e) => e.preventDefault()}
        >
          {paragraphs.map((paragraph) => {
            const isMatch = item.id === paragraph.id
            return (
              <Box
                component="p"
                key={paragraph.id}
                aria-label="reference-paragraph"
                sx={{
                  mb: 2,
                  bgcolor: (theme) =>
                    isMatch ? theme.palette.background.successGreen : 'transparent',
                  outline: '5px solid',
                  outlineColor: (theme) =>
                    isMatch ? theme.palette.background.successGreen : 'transparent',
                }}
              >
                {paragraph.description}
              </Box>
            )
          })}
        </Box>
      </ClickAwayListener>
      <ReferencePopper
        content={state.content}
        open={state.open}
        anchorEl={state.bounds}
        onSubmit={(value) => value && onUseSelection(value)}
        citation={item.citation}
      />
    </>
  )
}

export default function ReferencesModal({
  items,
  selectedCitationId,
  open,
  onClose,
  onSelect,
  onUseSelection,
}: Props) {
  const dispatch = useAppDispatch()
  const showHelperInfo = useAppSelector((state) => state.references.showHelperInfo)
  const selectedCitationIndex: number | undefined = useMemo(
    () => items.findIndex((citation) => citation.id === selectedCitationId),
    [items, selectedCitationId]
  )
  const selected = items[selectedCitationIndex]
  const citation = items[selectedCitationIndex] && items[selectedCitationIndex].citation

  const handleCloseInfo = () => {
    dispatch(actions.set({ showHelperInfo: false }))
  }

  if (!citation || !selected) return null

  return (
    <Modal open={open} onClose={onClose}>
      <Box px={20}>
        <Box typography="h2" mt={6} mb={4}>
          References
        </Box>
      </Box>

      <Box px={20} mb={3} position="relative">
        <Box sx={styles.arrowLeft}>
          <Button
            disabled={selectedCitationIndex === 0}
            onClick={() => onSelect(items[selectedCitationIndex - 1].id)}
          >
            <ArrowBackIcon sx={{ mr: 1 }} /> Previous
          </Button>
        </Box>

        <Box typography="body2" fontWeight="bold" minHeight={37} display="flex" alignItems="center">
          {citation}
        </Box>

        <Box sx={styles.arrowRight}>
          <Button
            disabled={selectedCitationIndex === items.length - 1}
            onClick={() => onSelect(items[selectedCitationIndex + 1].id)}
          >
            Next <ArrowForwardIcon sx={{ ml: 1 }} />
          </Button>
        </Box>
      </Box>

      {showHelperInfo && (
        <Box px={20}>
          <Alert
            onClose={handleCloseInfo}
            closeText="Close info"
            severity="success"
            color="info"
            sx={{ mb: 2 }}
          >
            Select text below to use as a reference
          </Alert>
        </Box>
      )}

      <Content item={selected} onUseSelection={onUseSelection} />
    </Modal>
  )
}
