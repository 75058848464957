export const LOGIN_ROUTE = '/'
export const AUTHOR_ROUTE = '/author'
export const AUTHOR_ITEM_ROUTE = '/author/item/:itemId'
export const AUTHOR_CLOZE_ROUTE = '/author/cloze/:itemId'
export const DELIVER_ROUTE = '/deliver'
export const DELIVER_PROJECT_ROUTE = '/deliver/project/:projectId'
export const DELIVER_DRAFT = '/deliver/project/draft-items'
export const USERS_ROUTE = '/users'
export const NOT_FOUND = '/404'

export const routeMap = {
  LOGIN_ROUTE,
  AUTHOR_ROUTE,
  AUTHOR_ITEM_ROUTE,
  AUTHOR_CLOZE_ROUTE,
  DELIVER_ROUTE,
  DELIVER_PROJECT_ROUTE,
  DELIVER_DRAFT,
  NOT_FOUND,
  USERS_ROUTE,
} as const

export type Route = typeof routeMap[keyof typeof routeMap]
