export const api = {
  defaultError: 'Sorry, an error occurred. Please try again later.',
  storyAddBlockError:
    'Sorry, an error occurred and previous version was restored. Please try again later',
  completeStoryError: 'Story completion failed. Please add at least 5 blocks before trying again.',
}

export const aiResponseErrorMessage = {
  qualityCleaners: {
    errorType: [
      'OverlapException',
      'ExcludedTextException',
      'UnsafeTextException',
      'MinimumWordsException',
      'InsufficientPartsException',
      'CleanerException',
    ],
    message:
      'Sorry. The item(s) generation has failed due to validation errors. ' +
      'Please try regenerating the item(s).',
  },
  parsingErrors: {
    errorType: ['ParseException'],
    message:
      'Sorry. The item(s) generation has failed due to parsing errors. ' +
      'Please try regenerating the item(s).',
  },
  aiConnectionErrors: {
    errorType: ['OpenAiException'],
    message:
      'Sorry. The item(s) generation has failed due to connectivity errors. ' +
      'Please try regenerating the item(s).',
  },
  defaultError: {
    message: 'Sorry, an error occurred. Please try again later.',
  },
}

export const ErrorMessage = {
  api,
  aiResponseErrorMessage,
  getAIResponseErrorMessage: (error) => {
    if (aiResponseErrorMessage.qualityCleaners.errorType.find((err) => err === error)) {
      return aiResponseErrorMessage.qualityCleaners.message
    }

    if (aiResponseErrorMessage.parsingErrors.errorType.find((err) => err === error)) {
      return aiResponseErrorMessage.parsingErrors.message
    }

    if (aiResponseErrorMessage.aiConnectionErrors.errorType.find((err) => err === error)) {
      return aiResponseErrorMessage.aiConnectionErrors.message
    }

    return aiResponseErrorMessage.defaultError.message
  },
}
