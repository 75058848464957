import React, { memo, useEffect, useState } from 'react'
import { EditorState, Editor, convertToRaw } from 'draft-js'
import { AccordionSummary, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { debounce } from 'lodash'

import { useAppDispatch, useAppSelector } from '@core/store'
import { plugin } from '@pages/author-cloze/editor/cloze-editor'
import { fromHTML, decoratorsNoBorder, toHTML } from '@pages/author-cloze/editor/cloze-html'
import { actions } from '@pages/author/author-slice'

import { PASSAGE } from './constants'
import Popper from './passage-popper'

interface PassageArrowProps {
  show: boolean
  onClick: () => void
}

export const PassageArrow = ({ show, onClick }: PassageArrowProps) => {
  const content = show ? 'Hide Stimulus' : 'Show Stimulus'
  return (
    <AccordionSummary aria-label={content} expandIcon={<ExpandMoreIcon />} onClick={onClick}>
      <Typography variant="subtitle2" align="center">
        {content}
      </Typography>
    </AccordionSummary>
  )
}

const updateEditorState = (value = '') => {
  return EditorState.createWithContent(fromHTML(value), decoratorsNoBorder)
}

const Passage = () => {
  const name = PASSAGE
  const passage = useAppSelector((state) => state.author.content?.passage)
  const customPassage = useAppSelector((state) => state.author.content?.custom_passage)
  const discard = useAppSelector((state) => state.author.loading.discard)
  const dispatch = useAppDispatch()

  const answers = useAppSelector((state) => state.author.content?.answers)
  const contentText = customPassage || passage

  const [editorState, setEditorState] = useState(updateEditorState(contentText))

  useEffect(() => {
    setEditorState(updateEditorState(contentText))
  }, [discard])

  const handleSave = (newEditor: EditorState) => {
    const raw = convertToRaw(newEditor.getCurrentContent())
    const entities =
      raw?.blocks.flatMap((block) =>
        block.entityRanges.map((item) => ({
          id: raw.entityMap[item.key].data.id,
          status: 'CORRECT',
          value: block.text.slice(item.offset, item.offset + item.length),
        }))
      ) || []

    dispatch(actions.setEditorContent({ options: entities, html: toHTML(raw) }))
  }

  const debouncedSave = React.useMemo(
    () =>
      debounce((newEditor) => {
        handleSave(newEditor)
      }, 300),
    []
  )

  const handleChange = (newEditor: EditorState) => {
    setEditorState(newEditor)
    debouncedSave(newEditor)
  }

  if (!contentText) return null

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={1} mb={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" data-testid={`subtitle-${name}`} fontSize={16} fontWeight="bold">
            Passage:
          </Typography>
        </Box>
      </Box>

      <Editor
        editorState={editorState}
        onChange={handleChange}
        stripPastedStyles
        handleKeyCommand={plugin.handleKeyCommand}
        handleBeforeInput={plugin.handleBeforeInput}
        handlePastedText={plugin.handlePastedText}
        keyBindingFn={plugin.keyBindingFn}
        spellCheck
      />
      <Popper getEditorState={() => editorState} onChange={handleChange} />

      <Box display="flex" alignItems="center" mt={4}>
        <Box>
          <Box typography="h4" mr={2}>
            Keys:
          </Box>

          {answers?.map((x) => {
            return (
              <Box display="flex" mt={2}>
                {x.value}
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default memo(Passage)
