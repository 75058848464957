import React, { useEffect } from 'react'
import { Box, Button, Popper, Fade, Paper } from '@mui/material'

type ReferencePopperProps = {
  open: boolean
  content: string
  citation: string
  anchorEl: HTMLElement | null
  onSubmit: (result: false | string) => void
}

const LIMIT = 2048

export function ReferencePopper(props: ReferencePopperProps) {
  const { open, content, citation, anchorEl, onSubmit } = props

  useEffect(() => {
    if (!anchorEl) return
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onSubmit(false)
      }
    }

    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [anchorEl])

  const modifiers = [
    {
      name: 'offset',
      options: { offset: [0, 7] },
    },
    {
      name: 'preventOverflow',
      options: { altAxis: true, altBoundary: true, padding: 10 },
    },
  ]

  const finalValue = `${content} - ${citation}`
  const limitReached = finalValue.length > LIMIT

  if (!anchorEl) return null

  return (
    // @ts-ignore
    <Popper
      disablePortal
      open={open}
      anchorEl={anchorEl}
      placement="top"
      modifiers={modifiers}
      transition
      sx={{ zIndex: '999' }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ p: 1.5, width: 300, userSelect: 'none' }} elevation={3}>
            <Box position="relative" mb={2} pt={1}>
              <Box typography="h5">Reference</Box>
              {limitReached && (
                <Box typography="caption" color="error" mt={1}>
                  {/* eslint-disable-next-line */}
                  You reached the limit of selection for the rationale.
                  <br />
                  Selection: {finalValue.length} - Limit: {LIMIT}
                  <br />
                  Please keep in mind that it includes the citation.
                </Box>
              )}
            </Box>

            <Box textAlign="right" mt={2}>
              <Button color="secondary" onClick={() => onSubmit(false)} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onSubmit(finalValue)}
                disabled={limitReached}
              >
                Use Selection
              </Button>
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}
