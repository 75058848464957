import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, SxProps } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

import { AUTHOR_ROUTE } from '@core/constants/routes'
import CanvasBg from '@core/components/CanvasBg'
import SignInAuth0 from './login-auth0'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const styles: Record<string, SxProps> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  halfContainer: {
    bgcolor: 'common.white',
    display: 'flex',
    flexGrow: 1,
    flexBasis: '50%',
    boxShadow: '0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22)',
  },
}

export default function Login() {
  const [error, setError] = useState<{ message: string } | null>(null)
  const { isLoading, isAuthenticated, error: auth0Error, logout } = useAuth0()
  const query = useQuery()
  const errorMessage = error
  const auth0ErrorParam = query.get('auth0ErrorParam')

  const formatTextURL = (text: string) => {
    const regex = / /g
    return text.replace(regex, '+')
  }

  useEffect(() => {
    if (auth0ErrorParam) {
      setError({
        message: auth0ErrorParam,
      })
    }
    if (auth0Error) {
      logout({
        logoutParams: {
          returnTo: `${window.location.origin}?auth0ErrorParam=${formatTextURL(
            auth0Error.message
          )}`,
        },
      })
    }
  }, [auth0Error, auth0ErrorParam])

  if (isAuthenticated) {
    return <Redirect to={AUTHOR_ROUTE} />
  }

  if (isLoading) {
    return <LinearProgress data-testid="loader" aria-label="loader" color="secondary" />
  }

  return (
    <>
      <CanvasBg />
      <Box className="animate__animated animate__fadeIn animate__delay-1s" sx={styles.container}>
        <Box sx={{ ...styles.halfContainer, backgroundColor: 'transparent' }}>
          {/* Placeholder for Register component */}
        </Box>
        <Box sx={styles.halfContainer}>
          <SignInAuth0 error={errorMessage} />
        </Box>
      </Box>
    </>
  )
}
