import React from 'react'
import { Link } from 'react-router-dom'
import GenerateLogo from 'src/assets/images/logo-ft-generate-dark.svg'
import { useAppDispatch } from '@core/store'
import { authorClear } from '@pages/author/author-slice'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  logoContainer: {
    textDecoration: 'none',
    height: 56,
    display: 'flex',
    alignItems: 'center',
    marginTop: -4,
    '& svg': {
      height: 48,
    },
  },
}))

const Logo = () => {
  const dispatch = useAppDispatch()
  const classes = useStyles()

  return (
    <Link to="/author" onClick={() => dispatch(authorClear())} className={classes.logoContainer}>
      <GenerateLogo data-testid="finetune-logo" />
    </Link>
  )
}

export default Logo
