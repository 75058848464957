import React from 'react'

import { SnackbarContextProvider } from './snackbarContext'
import * as Snack from './snack'

export * from './snackbarContext'

/**
 * Global context provider component combining all global context providers together
 */
export const ContextProviders = ({ children }: { children: React.ReactNode }) => (
  <Snack.SnackbarContextProvider>
    {/* @ts-ignore */}
    <SnackbarContextProvider>{children}</SnackbarContextProvider>
  </Snack.SnackbarContextProvider>
)
