/* eslint-disable no-alert */
import React, { useEffect, useState, Suspense, useRef, useMemo } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import * as auth0 from '@auth0/auth0-react'
import { LinearProgress } from '@mui/material'
import { useApolloClient } from '@apollo/client'

import ErrorHandler from '@core/api/ErrorHandler'
import { useAppSelector, useAppDispatch, useSubscription } from '@core/store'
import Raven from '@core/api/Raven'
import TIMEOUTS from '@core/constants/timeouts'
import { SUCCESS } from '@core/constants'
import { LOGIN_ROUTE } from '@core/constants/routes'

import * as queries from './main-queries'
import { actions, RouteMatch, Role, User } from './main-slice'
import { checkUserConfigure, claims } from './main-utils'
import UserError, { ErrorCode } from './main-errors'

const Progress = () => <LinearProgress data-testid="loader" aria-label="loader" color="secondary" />

declare global {
  interface Window {
    pendo: any
  }
}

interface ProtectedRouteProps extends RouteProps {
  computedMatch?: RouteMatch
  useAuth0?: typeof auth0.useAuth0
  windowLocation?: typeof window.location
}

// an infinite loop that waits 58 seconds
async function updateUserLastAccessLoop(
  client: ReturnType<typeof useApolloClient>,
  userId: string
) {
  await new Promise((r) => setTimeout(r, TIMEOUTS.LAST_ACCESS))

  try {
    if (!document.hidden) {
      const variables = { id: userId, lastAccess: new Date().toISOString() }
      await client.mutate({ mutation: queries.UPDATE_USER_LAST_ACCESS, variables })
    }

    updateUserLastAccessLoop(client, userId)
  } catch (error) {
    ErrorHandler(error)
  }
}

function useWatchChange<T>(value: T, callback: (newValue: T, oldValue: T) => void) {
  const ref = useRef(value)

  useEffect(() => {
    if (!value) return
    const prev = ref.current

    if (ref.current !== value) {
      ref.current = value
      if (prev) callback(value, prev)
    }
  }, [value, callback])
}

function ProtectedRoute(props: ProtectedRouteProps) {
  const { computedMatch, useAuth0 = auth0.useAuth0, windowLocation = window.location } = props
  const client = useApolloClient()
  const [errorKey, setErrorKey] = useState<ErrorCode | string>('')
  const dispatch = useAppDispatch()
  const main = useAppSelector((state) => state.main)
  const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0()
  const userRole = useRef<Role | null>(null)

  useWatchChange(main.customer?.id, () => {
    window.alert('Customer changed, please refresh the page')
    windowLocation.reload()
  })

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(actions.reset())
    }
  }, [isAuthenticated])

  const roleChanged = useMemo(() => {
    const role = main.user?.role
    if (!role) return false
    const prevRole = userRole.current
    userRole.current = role

    return prevRole && prevRole !== role
  }, [main.user?.role])

  const [shouldConfigure] = checkUserConfigure(user, main.user?.role)

  const setError = (code: ErrorCode) => {
    setErrorKey(code)
    if (user?.email) {
      dispatch(
        actions.set({ user: { email: user.email, role: userRole.current || 'User' } as User })
      )
    }
  }

  useEffect(() => {
    if (!shouldConfigure) return

    const configureUser = async () => {
      try {
        const role = user?.[claims]['x-hasura-default-role']
        const res = await client.mutate({
          mutation: queries.CONFIGURE_USER,
          context: { role },
        })

        const { response } = res.data.configure_user

        if (response.status !== SUCCESS) {
          throw response
        }

        // this creates a new token with the correct roles, so further navigation work properly
        await getAccessTokenSilently({ cacheMode: 'off' })

        // should trigger a full page load to build the token again
        // with the correct ftg_id inside
        if (roleChanged) {
          const canReload = window.confirm('Your user role has been changed, reload the page?')
          if (canReload) {
            windowLocation.reload()
          }
        } else {
          windowLocation.href = '/author'
        }
      } catch (error) {
        ErrorHandler(error)
        setError(error.message)
      }
    }

    if (shouldConfigure) configureUser()
  }, [shouldConfigure, roleChanged, user])

  useEffect(() => {
    if (!isAuthenticated || !user || !computedMatch) return

    const { sub } = user

    Raven.configureScope((scope) => {
      scope.setUser({ id: sub })
    })

    dispatch(actions.routeEnter(computedMatch))

    return () => {
      dispatch(actions.routeLeave(computedMatch))
    }
  }, [isAuthenticated, computedMatch?.url, user])

  useSubscription<queries.GET_USER_DATA>({
    query: queries.GET_USER,
    skip: isLoading || !isAuthenticated || shouldConfigure,
    onData(data) {
      const userAuth0 = user!
      // when the ftg_id inside the token do not match any record in our db
      if (!data.user.length) {
        setError('USER_NOT_FOUND_DB')
        return
      }

      const { customer, ...userData } = data.user[0]

      const internalAdmin = Boolean(
        userData.role === 'Admin' &&
          userData.isInternal &&
          user?.[claims]['x-hasura-allowed-roles'].includes('internal_admin')
      )

      if (!customer) {
        setError('USER_WITHOUT_CUSTOMER')
        return
      }

      return actions.set({
        user: {
          ...userData,
          name: userAuth0.name || '',
          picture: userAuth0.picture || '',
          internalAdmin,
        },
        customer,
      })
    },
    onError(error) {
      const isFirstTime = !main.user?.id

      if (isFirstTime) {
        setErrorKey(error?.message === 'undefined' ? 'FATAL_ERROR' : error?.message)
      }
    },
  })

  useEffect(() => {
    if (!user || !main.user?.id || errorKey || shouldConfigure) return

    const variables = {
      id: main.user.id,
      firstName: main.user.firstName || user.given_name || '',
      lastName: main.user.lastName || user.family_name || '',
      picture: user.picture || main.user.picture || '',
      lastAccess: new Date().toISOString(),
    }

    if (PENDO_ENABLED) {
      window.pendo.initialize({
        visitor: {
          id: main.user?.id,
          email: main.user?.email,
          full_name: main.user?.name,
          role: main.user?.role,
        },

        account: {
          id: main.customer?.id,
          name: main.customer?.name,
        },
      })
    }

    client.mutate({ mutation: queries.UPDATE_USER, variables })
    updateUserLastAccessLoop(client, main.user.id)
  }, [user?.name, main.user?.name, errorKey])

  if (!isLoading && !isAuthenticated) return <Redirect to={LOGIN_ROUTE} />

  if (errorKey) {
    return <UserError code={errorKey} email={user?.email} />
  }

  if (isLoading || !main.user || (shouldConfigure && !roleChanged)) {
    return <Progress />
  }

  return (
    <Suspense fallback={<Progress />}>
      <Route {...props} />
    </Suspense>
  )
}

export default ProtectedRoute
