/* eslint-disable max-len */

import { get } from 'lodash';
import { syllable } from 'syllable';
import pronouncing from '@core/utils/pronouncing';

import { SUGGEST_CORRECT, SUGGEST_DISTRACTOR } from '@author/constants/optionsStatus';

const SENTENCE_END_RE = /.*?(?:\.|!|\?|"|\u201d)(?:(?= [A-Z0-9])|$)/gm;

const isAlpha = (ch) => {
  return ch.match(/^[a-z]+$/i) !== null;
};

export const getTextMCQ = (content) => {
  const passage = get(content, 'passage', '');
  const parts = content?.has_generated_passage && passage ? [passage] : [];
  parts.push(content?.question);

  ['answers', 'parts'].map((partsName) => {
    const partName = get(content, partsName, []);
    partName.map((part) => {
      const { status } = part;
      if (status && ![SUGGEST_CORRECT, SUGGEST_DISTRACTOR].includes(status)) parts.push(part.value);
      return part;
    });
    return partsName;
  });
  return parts;
};

export const getStoryItemText = (content) => {
  const parts = [];
  content.blocks?.forEach(({ instances }) => {
    const { versions } = instances?.find((item) => item.active) || {};
    if (versions) {
      const newestVersion = versions[versions.length - 1];
      parts.push(newestVersion.paragraph);
    }
  });
  return parts;
};

export const getText = (content) => {
  const parts = content?.blocks ? getStoryItemText(content) : getTextMCQ(content);
  const partsStripped = parts.map((part) => part?.trim());
  return partsStripped.join('\n');
};

export const getTotalSentences = (content) => {
  const text = getText(content);
  return text.match(SENTENCE_END_RE)?.length || 0;
};

export const getWords = (content) => {
  return getText(content)
    .replace(/[.,?!"]/g, '')
    .split(/[\s]+/);
};

export const getTotalWords = (content) => {
  return getWords(content).length;
};

export const getTotalUniqueWords = (content) => {
  const uniqueWords = new Set(getWords(content));
  return uniqueWords.size;
};

export const getTypeTokenRatio = (content) => {
  const totalWords = getTotalWords(content);
  const totalUniqueWords = getTotalUniqueWords(content);
  return totalWords ? totalUniqueWords / totalWords : 0;
};

export const getTotalSyllables = (word) => {
  let totalSyllable = 0;
  if (isAlpha(word)) {
    totalSyllable = pronouncing.syllableCount(pronouncing.phonesForWord(word)[0]);
    if (totalSyllable === undefined) totalSyllable = syllable(word);
  }
  return totalSyllable;
};

export const getFleschRe = (content) => {
  // https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests#Flesch_reading_ease
  const words = getWords(content);
  const totalWords = Math.max(1, getTotalWords(content));
  const totalSentences = getTotalSentences(content);
  let totalSyllables = 0;
  words.map((word) => {
    totalSyllables += getTotalSyllables(word);
    return word;
  });
  return 206.835 - 1.015 * (totalWords / totalSentences) - 84.6 * (totalSyllables / totalWords);
};

export const getClIndex = (content) => {
  const totalLetters = Math.max(1, getWords(content).join('').length);
  const totalWords = Math.max(1, getTotalWords(content));
  const totalSentences = getTotalSentences(content);

  const L = 100 * (totalLetters / totalWords);
  const S = 100 * (totalSentences / totalWords);
  return 0.0588 * L - 0.296 * S - 15.8;
};

export const getFleschKincaidGl = (content) => {
  // https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests#Flesch–Kincaid_grade_level
  const words = getWords(content);
  const totalWords = Math.max(1, getTotalWords(content));
  const totalSentences = getTotalSentences(content);
  let totalSyllables = 0;
  words.map((word) => {
    totalSyllables += getTotalSyllables(word);
    return word;
  });
  if (!totalSentences) return 0;
  return 0.39 * (totalWords / totalSentences) + 11.8 * (totalSyllables / totalWords) - 15.59;
};

export const QUALITY_METRICS = {
  'Word Count': getTotalWords,
  'Unique Word Count': getTotalUniqueWords,
  'Type-Token Ratio': getTypeTokenRatio,
  'Flesch Reading Ease': getFleschRe,
  'Coleman-Liau Index': getClIndex,
  'Flesch-Kincaid Grade Level': getFleschKincaidGl,
};

export const getQualityMetrics = (content, job) => {
  const itemQualityMetrics = [];
  job?.aiModel?.qualityMetrics.map((qualityMetric) => {
    const { name } = qualityMetric;
    const qualityMetricFunc = QUALITY_METRICS[name];
    if (!qualityMetricFunc) {
      throw Error(`Quality metric ${name} is not defined`);
    }
    const itemQualityMetric = {
      ...qualityMetric,
      value: qualityMetricFunc(content),
    };
    itemQualityMetrics.push(itemQualityMetric);

    return qualityMetric;
  });
  return { qualityMetrics: itemQualityMetrics };
};
