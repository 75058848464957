export const AI = 'AI';
export const AI_CREATE_STEM = 'AI_CREATE_STEM';
export const AI_SUGGEST_ANSWERS = 'AI_SUGGEST_ANSWERS';
export const AI_REMAINING_ANSWERS = 'AI_REMAINING_ANSWERS';
export const FAILED = 'FAILED';
export const DONE = 'DONE';
export const CONTENT_DELIVERY = 'CONTENT_DELIVERY';
export const IN_PROGRESS = 'IN_PROGRESS';
export const NOT_STARTED = 'NOT_STARTED';

export const progressSteps = [
  { label: 'Opening your item...' },
  { label: 'Generate AI Engine at work...' },
];

export const aiProgressSteps = {
  AI: {
    IN_PROGRESS: 'Composing your content',
    DONE: 'Composition complete, analyzing content',
  },
  AI_CREATE_STEM: {
    IN_PROGRESS: 'Composing question',
  },
  AI_SUGGEST_ANSWERS: {
    IN_PROGRESS: 'Composing suggested key answers',
  },
  AI_REMAINING_ANSWERS: {
    IN_PROGRESS: 'Composing answers',
    DONE: 'Composition complete, analyzing content',
  },
};
