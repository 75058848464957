import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@core/store'
import { getDropdownOptions } from '@author/utils'
import { areInputsEnabled, getAllLeafs, INPUTS } from '@core/utils/tree'
import { InputType } from '@pages/author/author-types'

import _ from 'lodash'

export const dropdownOptions = createSelector(
  (state: RootState) => state.author?.models,
  (models) => getDropdownOptions(models) || []
)

export const tabValues = createSelector(
  (state: RootState) => state.author.selectedModel?.inputSettings || [],
  (state: RootState) => state.author.selectedModel?.flavors,
  (state: RootState) => state.author.selectedFlavors,
  (state: RootState) => state.author.itemInputs,
  (inputSettings, flavors, selectedFlavors, inputValues) => {
    const leafs = getAllLeafs(flavors, selectedFlavors)

    const settingsWithValue = inputSettings.map((input) => {
      const value = inputValues && inputValues[input.name]

      if (Array.isArray(value)) {
        return { ...input, elements: value }
      }

      return { ...input, value: value ?? '' }
    })
    const settings = _.groupBy(settingsWithValue, 'group')
    const hasDisabledLeaf =
      leafs.length > 1 &&
      leafs.some((leaf) => typeof leaf === 'object' && '_inputs' in leaf && leaf[INPUTS] === false)
    const hasRequiredInput = inputSettings?.some((input) => input.required)

    const tabs = Object.entries(settings).map(([tab, values]) => {
      const hasListItem = values.some((value) => value.type === InputType.list)
      return {
        name: tab,
        tooltip: `${tab} directs the content of ${
          hasListItem ? 'each individual item in a' : 'an entire'
        } batch of items.`,
        hasError: values?.some(
          ({ errorMessage, required, value }) => errorMessage || (required && !value)
        ),
      }
    })

    return {
      tabs,
      values: Object.values(settings),
      hasRequiredInput,
      hasDisabledLeaf,
    }
  }
)

export const showFlexibleInputs = createSelector(
  (state: RootState) => state.author.selectedModel?.flavors,
  (state: RootState) => state.author.selectedFlavors,
  (flavors, selectedFlavors) => {
    return areInputsEnabled(flavors, selectedFlavors)
  }
)
