import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useAppDispatch, useAppSelector } from '@core/store'
import { actions } from '@pages/author/author-slice'
import { CustomDragContext, CustomDraggable, CustomDroppable } from '@core/components/CustomDnD'
import { getSelectablePartsStatus } from '@author/utils/project'
import ContentOption from './ContentOption'

export * from './constants'

export interface OptionsFormProps {
  isDragEnabled: boolean
}

const useStyles = makeStyles((theme) => ({
  isDragging: {
    background: theme.palette.background.paleGrey,
  },
  isDraggingOver: {
    background: theme.palette.background.blueGrey,
  },
}))

const OptionsForm = ({ isDragEnabled }: OptionsFormProps) => {
  const clasess = useStyles()
  const originalContent = useAppSelector((state) => state.author.item?.originalContentVersion)
  const selectableStatuses = getSelectablePartsStatus()
  const dispatch = useAppDispatch()
  const finalOptions = useAppSelector((state) => state.author.content?.options)
  const { itemId } = useParams<{ itemId: string }>()

  const changeOption = (index, name, value) => {
    dispatch(actions.setContent({ index, name, option: value }))
  }

  const onDragEnd = (newOptions) => {
    dispatch(actions.setContent({ options: newOptions }))
  }

  return (
    <Box>
      <CustomDragContext items={finalOptions} onDragEndCallback={onDragEnd}>
        <CustomDroppable className={clasess.isDraggingOver}>
          {finalOptions?.map((_, index) => {
            const dragId = `dragId-${index}`
            return (
              <Box key={index}>
                <CustomDraggable
                  key={dragId}
                  id={dragId}
                  index={index}
                  disabled={!isDragEnabled}
                  className={clasess.isDragging}
                >
                  <ContentOption
                    key={`${itemId}-answer-${index}`}
                    isDragEnabled={isDragEnabled}
                    index={index}
                    selectableStatuses={selectableStatuses}
                    originalStatus={originalContent?.content?.options[index]?.status}
                    setValue={changeOption}
                    hasOptionRationale={false}
                  />
                </CustomDraggable>
              </Box>
            )
          })}
        </CustomDroppable>
      </CustomDragContext>
    </Box>
  )
}
export default OptionsForm
