import React, { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@core/store'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

import FTGDropdown from '@core/components/FTGDropdown'
import { actions } from '@containers/references/references-slice'

import {
  keysDistractorsSelector,
  excludedOptionsSelector,
  keysSelector,
} from './references-selectors'

const useStyles = makeStyles((theme) => ({
  dropdown: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 3,
    '& button': {
      width: '100%',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(
        1.25
      )}`,
      '&:hover': { background: 'transparent' },
    },
  },
  dropdownMenu: {
    '& ul h5': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    '& ul li': {
      paddingLeft: theme.spacing(4),
    },
    '& ul .answer-key': {
      background: `${
        theme.palette.other.scandalGreen
      } url("/assets/images/icon-circle-check-24.svg") no-repeat ${theme.spacing(1)} center`,
      backgroundSize: '16px 16px',
      '&:hover': {
        background: `${
          theme.palette.other.scandalGreen
        } url("/assets/images/icon-circle-check-24.svg") no-repeat ${theme.spacing(1)} center`,
        backgroundSize: '16px 16px',
      },
    },
  },
  valueClassName: {
    paddingLeft: theme.spacing(2.5),
    background: `${
      theme.palette.other.scandalGreen
    } url("/assets/images/icon-circle-check-24.svg") no-repeat ${theme.spacing(1)} center`,
    backgroundSize: '16px 16px',
  },
}))

interface ItemReferenceOptionsProps {
  loading: boolean
  selectedOption: string | undefined
  setSelectedOption: (value: string | undefined) => void
}

export default function ItemReferenceOptions({
  loading,
  selectedOption,
  setSelectedOption,
}: ItemReferenceOptionsProps) {
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const selectedKeysDistractors = useAppSelector(keysDistractorsSelector)
  const selectedKeys = useAppSelector(keysSelector)
  const excludedOptions = useAppSelector(excludedOptionsSelector)

  const isOptionExcluded = useMemo(
    () => excludedOptions.find((option) => option.value === selectedOption),
    [excludedOptions]
  )

  useEffect(() => {
    if (!selectedOption && selectedKeys.length === 1) {
      setSelectedOption(selectedKeys[0].value)
    }
    if (selectedOption && isOptionExcluded) {
      setSelectedOption(undefined)
      dispatch(actions.clear())
    }
    if (selectedOption && selectedKeys.length === 0) {
      setSelectedOption(undefined)
      dispatch(actions.clear())
    }
  }, [selectedKeys, isOptionExcluded])

  const dropdownDisabled = loading || !selectedKeys.length

  const isKey = (value?: string) =>
    selectedKeys.length && selectedKeys.some((key) => key.value === value)

  const options = selectedKeysDistractors.length
    ? [
        { name: 'Options', header: true },
        ...selectedKeysDistractors.map((answer) => ({
          ...answer,
          name: answer.value,
          itemClassName: isKey(answer.value) ? 'answer-key' : '',
        })),
      ]
    : []
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Select
      </Typography>
      <FTGDropdown
        className={classes.dropdown}
        testid="item-answers-dropdown"
        value={selectedOption}
        setValue={(o) => {
          setSelectedOption(o.name)
          dispatch(actions.clear())
        }}
        placeholder="Choose key to begin"
        options={options}
        menuClassName={classes.dropdownMenu}
        valueClassName={isKey(selectedOption) ? classes.valueClassName : ''}
        disabled={dropdownDisabled}
        menuSx={{ '& li': { maxWidth: 372, minWidth: 250 } }}
      />
    </>
  )
}
