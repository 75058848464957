import React, { FormEvent, useEffect, useReducer } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
  ListItemButton,
  Box,
  ListItemText,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { useAppSelector } from '@core/store'
import { useApolloClient } from '@apollo/client'
import { formatTimeAgo } from '@core/utils/date'
import { useSnack } from '@core/providers/snack'
import ErrorHandler from '@core/api/ErrorHandler'

import * as queries from './admin-queries'

interface Customer {
  id: string
  name: string
  features: Record<string, any>
  createdAt: string
}

interface State {
  open: boolean
  customers: Customer[] | null
  selectedCustomerId: string
  loading: boolean
}

const initialState: State = {
  loading: false,
  open: false,
  customers: null,
  selectedCustomerId: '',
}

function reducer(state: State, action: Partial<State>): State {
  return { ...state, ...action }
}

const context = { role: 'internal_admin' }

export function useAdminSettings(onReload: () => void = () => window.location.reload()) {
  const snack = useSnack()
  const client = useApolloClient()
  const user = useAppSelector((state) => state.main.user!)
  const customer = useAppSelector((state) => state.main.customer)

  const [state, setState] = useReducer(reducer, {
    ...initialState,
    selectedCustomerId: customer?.id || '',
  })

  useEffect(() => {
    if (!state.open) return

    client
      .query({
        query: queries.GET_CUSTOMERS,
        context,
      })
      .then(({ data }) => {
        setState({
          customers: data.customer,
        })
      })
      .catch((error) => {
        ErrorHandler(error)
        snack.add({ message: error.message, severity: 'error' })
      })
  }, [state.open])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setState({ loading: true })

    try {
      await client.mutate({
        mutation: queries.SWITCH_CUSTOMER,
        variables: { customerId: state.selectedCustomerId },
        context,
      })
      setState({ loading: false, open: false })
      onReload()
    } catch (error) {
      ErrorHandler(error)
      snack.add({ message: error.message, severity: 'error' })
      setState({ loading: false })
    }
  }

  return {
    state,
    setState,
    handleSubmit,
    user,
    customer,
  }
}

type AdminSettings = ReturnType<typeof useAdminSettings>

export default function AdminSettingsDialog({ adminSettings }: { adminSettings: AdminSettings }) {
  const { state, handleSubmit, setState, user, customer } = adminSettings

  if (!user.internalAdmin || !customer) return null

  const enableSwitch = state.selectedCustomerId && state.selectedCustomerId !== customer.id

  return (
    <Dialog open={state.open} onClose={() => setState({ open: false })} fullWidth maxWidth="xs">
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle>Admin Settings</DialogTitle>
        <DialogContent>
          <Autocomplete
            loading={!state.customers}
            options={state.customers || []}
            sx={{ py: 3 }}
            onChange={(e, value) => setState({ selectedCustomerId: value?.id })}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label={state.customers ? 'Select a customer' : 'Loading...'}
                fullWidth
                helperText="All tabs will reload after this change"
                disabled={!state.customers}
              />
            )}
            value={state.customers?.find((item) => item.id === state.selectedCustomerId) || null}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option: Customer) => (
              <ListItem {...props} key={option.id} sx={{ padding: '0px !important' }}>
                <ListItemButton role={undefined} dense>
                  <ListItemText
                    id={option.id}
                    color="text.primary"
                    secondaryTypographyProps={{ color: 'primary.main' }}
                    primary={
                      <>
                        {option.id === customer.id ? '* Current - ' : ''}
                        {option.name}
                      </>
                    }
                    secondary={
                      <>
                        <b>Created at:</b> {formatTimeAgo(option.createdAt)[0]}
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState({ open: false })}>Cancel</Button>
          <LoadingButton
            variant="contained"
            disabled={!enableSwitch}
            type="submit"
            loading={state.loading}
          >
            Switch Customer
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
