import { ITEM_SET, MCQ, CLOZE } from '@author/constants/questionsTypes'

export enum AnswerStatus {
  key = 'CORRECT',
  distractor = 'DISTRACTOR',
  excluded = 'EXCLUDED',
}
export interface Answer {
  value: string
  status: AnswerStatus | null
}
export interface ContentCloze {
  options?: Answer[]
  question?: undefined
}
export interface ContentMCQ {
  question?: string
  answers?: Answer[]
}

interface Comment {
  id: string
  name: string
  value: string
}
interface ContentRoot {
  custom_passage: string
  stimulus?: string
  cloze_ids?: string[]
  mcq_ids?: string[]
}

interface ContentFilter {
  label: string
}

export type Content = ContentCloze | ContentMCQ

export interface BaseItem {
  id: string
  step: string
  status: string
  index?: number
  contentFilter?: ContentFilter
  aiModelId?: string
}

export interface ItemCloze extends BaseItem {
  type: typeof CLOZE
  finalContent?: ContentCloze | null
  lastContent: ContentCloze
}

export interface ItemMCQ extends BaseItem {
  type: typeof MCQ
  finalContent?: ContentMCQ | null
  lastContent: ContentMCQ
}

export type Item = ItemCloze | ItemMCQ

export interface ItemRoot extends Omit<BaseItem, 'finalContent' | 'lastContent'> {
  job: {
    id: string
    ownerId: string
  }
  aiModel: {
    name: string
  }
  type: typeof ITEM_SET
  selectedFlavors: string[]
  project: {
    id: string
    name: string
    access: { write: boolean }[]
  } | null
  finalContent?: ContentRoot | null
  lastContent: ContentRoot
  comments?: { type: string; value: Comment[] }[]
  qualityMetrics?: {
    name: string
    value: number
    min: number
    max: number
  }[]
}

export type ItemList = (Item | ItemRoot)[]

export type ItemMap = { root: ItemRoot } & Record<string, ItemMCQ | ItemCloze>

export interface State {
  initialized: boolean
  contentTab: number
  sidebarTab: number
  notFound: boolean
  items: ItemMap | null
  expanded: string | null
  savingDraft: boolean
  savingItem: boolean
  match: string | null
  key: number
  fatalError?: string
}

export interface StateLoaded extends State {
  step: 'loaded'
  items: ItemMap
  initialized: true
}
