import { gql } from '@apollo/client'

export const UPDATE_ITEM_COMMENTS = gql`
  mutation update_item_comments($itemId: uuid!, $comments: jsonb) {
    upsert_content_version(
      content_version: { comments: $comments, item_id: $itemId, type: "USER_INPUT" }
    ) {
      ok
    }
  }
`
