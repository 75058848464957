import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import FTGDropdown from '@core/components/FTGDropdown';
import FTGTooltip from '@core/components/FTGTooltip';
import { getOptionsFromTree, depthOf, isChild, getAllLeafs, getMetadata } from '@core/utils/tree';
import { makeStyles } from '@mui/styles';
import { FTGCheckbox } from '@core/components';
import { useAppSelector } from '@core/store';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    background: theme.palette.background.button,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    borderRadius: 3,
    '& button': {
      width: '100%',
      padding: `${theme.spacing(0.25)} ${theme.spacing(1)} ${theme.spacing(0.25)} ${theme.spacing(
        1.25
      )}`,
      '&:hover': { background: 'transparent' },
      '& span': {
        justifyContent: 'space-between',
      },
    },
  },
  passageCheckbox: {
    marginLeft: theme.spacing(1),
  },
}));

export const compare = (a, b) => {
  let aComparator = a.name.toUpperCase();
  let bComparator = b.name.toUpperCase();

  if ('_order' in a && '_order' in b) {
    aComparator = a._order;
    bComparator = b._order;
  }

  if (aComparator < bComparator) {
    return -1;
  }
  if (aComparator > bComparator) {
    return 1;
  }
  return 0;
};

export const checkRequired = (flavors, selected) => {
  const leafs = getAllLeafs(flavors, selected);

  const isAllRequired = leafs.every((option) => option._corpus_units && !option._units);

  if (isAllRequired) {
    return {
      required: true,
      disabled: true,
      reason: `This combination of Options requires a custom passage`,
    };
  }

  const isAllSelected = selected.filter(Boolean).length === selected.length;

  if (!isAllSelected) {
    return {
      required: false,
      disabled: true,
      reason: 'You must select all options',
    };
  }

  const isSomeAllowed = leafs.some((option) => option._corpus_units && option._units);

  if (isSomeAllowed) {
    return { required: false, disabled: false, reason: '' };
  }

  return {
    required: false,
    disabled: true,
    reason: `This combination of options don't support a custom passage`,
  };
};

const FlavorsSelect = ({
  modelName,
  flavors,
  selectedFlavors,
  setSelectedFlavors,
  customPassageEnabled,
  setCustomPassageEnabled,
  disabledFlavor,
}) => {
  const classes = useStyles();
  const depthOfFlavors = useMemo(() => depthOf(flavors), [flavors]);
  const aiModelInputs = useAppSelector((state) => state.author.selectedModel?.inputSettings) || [];

  useEffect(() => {
    if (!selectedFlavors.length) {
      setSelectedFlavors(Array(depthOfFlavors).fill(undefined));
    }
  }, [selectedFlavors]);

  const setValue = (index, option) => {
    let selected =
      selectedFlavors.length === depthOfFlavors
        ? [...selectedFlavors]
        : Array(depthOfFlavors).fill(undefined);

    if (index === 0 && option.name) {
      selected = selected.map((name, idx) => {
        if (idx === 0) return option.name;

        return isChild(flavors[option.name], name, idx - 1) ? name : undefined;
      });
    } else {
      selected[index] = option.name;
    }

    setSelectedFlavors(selected);
  };

  const flavorsOptions = useMemo(() => {
    const result = [];

    for (let depth = 0; depth < depthOfFlavors; depth += 1) {
      result.push(getOptionsFromTree({ flavors, depth, selectedFlavors }));
    }

    return result.map((options) => options.sort(compare));
  }, [flavors, selectedFlavors, depthOfFlavors]);

  const { required, disabled, reason } = useMemo(
    () => checkRequired(flavors, selectedFlavors),
    [flavors, selectedFlavors]
  );

  const placeholders = useMemo(() => getMetadata(flavors, 0)._placeholders, [flavors]);

  useEffect(() => {
    if (required && !customPassageEnabled) setCustomPassageEnabled(true);
    if (!required && reason) setCustomPassageEnabled(false);
  }, [reason, required, customPassageEnabled]);

  return (
    <>
      {flavorsOptions.map((options, index) => {
        const placeholder =
          (Array.isArray(placeholders) && placeholders[index]) || 'Select Generate Model';
        return (
          <FTGDropdown
            key={`select-${index}-${modelName}`}
            className={classes.dropdown}
            value={selectedFlavors[index]}
            setValue={(o) => setValue(index, o)}
            buttonLabel={selectedFlavors[index]}
            placeholder={placeholder}
            options={options}
            hasClearButton
            disabled={disabledFlavor}
          />
        );
      })}

      {!aiModelInputs?.length > 0 && (
        <FTGTooltip title={reason}>
          <div>
            <FTGCheckbox
              classes={classes.passageCheckbox}
              label="I want to submit a custom passage"
              onChange={(e) => setCustomPassageEnabled(e.target.checked)}
              checked={customPassageEnabled}
              disabled={disabled}
            />
          </div>
        </FTGTooltip>
      )}
    </>
  );
};

FlavorsSelect.propTypes = {
  modelName: PropTypes.string,
  flavors: PropTypes.object.isRequired,
  selectedFlavors: PropTypes.array.isRequired,
  setSelectedFlavors: PropTypes.func.isRequired,
  customPassageEnabled: PropTypes.bool,
  setCustomPassageEnabled: PropTypes.func.isRequired,
  disabledFlavor: PropTypes.bool,
};

FlavorsSelect.defaultProps = {
  customPassageEnabled: false,
  modelName: '',
};

export default FlavorsSelect;
