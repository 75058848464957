import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  checkBoxOutlineBlankIconRoot: {
    color: theme.palette.secondary.light,
  },
  checkBoxIconRoot: {
    color: theme.palette.secondary.dark,
  },
  checkBoxLabel: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
}));

const FTGCheckbox = ({ label, classes, onChange, checked, disabled }) => {
  const innerClasses = useStyles();

  return (
    <div className={classes}>
      <FormControlLabel
        classes={{ label: innerClasses.checkBoxLabel }}
        label={label}
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            icon={
              <CheckBoxOutlineBlankIcon
                classes={{ root: innerClasses.checkBoxOutlineBlankIconRoot }}
                fontSize="small"
              />
            }
            checkedIcon={
              <CheckBoxIcon classes={{ root: innerClasses.checkBoxIconRoot }} fontSize="small" />
            }
          />
        }
      />
    </div>
  );
};

FTGCheckbox.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

FTGCheckbox.defaultProps = {
  label: '',
  classes: '',
  onChange: () => {},
  checked: false,
  disabled: false,
};

export default FTGCheckbox;
