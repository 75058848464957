/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useCallback } from 'react';

export function useEventListener(
  eventName: string,
  handler: ReturnType<typeof useCallback>,
  element = window
) {
  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event) => handler(event);
    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, handler]);
}
