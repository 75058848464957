/* eslint-disable no-console */
import { useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'

import TIMEOUTS from '@core/constants/timeouts'
import { useAppDispatch, useAppSelector } from '@core/store'

import * as thunkActions from '@containers/comments/comments-thunk'
import { isCommentsMatch } from './comments-utils'

export function WatchComments() {
  const dispatch = useAppDispatch()
  const comments = useAppSelector((state) => state.comments.comments)
  const prevComments = useRef(comments)

  const updateDebounced = useMemo(() => {
    return debounce(async () => {
      await dispatch(thunkActions.saveComment())
    }, TIMEOUTS.AUTO_SAVE_TIMEOUT)
  }, [dispatch])

  useEffect(() => {
    if (!isCommentsMatch(prevComments.current, comments)) {
      updateDebounced()
    }

    prevComments.current = comments
  }, [comments])

  return null
}
