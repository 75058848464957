import React from 'react'
import { TextField, Box, SxProps, IconButton, SvgIcon } from '@mui/material'
import { useTheme } from '@emotion/react'
import { useAppDispatch } from '@core/store'
import { actions } from '@containers/comments/comments-slice'

import ContentEditable from '@core/components/ContentEditable/ContentEditable'
import FTGTooltip from '@core/components/FTGTooltip'
import TrashIcon from '@assets/images/icon-trash-24-px.svg'

interface CommentFeedbackProps {
  id: string
  name: string
  value: string
}

const styles: Record<string, SxProps> = {
  commentTitle: {
    width: 245,
    '& .MuiOutlinedInput-root': {
      height: 46,
      '&:hover fieldset': {
        borderColor: 'rgba(0,0,0,0.23)',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
        boxShadow: 'rgb(50 151 253) 0px 0px 0px 3px',
        transition: 'box-shadow 0.5s ease-in-out 0s, background-color 0.1s ease-in-out 0s',
        background: 'rgba(50, 151, 253, 0.03)',
      },
    },
  },
}

const CommentFeedback = (props: CommentFeedbackProps) => {
  const { id, name, value } = props
  const theme: any = useTheme()
  const dispatch = useAppDispatch()

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Box height={50} display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          sx={styles.commentTitle}
          inputProps={{
            'aria-label': 'comment-name',
          }}
          defaultValue={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
              actions.updateComment({
                id,
                field: 'name',
                value: event.target.value,
              })
            )
          }}
        />

        <FTGTooltip withMargin title="Delete" placement="left">
          <IconButton
            onClick={() => {
              dispatch(actions.deleteComment(id))
            }}
            className="comment-button"
            aria-label="Delete"
            size="large"
          >
            <SvgIcon component={TrashIcon} />
          </IconButton>
        </FTGTooltip>
      </Box>
      <Box mt={2}>
        <ContentEditable
          ariaLabel="comment-text"
          onChange={(textContent) => {
            dispatch(
              actions.updateComment({
                id,
                field: 'value',
                value: textContent,
              })
            )
          }}
          text={value}
          className={{
            root: {
              height: 200,
              fontSize: 14,
              boxShadow: '0 0 0 1px rgb(0 0 0 / 23%)',
              lineHeight: '1.43',
              '& .public-DraftEditorPlaceholder-root': {
                background: theme.palette.background.blueGrey,
                height: '100%',
              },
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default CommentFeedback
