import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { dateFormatter } from '@core/utils/date'
import { TIMEZONE } from '@core/constants'
import { authorClear } from '@pages/author/author-actions'
import { State, RawBatch } from './batches-types'
import * as thunk from './batches-thunk'

export const thunkActions = thunk

export function getInitialState(): State {
  return {
    selected: null,
    selectedJobId: null,
    selectedDiscard: [],
    batches: null,
    isRegenerating: false,
  }
}

function mountBatch(job: RawBatch) {
  const { createdAt, itemsRequired, aiModel, items, ...batch } = job
  const flavors =
    items && items[0]?.selectedFlavors ? items[0].selectedFlavors?.filter(Boolean) : []

  const date = dateFormatter(
    {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone: TIMEZONE,
    },
    new Date(createdAt)
  )

  return {
    ...batch,
    aiModel,
    itemsRequired,
    createdAt,
    // @TODO: this needs to get from item.ai_model, instead of item.job.ai_model
    jobName: aiModel?.name,
    date,
    name: `${aiModel?.name} (${itemsRequired})`,
    tooltip: flavors?.join('; '),
    flavors: [aiModel?.name, ...flavors],
    customPassage: items?.[0]?.customPassage || null,
    selectedFlavors: items?.[0]?.selectedFlavors || null,
    temperatePercentage:
      aiModel?.maxTemperature && aiModel?.minTemperature
        ? items[0]?.temperatePercentage ?? 0 * 100
        : null,
  }
}

export const slice = createSlice({
  name: 'batches',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    selectDiscard(state: State, action: PayloadAction<string>) {
      const { selectedDiscard } = state
      const index = selectedDiscard.indexOf(action.payload)

      if (index === -1) {
        state.selectedDiscard.push(action.payload)
      } else {
        state.selectedDiscard.splice(index, 1)
      }
    },
    updateList(state: State, action: PayloadAction<{ jobs: any[] }>) {
      const { jobs } = action.payload

      state.batches = state.batches || {}

      for (const item of jobs) {
        const current = mountBatch(item)

        if (item.id in state.batches) {
          state.batches[item.id] = { ...state.batches[item.id], ...current }
        } else {
          state.batches[item.id] = { ...current, step: 'UNLOADED', items: null }
        }
      }
    },
    updateBatch(state: State, action: PayloadAction<{ id: string; [key: string]: any }>) {
      const { id, ...content } = action.payload

      state.batches = state.batches || {}
      state.batches[id] = { ...state.batches[id], ...content, id }
    },
    createTempBatch(
      state: State,
      action: PayloadAction<{
        id: string
        itemsRequired: number
        createdAt: string
        aiModel: {
          id: string
          name: string
          maxTemperature: number | null
          minTemperature: number | null
          [key: string]: any
        }
      }>
    ) {
      const { id, itemsRequired, createdAt, aiModel } = action.payload

      // only create temp if not already exists
      if (state.batches && id in state.batches) {
        return
      }

      const batch = mountBatch({
        id,
        itemsRequired,
        aiModel,
        createdAt,
        items: [],
        itemsPassed: 0,
      })

      state.batches = state.batches || {}
      state.batches[id] = { ...batch, step: 'UNLOADED', items: null }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunkActions.discardBatches.fulfilled, (state, action) => {
      if (action.payload.deleted && state.batches) {
        for (const id of state.selectedDiscard) {
          delete state.batches[id]
        }
        state.selectedDiscard = []
      }
      Object.assign(state, action.payload)
    })
    builder.addCase(thunkActions.regenerateBatches.pending, (state) => {
      state.isRegenerating = true
    })
    builder.addCase(thunkActions.regenerateBatches.fulfilled, (state, action) => {
      if (state.batches && action.payload?.jobId) {
        delete state.batches[action.payload.jobId]
      }
      state.isRegenerating = false
    })
    builder.addCase(thunkActions.regenerateBatches.rejected, (state) => {
      state.isRegenerating = false
    })

    builder.addCase(authorClear, (state: State) => {
      const { batches } = state
      Object.assign(state, { ...getInitialState(), batches })
    })
  },
})

export default slice
export const { actions } = slice
