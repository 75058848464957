import React, { useCallback, useMemo, useState, memo } from 'react';
import { useHistory, generatePath, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { FormControlLabel, Switch } from '@mui/material';
import ItemAiProgress from '@author/components/Item/ItemAiProgress';
import { useAppDispatch, useAppSelector } from '@core/store';

import { HOT_TEXT, subTitles } from '@author/constants/questionsTypes';
import Pagination from '@deliver/components//Shared/Pagination';
import { AUTHOR_ITEM_ROUTE } from '@core/constants/routes';
import { checkpoint } from '@core/tracking';

import DialogSaveItem, { useConfirmSave } from '@core/components/dialogs/dialog-save-item';
import FTGTooltip from '@core/components/FTGTooltip';
import { useProjects } from '@containers/projects/projects-hooks';
import ContentLabelNotification from '@author/components/Notifications/ContentLabel';
import RegenerateDialog, { useConfirmRegenerate } from '@core/components/dialogs/dialog-regenerate';
import { Box } from '@mui/system';

import * as selectors from '@containers/batches/batches-selectors';
import * as thunkActions from '@pages/author/author-thunk';
import { actions } from '@pages/author/author-slice';
import { useSnack } from '@core/providers/snack';
import { ErrorMessage } from '@message/error';
import RegenerateButton from './RegenerateButton';
import { ItemHeader } from './Header';

import { OPTIONS } from './constants';
import PassageForm from './passage-form';
import HotTextForm from './hot-text-form';

import Answers from './answers-form';
import QuestionParts from './question-parts-form';
import QuestionForm from './question-form';
import OptionsForm from './options-form';

export * from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentTextContainer: {
    width: '100%',
    padding: `${theme.spacing(2)} 0`,
    whiteSpace: 'pre-line',
  },
  contentTextContainerWithOverflowY: {
    marginBottom: theme.spacing(2),
    'overflow-y': 'auto',
    height: 128,
    minHeight: 128,
    resize: 'vertical',
  },
  subTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    minHeight: 40,
    '& h4': {
      fontWeight: 600,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  restoreIconBtn: {
    color: theme.palette.primary.main,
    '&:hover': { background: theme.palette.other.lightBlue },
    '&.Mui-disabled': { '& svg use': { fill: theme.palette.grey['400'] } },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Item = () => {
  const classes = useStyles();
  const history = useHistory();
  const { itemId: selectedItemId } = useParams();
  const snack = useSnack();
  const dispatch = useAppDispatch();
  const [isOrderingEnabled, setOrderingEnabled] = useState(false);
  const batch = useAppSelector(selectors.selectCurrentBatch);
  const contentFilterLabel = useAppSelector((state) => state.author.item?.contentFilter?.label);
  const projectId = useAppSelector((state) => state.author.item?.project?.id);
  const contentType = useAppSelector((state) => state.author.item?.contentType);
  const contentAnswers = useAppSelector((state) => state.author.content?.answers);
  const contentParts = useAppSelector((state) => state.author.content?.parts);
  const contentOptions = useAppSelector((state) => state.author.content?.options);
  const paginatedItemsIds = useAppSelector((state) => state.author.paginatedItemsIds);
  const isContentEditOn = useAppSelector((state) => state.author.isContentEditOn);
  const isRegenerating = useAppSelector((state) => state.author.loading.regenerate);
  const loading = useAppSelector((state) => state.author.loading.generate);
  const generatedItemId = useAppSelector((state) => state.author.generatedItemId);
  const itemId = selectedItemId || generatedItemId;
  const confirmRegenerate = useConfirmRegenerate();
  const projects = useProjects();
  const confirmSave = useConfirmSave(projects);

  const onSaveItem = async (id, projectName) => {
    dispatch(thunkActions.deliverItem({ itemId, projectId: id, projectName }));
  };

  const isRegenerateOptionsDisabled = isRegenerating || loading || isContentEditOn;
  const showRegenerateOptions = contentType !== 'FRQ';

  /**
   * Batch item pagination
   */
  const onChangePaginationPage = useCallback(
    (_, page) => {
      checkpoint('item-pagination-change');
      history.push(generatePath(AUTHOR_ITEM_ROUTE, { itemId: paginatedItemsIds[page - 1] }));
    },
    [paginatedItemsIds]
  );

  const paginationPage = useMemo(() => {
    return paginatedItemsIds.indexOf(itemId) + 1;
  }, [paginatedItemsIds, itemId]);

  const [label, action] = projectId
    ? ['Update Item', () => onSaveItem(projectId)]
    : ['Save Item', () => confirmSave.open(onSaveItem)];

  const handleRegenerateCompleted = useCallback(async () => {
    dispatch(thunkActions.fetchItem({ itemId }));
    dispatch(actions.stopLoading());
  }, [itemId]);

  const handleRegenerateFailed = useCallback(async (errorResponse) => {
    const errorMessage = ErrorMessage.getAIResponseErrorMessage(errorResponse);

    snack.add({
      message: errorMessage,
      severity: 'error',
    });

    dispatch(actions.stopLoading());
  }, []);

  const handleRegenerate = async () => {
    checkpoint('Regenerate');
    const userConfirmed = await confirmRegenerate.open(null, { hideKey: `regenerate-options` });
    if (userConfirmed) dispatch(thunkActions.regenerateItem({ type: OPTIONS }));
  };
  return (
    <div className={classes.container} data-testid="item-container">
      <RegenerateDialog state={confirmRegenerate} />
      <DialogSaveItem state={confirmSave} formProps={projects} />

      <ItemHeader onSaveAction={action} onSaveActionLabel={label} contentType={contentType}>
        <ContentLabelNotification label={contentFilterLabel} />
      </ItemHeader>

      {contentType === HOT_TEXT ? (
        <Box>
          <QuestionForm />
          <HotTextForm />
        </Box>
      ) : (
        <Box>
          <PassageForm />
          <QuestionForm />
        </Box>
      )}

      {contentType !== HOT_TEXT && (
        <>
          {contentType && showRegenerateOptions && (
            <div className={classes.subTitleContainer}>
              <h4 data-testid="subtitle-options">
                {subTitles[contentType].options}:
                <RegenerateButton
                  name="options"
                  disabled={isRegenerateOptionsDisabled}
                  onRegenerate={handleRegenerate}
                  type={OPTIONS}
                />
              </h4>
              <FTGTooltip title="Toggle to enable option ordering" placement="top">
                <FormControlLabel
                  control={<Switch aria-label="Ordering Toggle" />}
                  label="Option ordering"
                  labelPlacement="start"
                  checked={isOrderingEnabled}
                  onChange={(event) => setOrderingEnabled(event.target.checked)}
                />
              </FTGTooltip>
            </div>
          )}

          {!!contentOptions?.length && !isRegenerating && (
            <OptionsForm isDragEnabled={isOrderingEnabled} />
          )}
          {!!contentAnswers?.length && !isRegenerating && (
            <Answers isDragEnabled={isOrderingEnabled} />
          )}
          {!!contentParts?.length && !isRegenerating && (
            <QuestionParts isDragEnabled={isOrderingEnabled} />
          )}
        </>
      )}

      {isRegenerating && (
        <ItemAiProgress
          itemId={itemId}
          isRegenerating={isRegenerating}
          onCompleted={handleRegenerateCompleted}
          onFailed={handleRegenerateFailed}
        />
      )}

      {paginatedItemsIds.length > 1 && paginatedItemsIds.includes(itemId) && (
        <Pagination
          disabled={batch?.isLoading}
          style={{ marginTop: '24px' }}
          count={paginatedItemsIds.length}
          page={paginationPage}
          onChange={onChangePaginationPage}
        />
      )}
    </div>
  );
};

export default memo(Item);
