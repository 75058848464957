const UNITS = '_units';
export const INPUTS = '_inputs';
const CORPUS_UNITS = '_corpus_units';

export const isChild = (tree, nodeName, depth) => {
  if (!nodeName) {
    return false;
  }
  if (depth === 0) {
    return Object.keys(tree).includes(nodeName);
  }

  let result = false;

  Object.values(tree).forEach((subtree) => {
    if (isChild(subtree, nodeName, depth - 1)) {
      result = true;
    }
  });

  return result;
};

const isMetadataField = (name) => {
  return name.startsWith('_');
};

export const getNodesNamesFromTree = (tree, depth) => {
  if (depth === 0) {
    return Object.keys(tree).filter((key) => !isMetadataField(key));
  }

  const options = [];
  const subtrees = Object.entries(tree).filter(([key]) => !isMetadataField(key));
  subtrees.forEach(([, subtree]) => options.push(...getNodesNamesFromTree(subtree, depth - 1)));
  return options;
};

const filterTree = (tree, name, depth) => {
  const newTree = {};

  if (depth === 0) {
    const newSubtree = tree[name];
    return newSubtree ? { [name]: newSubtree } : undefined;
  }

  Object.entries(tree).forEach(([subtreeName, subtree]) => {
    const newSubtree = filterTree(subtree, name, depth - 1);
    if (newSubtree) {
      newTree[subtreeName] = newSubtree;
    }
  });

  return Object.keys(newTree).length !== 0 ? newTree : undefined;
};

export const filter = (tree, names) => {
  let newTree = tree;

  names.forEach((name, index) => {
    if (name) {
      newTree = filterTree(newTree, name, index);
    }
  });

  return newTree;
};

const _getMetadata = (tree) => {
  const keys = Object.keys(tree);
  const metadataKeys = keys.filter(([key]) => isMetadataField(key));
  return metadataKeys.reduce(
    (result, key) => ({
      ...result,
      [key]: tree[key],
    }),
    {}
  );
};

export const getMetadata = (tree, depth) => {
  if (depth === 0) {
    return _getMetadata(tree);
  }

  let options = {};
  Object.entries(tree).forEach(([name, subtree]) => {
    if (!isMetadataField(name)) {
      const metadata = getMetadata(subtree, depth - 1);
      if (depth === 1) {
        options[name] = metadata;
      } else {
        options = { ...options, ...metadata };
      }
    }
  });
  return options;
};

const DISABLED_TOOLTIP =
  'Option disabled. Select another option in the hierarchy to enable other options.';
export const getOptionsFromTree = ({ flavors, depth, selectedFlavors }) => {
  const metadataByName = getMetadata(flavors, depth + 1);
  const allNodesNames = new Set(getNodesNamesFromTree(flavors, depth));
  const otherSelectedFlavors = selectedFlavors.map((s, index) => (index !== depth ? s : undefined));
  const activeTree = filter(flavors, otherSelectedFlavors);
  const activeNodesNames = new Set(getNodesNamesFromTree(activeTree, depth));
  const options = [];

  allNodesNames.forEach((name) => {
    const disabled = !activeNodesNames.has(name);
    const option = {
      name,
      ...metadataByName[name],
      disabled,
      tooltip: disabled ? DISABLED_TOOLTIP : name,
    };
    options.push(option);
  });

  return options;
};

const subIsTree = (subtree) => {
  return (
    typeof subtree === 'object' &&
    !Object.keys(subtree).includes(UNITS) &&
    !Object.keys(subtree).includes(CORPUS_UNITS)
  );
};

export const depthOf = (tree) => {
  const treeWithoutMetadata = Object.keys(tree)
    .filter(([key]) => !isMetadataField(key))
    .reduce((result, key) => ({ ...result, [key]: tree[key] }), {});

  const subtree = Object.values(treeWithoutMetadata)[0];

  if (!subIsTree(subtree)) {
    return 1;
  }
  return depthOf(subtree) + 1;
};

export const getAllLeafs = (flavors, selected) =>
  selected?.reduce(
    (acc, name) => {
      if (name) {
        return acc[0] && acc.flatMap((node) => node[name] || []);
      }

      return acc.flatMap((node) =>
        Object.entries(node).reduce(
          (children, [key, value]) => (isMetadataField(key) ? children : children.concat(value)),
          []
        )
      );
    },
    [flavors]
  ) || [];

export const areInputsEnabled = (flavors, selectedFlavors) => {
  const metadata = getMetadataFromSelectedFlavors(flavors, selectedFlavors);
  if (metadata && Object.keys(metadata).length) {
    if ('_inputs' in metadata) {
      return metadata[INPUTS];
    }
    return true;
  }
  return false;
};

export const getMetadataFromSelectedFlavors = (tree, selectedFlavors) => {
  if (!tree || selectedFlavors.some((s) => !s)) return null;

  let leaf = tree;

  for (let i = 0; i < selectedFlavors.length; i += 1) {
    leaf = leaf[selectedFlavors[i]];
  }

  return getMetadata(leaf, 0);
};
