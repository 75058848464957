import React from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  toast: {
    background: theme.palette.background.successGreen,
    color: theme.palette.text.balanceGreen,
    '& svg': {
      opacity: 0.8,
      color: theme.palette.text.balanceGreen,
    },
  },
}));

/**
 * Renders Toast Notification component
 * */
const ToastNotification = ({ children, onClose, type, className, ...props }) => {
  const classes = useStyles();

  return (
    <MuiAlert
      className={classes.toast}
      onClose={onClose}
      elevation={6}
      severity="success"
      {...props}
    >
      {children}
    </MuiAlert>
  );
};

ToastNotification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
};

ToastNotification.defaultProps = {
  type: 'success',
  onClose: null,
  className: '',
};

export default ToastNotification;
