import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const useStyles = makeStyles((theme) => ({
  progressListPadding: {
    padding: 0,
    '& .progress-list-item': {
      paddingLeft: 0,
      paddingBottom: theme.spacing(2),
      '& .progress-list-item-icon': {
        minWidth: '45px',
        paddingRight: theme.spacing(2.5),
        justifyContent: 'center',
        '& .bullet': {
          fontSize: '1rem',
          color: theme.palette.text.blue,
        },
        '& .pending-bullet': {
          opacity: 0.6,
        },
        '& .in-progress-bullet': {
          fontSize: '1.5rem',
        },
      },
    },
    '& .in-progress-text': {
      fontWeight: 500,
    },
  },
}));

/**
 * Renders steps
 */
const Steps = ({ steps, stepIndex }) => {
  const classes = useStyles();
  return (
    steps.map((name, index) => (
      <List
        key={`${name}-${index}`}
        classes={{ padding: classes.progressListPadding }}
        aria-label="Item generation step"
      >
        <ListItem className="progress-list-item" data-testid="progress-list-item">
          <ListItemIcon className="progress-list-item-icon">
            {index === stepIndex ? (
              <RadioButtonCheckedIcon className={clsx('bullet', 'in-progress-bullet')} />
            ) : (
              <FiberManualRecordIcon
                className={clsx('bullet', index > stepIndex && 'pending-bullet')}
              />
            )}
          </ListItemIcon>
          <ListItemText
            primary={name}
            classes={{ primary: clsx(index === stepIndex && 'in-progress-text') }}
          />
        </ListItem>
      </List>
    )) || null
  );
};

Steps.propTypes = {
  steps: PropTypes.array,
  stepIndex: PropTypes.number.isRequired,
};

Steps.defaultProps = {
  steps: [],
};
export default Steps;
