import { RootState } from '@core/store'
import { isStoryItem } from '@pages/author/author-selectors'
import { createSelector } from '@reduxjs/toolkit'
import { showFlexibleInputs } from '@author/components/ModelSidebar/SettingsTab/settings-tab-selectors'
import { getAllLeafs, INPUTS } from '@core/utils/tree'

export const hasInputSettingsError = createSelector(
  showFlexibleInputs,
  (state: RootState) => state.author.selectedModel?.inputSettings,
  (state: RootState) => state.author.itemInputs,
  (state: RootState) => state.author.selectedModel?.flavors,
  (state: RootState) => state.author.selectedFlavors,
  (flexibleInputs, inputSettings, inputs, flavors, selectedFlavors) => {
    if (inputSettings?.length) {
      const leafs = getAllLeafs(flavors, selectedFlavors)
      const hasDisabledLeaf =
        leafs.length > 1 &&
        leafs.some(
          (leaf) => typeof leaf === 'object' && '_inputs' in leaf && leaf[INPUTS] === false
        )
      const isAllSelected = selectedFlavors.filter(Boolean).length === selectedFlavors.length
      if (!hasDisabledLeaf && !isAllSelected) {
        return 'You must select all options'
      }
    }

    if (
      !!flexibleInputs &&
      inputSettings?.some(({ name, errorMessage, required }) => {
        return errorMessage || (required && typeof (inputs && inputs[name]) === 'undefined')
      })
    ) {
      return 'The Custom Inputs *required fields must be completed to Run Generate'
    }
  }
)
export const disableGenerate = createSelector(
  isStoryItem,
  hasInputSettingsError,
  (state: RootState) => state.author.selectedModel,
  (state: RootState) => state.author.selectedFlavors,
  (state: RootState) => state.author.customPassage,
  (state: RootState) => state.author.customPassageEnabled,
  (state: RootState) => state.author.loading.generate,
  (state: RootState) => state.author.loading.regenerate,
  (
    isStory,
    isInputSettingsError,
    selectedModel,
    selectedFlavors,
    customPassage,
    customPassageEnabled,
    isGenerating,
    isRegenerating
  ) => {
    return (
      !selectedModel ||
      isGenerating ||
      isRegenerating ||
      (customPassageEnabled && !customPassage) ||
      (isStory && selectedFlavors.filter(Boolean).length === 0) ||
      !!isInputSettingsError
    )
  }
)

export const disableGenerateReason = createSelector(
  isStoryItem,
  hasInputSettingsError,
  (state: RootState) => state.author.selectedModel,
  (state: RootState) => state.author.selectedFlavors,
  (state: RootState) => state.author.customPassage,
  (state: RootState) => state.author.customPassageEnabled,
  (state: RootState) => state.author.loading.generate,
  (
    isStory,
    isInputSettingsError,
    selectedModel,
    selectedFlavors,
    customPassage,
    customPassageEnabled,
    isGenerating
  ) => {
    if (!selectedModel || (isStory && selectedFlavors.filter(Boolean).length === 0))
      return 'Select a model to proceed.'

    if (isGenerating) return 'Generate AI Engine at work. Please wait.'

    if (customPassageEnabled && !customPassage)
      return 'Add a custom passage to proceed or uncheck the checkbox.'

    if (isInputSettingsError) return isInputSettingsError

    return ''
  }
)
