import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  linearProgress: { backgroundColor: theme.palette.grey['300'] },
}));

/**
 * Wrapper component for MaterialUI progress component
 */

const FTGProgress = ({ value, classes, ...props }) => {
  const defaultClasses = useStyles();

  return (
    <LinearProgress
      className={clsx(defaultClasses.linearProgress, classes?.linearProgress)}
      color="secondary"
      value={value > 100 ? 100 : value}
      {...props}
    />
  );
};

FTGProgress.propTypes = {
  value: PropTypes.number,
  classes: PropTypes.object,
};

FTGProgress.defaultProps = {
  classes: null,
  value: null,
};

export default FTGProgress;
