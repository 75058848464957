import React, { memo } from 'react'
import clsx from 'clsx'
import { Tab, Tabs, Box, BoxProps, SxProps } from '@mui/material'

import SettingsTab from '@author/components/ModelSidebar/SettingsTab/settings-tab'
import Metrics from '@author/components/ModelSidebar/MetricsTab'
import BatchesTab from '@containers/batches/batches-tab'
import GenerateItems from '@author/components/ModelSidebar/generate-items/generate-items'
import { useAppDispatch, useAppSelector } from '@core/store'
import { actions } from '@pages/author/author-slice'
import ReferencesTab from '@containers/references/references-tab'
import { selectReferences } from '@containers/references/references-selectors'
import FeedBackTab from './feedback-tab'

export enum TabValues {
  TAB_GENERATE = 0,
  TAB_METRICS = 1,
  TAB_BATCHES = 2,
  TAB_REFERENCES = 3,
  TAB_FEEDBACK = 4,
}

const styles: Record<string, SxProps> = {
  tabs: {
    '& .MuiTabs-indicator': {
      bgColor: 'other.downriver',
      maxWidth: 120,
    },
    '& .MuiTabScrollButton-root': {
      width: 30,
    },
  },
  tab: {
    color: 'other.downriver',
    minWidth: 'auto',
    textTransform: 'capitalize',
    '&.generate-tab': {
      background: 'rgba(179, 212, 255, .6)',
      borderTopLeftRadius: 0.5,
    },
  },
  color: {
    color: 'text.primary',
  },
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  generateItems: {
    m: 4,
  },
}

interface TabPanelProps extends BoxProps {
  children: React.ReactNode
  value: number
  index: number
}

export const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  if (value !== index) return null

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={styles.tabPanel}
      {...other}
    >
      {children}
    </Box>
  )
}

const MetricsTab = () => {
  const qualityMetricsOptions = useAppSelector(
    (state) => state.author.qualityMetricsAndStatus?.qualityMetrics
  )
  return <Metrics qualityMetricsOptions={qualityMetricsOptions} />
}

const ModelSidebar = () => {
  const selectedTab = useAppSelector((state) => state.author.selectedTab)
  const references = useAppSelector(selectReferences)

  const dispatch = useAppDispatch()

  const handleTabChange = (_, newTab) => {
    dispatch(actions.set({ selectedTab: newTab }))
  }

  return (
    <Box
      bgcolor="common.white"
      border="1px solid"
      borderColor="divider"
      borderRadius={1}
      height="100%"
      minHeight="calc(100vh - 56px - 64px)"
    >
      <Tabs
        sx={styles.tabs}
        variant="scrollable"
        value={selectedTab}
        onChange={handleTabChange}
        scrollButtons="auto"
        aria-label="nav tabs"
      >
        <Tab
          aria-label="Generate tab"
          label="Generate"
          value={TabValues.TAB_GENERATE}
          className={clsx(styles.tab, { 'generate-tab': selectedTab === TabValues.TAB_GENERATE })}
          id={`simple-tab-${TabValues.TAB_GENERATE}`}
        />
        <Tab
          aria-label="Batches tab"
          label="Batches"
          value={TabValues.TAB_BATCHES}
          sx={styles.tab}
          id={`simple-tab-${TabValues.TAB_BATCHES}`}
        />
        <Tab
          aria-label="Feedback tab"
          label="Comments"
          value={TabValues.TAB_FEEDBACK}
          sx={styles.tab}
          id={`simple-tab-${TabValues.TAB_FEEDBACK}`}
        />
        {references.enabled && (
          <Tab
            aria-label="References tab"
            label="References"
            value={TabValues.TAB_REFERENCES}
            sx={styles.tab}
            id={`simple-tab-${TabValues.TAB_REFERENCES}`}
          />
        )}
        <Tab
          aria-label="Metrics tab"
          label="Metrics"
          value={TabValues.TAB_METRICS}
          sx={styles.tab}
          id={`simple-tab-${TabValues.TAB_METRICS}`}
        />
      </Tabs>

      <TabPanel
        value={selectedTab}
        key={`tabpanel-${TabValues.TAB_GENERATE}`}
        index={TabValues.TAB_GENERATE}
      >
        <SettingsTab />
        <Box sx={styles.generateItems}>
          <GenerateItems />
        </Box>
      </TabPanel>
      <TabPanel
        value={selectedTab}
        key={`tabpanel-${TabValues.TAB_METRICS}`}
        index={TabValues.TAB_METRICS}
      >
        <MetricsTab />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        key={`tabpanel-${TabValues.TAB_REFERENCES}`}
        index={TabValues.TAB_REFERENCES}
      >
        <ReferencesTab />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        key={`tabpanel-${TabValues.TAB_BATCHES}`}
        index={TabValues.TAB_BATCHES}
      >
        <BatchesTab />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        key={`tabpanel-${TabValues.TAB_FEEDBACK}`}
        index={TabValues.TAB_FEEDBACK}
      >
        <FeedBackTab />
      </TabPanel>
    </Box>
  )
}
export default memo(ModelSidebar)
