import React, { useRef } from 'react'
import {
  Box,
  IconButton,
  IconButtonProps,
  StandardTextFieldProps,
  TextField as MuiTextField,
} from '@mui/material'
import { Edit as EditIcon, HighlightOffRounded as Close } from '@mui/icons-material'

interface TextFieldEditProps extends StandardTextFieldProps {
  handleClose: (e) => void
  closeIconProps?: IconButtonProps
  editIconProps?: IconButtonProps
}

export const TextFieldEdit = (props: TextFieldEditProps) => {
  const textAreaRef = useRef<HTMLHeadingElement>(null)
  const { handleClose, disabled, closeIconProps, editIconProps, ...rest } = props

  const handleEdit = () => {
    textAreaRef.current?.focus()
  }

  return (
    <Box display="flex" alignItems="center" my={2}>
      <IconButton
        aria-label="edit text"
        onClick={handleEdit}
        sx={{
          cursor: 'pointer',
          mr: 1,
          color: 'other.grayishBlueDark',
        }}
        disabled={disabled}
        {...editIconProps}
      >
        <EditIcon />
      </IconButton>
      <MuiTextField
        {...rest}
        inputRef={textAreaRef}
        size="small"
        fullWidth
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="delete text"
              sx={{ p: 0 }}
              onClick={handleClose}
              disabled={disabled}
              {...closeIconProps}
            >
              <Close
                sx={[
                  {
                    color: 'other.grayishBlueDark',
                    opacity: disabled || closeIconProps?.disabled ? 0.6 : 1,
                  },
                ]}
              />
            </IconButton>
          ),
        }}
      />
    </Box>
  )
}
