import { gql } from '@apollo/client';
import { FAILED } from '../constants/progressSteps';

export const GET_STORY_ITEM = gql`
  query item($itemId: uuid!) {
    item: item_by_pk(id: $itemId) {
      id
      job {
        id
        aiModel: ai_model {
          id
          public_mapper
          flavors
          comments
          name
          qualityMetrics: quality_metrics
          type
        }
      }
      project {
        id
        name
      }
      lastContentVersion: last_content_version
      finalContentVersion: final_content_version
    }
  }
`;

export const GENERATE_ITEM = gql`
  mutation generate_item(
    $modelId: uuid
    $itemsRequired: Int
    $itemId: uuid
    $temperature_percentage: Float
    $flavors: [String]
    $custom_passage: String
    $title: String
    $autogenerateBlocks: Boolean = false
    $empty: Boolean = false
    $meta: jsonb = {}
  ) {
    generate_item(
      ai_model_id: $modelId
      items_required: $itemsRequired
      item_id: $itemId
      temperature_percentage: $temperature_percentage
      flavors: $flavors
      custom_passage: $custom_passage
      title: $title
      autogenerate_blocks: $autogenerateBlocks
      empty: $empty
      meta: $meta
    ) {
      items_ids
      job_id
    }
  }
`;

export const GENERATE_ITEM_SET = gql`
  mutation generate_item_set(
    $custom_passage: String
    $flavors: [String]
    $modelId: uuid
    $temperature_percentage: Float
  ) {
    generate_item_set(
      custom_passage: $custom_passage
      flavors: $flavors
      ai_model_id: $modelId
      temperature_percentage: $temperature_percentage
    ) {
      items_ids
      job_id
    }
  }
`;

export const GET_PROJECTS = gql`
  subscription get_projects {
    project {
      id
      name
    }
  }
`;

export const GET_ITEM_UPDATES = gql`
  subscription get_item_updates($itemId: uuid!) {
    item_by_pk(id: $itemId) {
      step
      status
      aiResponses: ai_responses(
        limit: 1,
        where: {status: {_eq: "${FAILED}"}}, order_by: {updated_at: desc}
      ) {
          error
        }
      }
  }
`;

export const DELIVER_CONTENT = gql`
  mutation deliver_content($itemId: uuid!, $projectId: uuid) {
    deliver_content(item_id: $itemId, project_id: $projectId) {
      ok
    }
  }
`;

export const UPDATE_ITEM_VOTE = gql`
  mutation upsert_item_vote($itemId: uuid!, $vote: smallint!) {
    insert_item_vote_one(
      object: { item_id: $itemId, vote: $vote }
      on_conflict: { constraint: item_vote_pkey, update_columns: vote }
    ) {
      vote
    }
  }
`;

export const DELETE_ITEM_VOTE = gql`
  mutation delete_item_vote($itemId: uuid!, $userId: String!) {
    delete_item_vote_by_pk(item_id: $itemId, user_id: $userId) {
      item_id
    }
  }
`;

export const UPDATE_CONTENTS = gql`
  mutation update_contents(
    $content: jsonb!
    $itemId: uuid!
    $qualityMetrics: jsonb!
    $comments: jsonb
    $createdAt: timestamptz
  ) {
    upsert_content_version(
      content_version: {
        content: $content
        quality_metrics: $qualityMetrics
        comments: $comments
        item_id: $itemId
        type: "USER_INPUT"
        created_at: $createdAt
      }
    ) {
      ok
      updatedAt: updated_at
    }
  }
`;

export const CLONE_ITEM = gql`
  mutation clone_item(
    $itemId: uuid!
    $clearAnswers: Boolean
    $filterAnswers: Boolean
    $clearStem: Boolean
  ) {
    clone_item(
      item_id: $itemId
      clear_answers: $clearAnswers
      filter_answers: $filterAnswers
      clear_stem: $clearStem
    ) {
      clonedItemId: cloned_item_id
    }
  }
`;

export const UPDATE_ITEM_COMMENTS = gql`
  mutation update_item_comments($itemId: uuid!, $comments: jsonb) {
    upsert_content_version(
      content_version: { comments: $comments, item_id: $itemId, type: "USER_INPUT" }
    ) {
      ok
    }
  }
`;

export const GET_CONTENT_VERSIONS = gql`
  subscription get_content_version($itemId: uuid!) {
    content_version(
      where: { item_id: { _eq: $itemId } }
      limit: 1
      order_by: { created_at: desc }
    ) {
      content
    }
  }
`;
