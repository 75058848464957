import { createAsyncThunk } from '@reduxjs/toolkit'
import ErrorHandler from '@core/api/ErrorHandler'
import { DONE } from '@author/constants/progressSteps'
import { getQualityMetrics } from '@core/utils/qualityMetrics'
import { AUTHOR_CLOZE_ROUTE, AUTHOR_ROUTE } from '@core/constants/routes'
import { TabValues } from '@author/components/ModelSidebar'
import { mapAnswers } from '@author/pages/Dashboard/utils'
import { SELECTED } from '@author/constants/optionsStatus'
import { ITEM_SET } from '@author/constants/questionsTypes'
import { OPTIONS, QUESTION, CLONE_ITEM as CLONE_ITEM_CONSTANT } from '@author/components/Item'
import { selectUserRole } from '@containers/main/main-utils'
import { actions as batchesActions } from '@containers/batches/batches-slice'
import { ErrorMessage } from '@message/error'
import { getSelectedOptions } from '@author/utils'
import { areInputsEnabled } from '@core/utils/tree'
import { actions as commentsActions } from '@containers/comments/comments-slice'

import { generatePath } from 'react-router-dom'
import * as queries from './author-queries'
import { actions } from './author-slice'

import { AiModel, HotTextOptions } from './author-types'

export const getModels = createAsyncThunk<AiModel[]>(
  'author/getModels',
  async (_, { extra, getState }) => {
    const { role } = selectUserRole(getState())
    try {
      const { data } = await extra.client.query({
        fetchPolicy: 'network-only',
        query: queries.GET_MODELS,
        context: { role },
      })

      return data.models
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({ severity: 'error', message })
      return { status: 'error', message: error.message }
    }
  }
)

export const fetchItem = createAsyncThunk(
  'author/fetchItem',
  async ({ itemId }: { itemId: string }, { extra, getState, dispatch }) => {
    try {
      const { user, role } = selectUserRole(getState())

      const { data } = await extra.client.query({
        fetchPolicy: 'network-only',
        query: queries.GET_ITEM,
        variables: { itemId, userId: user?.id },
        context: { role },
      })

      if (!data.item || data.item?.isArchived) {
        extra.history.push('/404')
        return { notFound: true }
      }

      const { item } = data

      if (item) {
        dispatch(
          commentsActions.set({ itemId: item.id, comments: item.lastContentVersion.comments })
        )
      }

      return { item }
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error while fetching the item. ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

export const updateContents = createAsyncThunk(
  'author/updateContents',
  async (
    { createdAt, content }: { createdAt?: string; content?: any },
    { extra, getState, dispatch }
  ) => {
    try {
      const { content: finalContent, item } = getState().author
      const { comments } = getState().comments
      const { user } = getState().main
      const { qualityMetrics, status } = {
        ...getQualityMetrics(finalContent, item?.job),
        status: DONE,
      }
      dispatch(actions.set({ qualityMetricsAndStatus: { qualityMetrics, status } }))

      await extra.client.mutate({
        mutation: queries.UPDATE_CONTENTS,
        variables: {
          itemId: item?.id,
          qualityMetrics,
          status,
          content: {
            ...(content ? { ...content } : { ...finalContent }),
            created_by: user?.id,
          },
          comments: comments.length > 0 ? comments : item?.lastContentVersion?.comments,
          ...(createdAt ? { createdAt } : {}),
        },
      })
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error updating item: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

export const createClone = createAsyncThunk(
  'author/createClone',
  async (itemId: string, { extra, getState, dispatch }) => {
    try {
      const jobId = getState().author.generatedJobId
      const { data } = await extra.client.mutate({
        mutation: queries.CLONE_ITEM,
        variables: {
          itemId,
          clearStem: false,
          clearAnswers: false,
          filterAnswers: false,
        },
      })
      dispatch(actions.set({ selectedTab: TabValues.TAB_BATCHES }))
      dispatch(actions.set({ generatedJobId: jobId }))
      extra.history.push(`/author/item/${data.cloneItem.clonedItemId}`)
    } catch (error) {
      const { message } = ErrorHandler(error) as any
      extra.snack.add({
        message: `Error while creating a clone ${message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

export const deliverItem = createAsyncThunk(
  'author/deliverItem',
  async (
    { itemId, projectId, projectName }: { itemId: string; projectId: string; projectName: string },
    { extra, getState, dispatch }
  ) => {
    try {
      const { content: finalContent } = getState().author

      const content = { ...finalContent }

      if (content?.answers) {
        const selectedAnswers = getSelectedOptions(content.answers)
        ;[content.answers] = mapAnswers(selectedAnswers, content.answers)
        content.answers = content.answers?.filter((x) => !!x.value)
      }

      if (content?.parts) {
        const selectedParts = getSelectedOptions(content.parts)
        ;[content.parts] = mapAnswers(selectedParts, content.parts, SELECTED)
        content.parts = content.parts?.filter((x) => !!x.value)
      }

      await dispatch(updateContents({ content }))

      await extra.client.mutate({
        mutation: queries.DELIVER_CONTENT,
        variables: { itemId, projectId },
      })

      await dispatch(fetchItem({ itemId }))

      extra.snack.add({
        message: `Your item has been saved to the project ${
          projectName ||
          getState().author.item?.project?.name ||
          getState().authorCloze.items?.root.project?.name
        } in Deliver`,
        severity: 'success',
      })
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error delivering content: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

export const discardItem = createAsyncThunk(
  'author/discardItem',
  async (_, { extra, getState, dispatch }) => {
    try {
      const finalContent = getState().author.item?.finalContentVersion
      const itemId = getState().author.item?.id

      await dispatch(
        updateContents({
          content: finalContent?.content,
          createdAt: finalContent?.updated_at,
        })
      )

      const finalComments = finalContent?.comments

      if (finalComments?.length) {
        await extra.client.mutate({
          mutation: queries.UPDATE_ITEM_COMMENTS,
          variables: {
            itemId,
            comments: finalComments,
          },
        })
        dispatch(commentsActions.set({ comments: finalComments }))
      }

      dispatch(actions.set({ content: finalContent?.content }))
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error discarding item: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

export const updateItemContent = createAsyncThunk(
  'author/updateItemContent',
  async ({ options, html }: { options: HotTextOptions; html: string }, { extra, dispatch }) => {
    try {
      dispatch(actions.setHotContent({ options, html }))
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error updating key: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

export const deleteItemContent = createAsyncThunk(
  'author/deleteItemContent',
  async ({ html, id }: { html: string; id: string }, { extra, dispatch }) => {
    try {
      dispatch(actions.deleteHotContent({ html, id }))
    } catch (error) {
      ErrorHandler(error)

      extra.snack.add({
        message: `Error deleting key: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  }
)

export const generateItem = createAsyncThunk(
  'author/generateItem',
  async (numberOfItems: number, { extra, getState, dispatch }) => {
    dispatch(
      actions.set({
        generatedJobId: null,
        item: null,
        paginatedItemsIds: [],
        content: null,
        generatedItemId: null,
      })
    )
    dispatch(commentsActions.set({ comments: [] }))

    extra.history.push(AUTHOR_ROUTE)

    const {
      selectedModel,
      itemInputs,
      customPassageEnabled,
      customPassage,
      selectedFlavors,
      selectedCreativity,
    } = getState().author

    let variables = {
      modelId: selectedModel?.id,
      itemsRequired: numberOfItems,
      flavors: selectedFlavors,
    }
    if (selectedModel) {
      const showFlexibleInputs = areInputsEnabled(selectedModel.flavors, selectedFlavors)

      variables = {
        ...variables,
        // @ts-ignore
        inputs: showFlexibleInputs ? itemInputs : {},
      }
    }
    if (customPassageEnabled) {
      variables = {
        ...variables,
        // @ts-ignore
        customPassage,
      }
    }
    if (selectedCreativity !== undefined) {
      variables = {
        ...variables,
        // @ts-ignore
        temperaturePercentage: selectedCreativity,
      }
    }

    if (selectedModel?.type === ITEM_SET) {
      try {
        const { data } = await extra.client.mutate({
          mutation: queries.GENERATE_ITEM_SET,
          variables,
        })

        const { items_ids: itemsIds } = data.generate_item_set
        extra.history.push(generatePath(AUTHOR_CLOZE_ROUTE, { itemId: itemsIds[0] }))
        return itemsIds
      } catch (error) {
        ErrorHandler(error)
      }
    }
    try {
      const { data } = await extra.client.mutate({
        mutation: queries.GENERATE_ITEM,
        variables,
      })

      if (data?.generate_item) {
        const { items_ids: itemsIds, job_id: jobId } = data.generate_item

        if (itemsIds.length === 1) {
          dispatch(actions.set({ generatedItemId: itemsIds[0] }))
        }
        if (itemsIds.length > 1) {
          // this creates a temporary batch item on the list
          // so we can avoid loaders while generating multiple items
          dispatch(
            batchesActions.createTempBatch({
              id: jobId,
              itemsRequired: numberOfItems,
              // @ts-ignore
              aiModel: selectedModel,
              createdAt: new Date().toISOString(),
            })
          )
          dispatch(actions.set({ generatedJobId: jobId, selectedTab: TabValues.TAB_BATCHES }))
          dispatch(actions.stopLoading())
        }
      }
    } catch (error) {
      ErrorHandler(error)
      extra.snack.add({
        message: ErrorMessage.api.defaultError,
        severity: 'error',
      })
      throw error
    }
  }
)

export const regenerateItem = createAsyncThunk(
  'author/regenerateItemData',
  async ({ type }: { type: string }, { extra, getState, dispatch }) => {
    const { selectedFlavors, content: finalContent, item, selectedCreativity } = getState().author

    let newContent = { ...finalContent }
    let updateContent = false

    try {
      if (type === CLONE_ITEM_CONSTANT && item) {
        return dispatch(createClone(item?.id))
      }

      if (type === OPTIONS) {
        if (finalContent?.answers) {
          newContent.answers = getSelectedOptions(finalContent.answers)
          updateContent = newContent.answers?.length !== finalContent.answers.length
        }

        if (finalContent?.parts) {
          newContent.parts = getSelectedOptions(finalContent.parts)
          updateContent = newContent.parts?.length !== finalContent.parts.length
        }

        if (finalContent?.options) {
          newContent.options = getSelectedOptions(finalContent.options)
          updateContent = newContent.options?.length !== finalContent.options.length
        }
      }

      if (type === QUESTION) {
        newContent = { ...newContent, answers: [], question: '' }
        updateContent = true
      }

      let variables = { itemId: item?.id, flavors: selectedFlavors }

      if (!selectedCreativity) {
        variables = {
          ...variables,
          // @ts-ignore
          temperaturePercentage: selectedCreativity,
        }
      }

      if (updateContent) {
        await dispatch(updateContents({ content: newContent }))
      }

      await extra.client.mutate({
        mutation: queries.GENERATE_ITEM,
        variables,
      })
    } catch (error) {
      ErrorHandler(error)
      extra.snack.add({
        message: ErrorMessage.api.defaultError,
        severity: 'error',
      })
      throw error
    }
  }
)
