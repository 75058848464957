import React, { memo, useState, useEffect } from 'react'
import { Badge, Box, Link, SxProps, Typography } from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { useAppSelector, useAppDispatch } from '@core/store'
import { FTGCheckbox } from '@core/components'
import FTGDropdown from '@core/components/FTGDropdown'
import CreativitySlider from '@author/components/CreativitySlider'
import FlavorsSelect from '@author/components/ModelSidebar/FlavorsSelect'
import CustomPassageTextarea from '@author/components/ModelSidebar/SettingsTab/CustomPassage'
import { actions } from '@pages/author/author-slice'
import { Tab, Tabs, TabPanel } from '@core/components/tab/tabs'

import FTGTooltip from '@core/components/FTGTooltip'
import Flexible from './flexible-input/flexible-input-form'
import * as selectors from './settings-tab-selectors'

const DEFAULT_MAX_WORDS = 250

const styles: Record<string, SxProps> = {
  dropdown: {
    bgcolor: 'background.button',
    mt: 2,
    mb: 3,
    borderRadius: 0.2,
    '& button': {
      width: '100%',
      '&: hover': { background: 'transparent' },
      '& span': {
        justifyContent: 'space-between',
      },
    },
  },
  infoText: {
    fontWeight: 500,
  },
  infoTextItalic: {
    fontStyle: 'italic',
    fontWeight: 500,
  },
}

const CustomPassage = () => {
  const dispatch = useAppDispatch()
  const customPassageEnabled = useAppSelector((state) => state.author.customPassageEnabled)
  const setCustomPassage = (value) => dispatch(actions.set({ customPassage: value }))
  const selectedModel = useAppSelector((state) => state.author.selectedModel)
  const customPassage = useAppSelector((state) => state.author.customPassage)
  const customPassageMaxWords =
    selectedModel?.public_mapper?.custom_passage_max_word || DEFAULT_MAX_WORDS

  if (!customPassageEnabled) return null

  return (
    <CustomPassageTextarea
      setCustomPassage={setCustomPassage}
      customPassage={customPassage}
      maxWords={customPassageMaxWords}
    />
  )
}

const ItemStartersAvailable = () => {
  const dispatch = useAppDispatch()

  const onItemStartesClick = () => {
    dispatch(actions.set({ selectedTab: 3 }))
  }
  return (
    <Box sx={{ borderRadius: 3, p: 2, mb: 2, bgcolor: 'other.lightBlue' }}>
      <Typography sx={{ fontWeight: 500, display: 'flex', alignItems: 'center' }} gutterBottom>
        <AutoAwesomeIcon sx={{ mr: 1 }} /> Item Starters Available
      </Typography>
      <Typography>
        <Link
          sx={{ cursor: 'pointer' }}
          color="secondary"
          underline="hover"
          onClick={onItemStartesClick}
        >
          Enter a keyword (or phrases)
        </Link>
        &nbsp;and we can find citations linked to your approved reference materials to give your
        item writing a flying start
      </Typography>
    </Box>
  )
}

const FlexibleForm = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [addCustomInput, setAddCustomInput] = useState(false)
  const { tabs, values, hasRequiredInput, hasDisabledLeaf } = useAppSelector(selectors.tabValues)
  const isGenerating = useAppSelector((state) => state.author.loading.generate)
  const hasItemStartReferences =
    useAppSelector(
      (state) => state.author.selectedModel?.public_mapper.has_item_start_references
    ) || false
  useEffect(() => {
    if (hasDisabledLeaf) {
      setAddCustomInput(false)
    } else if (hasRequiredInput) {
      setAddCustomInput(hasRequiredInput)
    }
  }, [hasDisabledLeaf, hasRequiredInput])

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab)
  }

  const handleAddCustomInput = (event) => {
    if (!hasDisabledLeaf && hasRequiredInput) {
      return false
    }
    setAddCustomInput(event.target.checked)
  }

  return (
    <Box>
      {hasItemStartReferences && <ItemStartersAvailable />}
      <FTGCheckbox
        label="Add Custom Input"
        onChange={handleAddCustomInput}
        checked={addCustomInput}
      />
      {addCustomInput && (
        <Box borderRadius={1} height="100%">
          {tabs.length > 1 ? (
            <>
              <Tabs
                value={selectedTab}
                sx={{
                  mt: 2,
                }}
                onChange={handleTabChange}
                aria-label="flexible inputs tab"
              >
                {tabs.map(({ name, tooltip, hasError }, i) => (
                  <Tab
                    aria-label={name}
                    label={
                      <FTGTooltip title={tooltip} placement="bottom">
                        <Badge
                          color="secondary"
                          variant="dot"
                          invisible={selectedTab === i || !hasError}
                        >
                          <Typography sx={{ pr: 0.5 }} variant="inherit">
                            {name}
                          </Typography>
                        </Badge>
                      </FTGTooltip>
                    }
                    value={i}
                    key={i}
                  />
                ))}
              </Tabs>

              {tabs.map(({ name }, i) => (
                <TabPanel value={selectedTab} key={`tab-panel-${name}`} index={i}>
                  <Flexible inputs={values[selectedTab]} isLoading={isGenerating} key={i} />
                </TabPanel>
              ))}
            </>
          ) : (
            <Flexible inputs={values[selectedTab]} isLoading={isGenerating} key="1" />
          )}
        </Box>
      )}
    </Box>
  )
}

const Form = () => {
  const showFlexibleInputs = useAppSelector(selectors.showFlexibleInputs)
  const inputSettings = useAppSelector((state) => state.author.selectedModel?.inputSettings) || []
  return (
    <Box>
      {showFlexibleInputs && inputSettings?.length > 0 ? <FlexibleForm /> : <CustomPassage />}
    </Box>
  )
}

const Creativity = () => {
  const aiModel = useAppSelector((state) => state.author.selectedModel)
  const isLoading = useAppSelector((state) => state.author.loading.generate)

  return (
    <Box>
      {aiModel?.id && (
        <Box mt={4} mb={3}>
          {/* @ts-ignore */}
          <CreativitySlider jobId={aiModel.id} aiModel={aiModel} disabled={isLoading} />
        </Box>
      )}
    </Box>
  )
}

const SettingsTab = () => {
  const dispatch = useAppDispatch()
  const customPassageEnabled = useAppSelector((state) => state.author.customPassageEnabled)
  const selectedFlavors = useAppSelector((state) => state.author.selectedFlavors)
  const aiModelName = useAppSelector((state) => state.author.selectedModel?.name)
  const aiModelFlavors = useAppSelector((state) => state.author.selectedModel?.flavors)
  const aiModelId = useAppSelector((state) => state.author.selectedModel?.id)
  const isLoading = useAppSelector((state) => state.author.loading.generate)
  const jobOptions = useAppSelector(selectors.dropdownOptions)

  const onJobChange = (newJob) => {
    dispatch(
      actions.set({
        selectedFlavors: [],
        customPassageEnabled: false,
        customPassage: '',
        selectedModel: newJob,
      })
    )
  }

  return (
    <Box mt={4} mx={4} mb={0}>
      <Typography sx={{ fontWeight: 500 }} gutterBottom>
        Available Generate Models
      </Typography>
      <FTGDropdown
        sx={styles.dropdown}
        value={aiModelName}
        setValue={onJobChange}
        buttonLabel={aiModelName}
        placeholder="Select Title"
        options={jobOptions}
        disabled={!jobOptions.length || isLoading}
      />
      <Box>
        {aiModelId && (
          <FlavorsSelect
            modelName={aiModelName}
            flavors={aiModelFlavors}
            selectedFlavors={selectedFlavors}
            setSelectedFlavors={(flavors) => dispatch(actions.set({ selectedFlavors: flavors }))}
            customPassageEnabled={customPassageEnabled}
            setCustomPassageEnabled={(value) =>
              dispatch(actions.set({ customPassageEnabled: value }))
            }
            disabledFlavor={isLoading}
          />
        )}
      </Box>
      <Form />
      <Creativity />
    </Box>
  )
}

export default memo(SettingsTab)
