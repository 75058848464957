import React, { useEffect, useCallback, useState } from 'react'
import { EditorState, getVisibleSelectionRect, RichUtils, convertToRaw } from 'draft-js'
import { Box, Button, Popper, Paper, IconButton, Alert, Fade, StyleProps } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import { v4 as uuidv4 } from 'uuid'

import { useAppDispatch } from '@core/store'
import ErrorHandler from '@core/api/ErrorHandler'
import { toHTML } from '@pages/author-cloze/editor/cloze-html'
import { useEventListener } from '@pages/author-cloze/editor/useEventListener'
import * as thunkActions from '@pages/author/author-thunk'

import {
  findEnd,
  findStart,
  removeEntity,
  getEntityRangeByCommand,
  forceSelection,
  findEntityByItemId,
  replaceSelectionEntity,
  insertStickyTextEntity,
  insertSpaceText,
  removeLastUndo,
} from '@pages/author-cloze/editor/utils'

const styles: StyleProps = {
  popper: {
    zIndex: '999',
    '&[data-popper-placement*="top"] .arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `white transparent transparent transparent`,
      },
    },
    '&[data-popper-placement*="bottom"]': {
      mt: '0.5em !important',
    },
    '&[data-popper-placement*="bottom"] .arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent white transparent`,
      },
    },
    '&[data-popper-placement*="right"] .arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent white transparent transparent`,
      },
    },
    '&[data-popper-placement*="left"] .arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent white`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}

type ToolbarProps = {
  getEditorState: () => EditorState
  onChange: (state: EditorState) => void
}

const MAIN = 1
const DELETE = 2

export default function PassagePopper({ getEditorState, onChange }: ToolbarProps) {
  const editorState = getEditorState()
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)
  const [type, setType] = useState(MAIN)

  const selectionState = editorState.getSelection()
  const contentState = editorState.getCurrentContent()

  const anchorKey = selectionState.getAnchorKey()
  const currentContent = editorState.getCurrentContent()
  const currentContentBlock = currentContent.getBlockForKey(anchorKey)
  const start = selectionState.getStartOffset()
  const end = selectionState.getEndOffset()
  const fullBlockText = currentContentBlock.getText()
  const currentEntityAt = getEntityRangeByCommand(editorState, 'range')
  const currentEntity =
    currentEntityAt?.entity && contentState.getEntity(currentEntityAt.entity).getData()

  const idealEnd = findEnd(fullBlockText, end)
  const idealStart = findStart(fullBlockText, start)
  const idealSentence = fullBlockText.slice(idealStart, idealEnd)

  const key = `${anchorKey}-${start}-${end}`

  const handleFocus = useCallback(
    ({ detail }) => {
      const range = findEntityByItemId(detail.id, editorState)

      if (!range) return

      onChange(
        forceSelection(
          editorState,
          {
            start: range.end,
            end: range.end,
            entity: range.entity,
            keyBlock: range.keyBlock,
          },
          false
        )
      )
    },
    [editorState]
  )

  useEventListener('focusEntity', handleFocus)

  const handleReplaceText = useCallback(
    ({ detail }: any) => {
      onChange(replaceSelectionEntity(editorState, detail))
    },
    [editorState]
  )

  useEventListener('changeTextEntity', handleReplaceText)

  const handleStickyInsertText = useCallback(
    ({ detail }: any) => {
      onChange(insertStickyTextEntity(editorState, detail.text, detail.rangeEntity))
    },
    [editorState]
  )

  useEventListener('insertStickyTextEntity', handleStickyInsertText)

  const handleInsertSpaceText = useCallback(() => {
    const nextState = insertSpaceText(editorState)
    if (nextState) onChange(nextState)
  }, [editorState])

  useEventListener('insertSpaceText', handleInsertSpaceText)

  const handleClose = () => {
    setAnchorEl(null)
    setType(MAIN)
  }

  const handleOpen = () => {
    setType(MAIN)
  }

  useEffect(() => {
    if (currentEntity) {
      const el = document.getElementById(currentEntity?.id)

      if (el) {
        setAnchorEl(el)
        handleOpen()
      } else {
        handleClose()
      }
      return
    }

    const hasIdealSentence = idealSentence && idealSentence.trim().length > 2 && end - start > 2

    if (!hasIdealSentence) {
      return handleClose()
    }

    const bounding = getVisibleSelectionRect(window)
    const getBoundingClientRect = () => bounding

    if (bounding) {
      setAnchorEl({ getBoundingClientRect } as HTMLElement)
      handleOpen()
    }
  }, [idealSentence, currentEntity, key])

  const entityText = fullBlockText?.slice(currentEntityAt?.start, currentEntityAt?.end)

  const handleRemove = () => {
    let nextState = removeEntity(editorState)
    nextState = removeLastUndo(nextState, 1)
    onChange(
      forceSelection(
        nextState,
        {
          start: idealEnd,
          end: idealEnd,
          keyBlock: anchorKey,
        },
        true
      )
    )
    const html = toHTML(convertToRaw(nextState.getCurrentContent()))
    dispatch(thunkActions.deleteItemContent({ html, id: currentEntity?.id }))
  }

  const handleSelect = async () => {
    try {
      const isBackward = editorState.getSelection().getIsBackward()
      const updatedSelection = editorState.getSelection().merge({
        focusOffset: isBackward ? idealStart : idealEnd,
        anchorOffset: isBackward ? idealEnd : idealStart,
      })

      const data = {
        id: uuidv4(),
        value: idealSentence,
        status: 'CORRECT',
      }

      const contentStateWithEntity = contentState.createEntity('SELECTED', 'MUTABLE', data)
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

      let nextState = RichUtils.toggleLink(editorState, updatedSelection, entityKey)

      nextState = removeLastUndo(nextState, 1)

      const html = toHTML(convertToRaw(nextState.getCurrentContent()))
      dispatch(thunkActions.updateItemContent({ options: data, html }))

      onChange(
        forceSelection(
          nextState,
          {
            start: idealEnd,
            end: idealEnd,
            entity: entityKey,
            keyBlock: anchorKey,
          },
          true
        )
      )
    } catch (error) {
      ErrorHandler(error)
    }
  }

  useEffect(() => {
    if (!anchorEl) return
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [anchorEl])

  const contentType = {
    [MAIN]: {
      title: 'Your selection',
      content: (
        <>
          <Box
            p={1}
            bgcolor="background.default"
            color="primary.main"
            component="i"
            display="block"
            typography="caption"
            id="selection"
          >
            {currentEntity?.id ? entityText : idealSentence}
          </Box>
          {entityText?.length < 3 && (
            <Alert severity="error">
              hey, you only have 3 characters left. To delete this entity, you need to click on
              remove item button below.
            </Alert>
          )}
        </>
      ),
      button: (
        <>
          <Button
            variant="contained"
            color="tertiary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setType(DELETE)}
            disabled={!currentEntity?.id}
          >
            Remove Option
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            disabled={Boolean(currentEntityAt)}
            onClick={handleSelect}
          >
            Add Option
          </LoadingButton>
        </>
      ),
    },
    [DELETE]: {
      title: 'Are you Sure?',
      content: <Alert severity="info">This action will remove the Key generated</Alert>,
      button: (
        <>
          <Button
            variant="contained"
            color="tertiary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setType(MAIN)}
          >
            Cancel
          </Button>
          <LoadingButton variant="contained" color="secondary" size="small" onClick={handleRemove}>
            Confirm
          </LoadingButton>
        </>
      ),
    },
  }

  const contentPopover = contentType[type]

  const modifiers = [
    {
      name: 'offset',
      options: { offset: [0, 7] },
    },
    {
      name: 'preventOverflow',
      options: { altAxis: true, altBoundary: true, padding: 10 },
    },
    {
      name: 'arrow',
      enabled: true,
      options: { element: arrowRef },
    },
  ]

  if (!anchorEl) return null

  return (
    // @ts-ignore
    <Popper
      open
      anchorEl={anchorEl}
      placement="top"
      modifiers={modifiers}
      transition
      sx={styles.popper}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div>
            <Box component="span" className="arrow" sx={styles.arrow} ref={setArrowRef} />
            <Paper sx={{ p: 1.5, minWidth: 300, maxWidth: 400 }} elevation={9}>
              <Box position="relative" mb={2} pt={1}>
                <Box typography="h5">{contentPopover.title}</Box>
                <IconButton
                  color="primary"
                  onClick={handleClose}
                  sx={{ position: 'absolute', right: 0, top: 0 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              {contentPopover.content}

              <Box textAlign="right" mt={2}>
                {contentPopover.button}
              </Box>
            </Paper>
          </div>
        </Fade>
      )}
    </Popper>
  )
}
