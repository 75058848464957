import { KEY, DISTRACTOR, SELECTED, EXCLUDED } from '@author/constants/optionsStatus';

/**
 * Get answer status that the user can select
 */
export const getSelectableAnswerStatus = () => {
  return [KEY, DISTRACTOR, EXCLUDED];
};

/**
 * Get question part status that the user can select
 */
export const getSelectablePartsStatus = () => {
  return [SELECTED, EXCLUDED];
};

/**
 * Get options with selected status
 * @param {*} options - array
 */
export const getSelectedOptions = (options) => options.filter(({ status }) => !!status);

/**
 * Get text truncated
 * @param {*} source - string
 * @param {*} size - number
 */
export const truncate = (source, size) =>
  source?.length <= size ? source : source?.substr(0, size - 3).concat('...');

export const getDropdownOptions = (options) =>
  Array.isArray(options) &&
  options?.slice().sort((a, b) => {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();
    /* eslint-disable no-nested-ternary */
    return x < y ? -1 : x > y ? 1 : 0;
  });
